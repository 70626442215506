const cartList = [
    {
        id: '1',
        img: 'assets/images/products/img-1.png',
        title: 'Blive Camiseta Estampada Gola Redonda Masculina',
        category: 'Moda',
        size: 'XL',
        color: 'Azul',
        price: 327.49,
        qty: 2
    },
    {
        id: '2',
        img: 'assets/images/products/img-5.png',
        title: 'Bola de Vôlei Willage',
        category: 'Esportes',
        color: 'Branco',
        price: 49.06,
        qty: 1
    },
    {
        id: '3',
        img: 'assets/images/products/img-10.png',
        title: 'Camiseta de Algodão com Gola para Homens',
        category: 'Moda',
        size: 'M',
        color: 'Marrom',
        price: 53.33,
        qty: 3
    },
    {
        id: '4',
        img: 'assets/images/products/img-11.png',
        title: 'Cueca Boxer Jeans Azul Masculina',
        category: 'Moda',
        size: '8',
        color: 'Verde',
        price: 164.37,
        qty: 1
    },
    {
        id: '5',
        img: 'assets/images/products/img-8.png',
        title: 'Moletom Masculino de Manga Longa Sólido',
        category: 'Moda',
        color: 'Verde',
        price: 180.00,
        qty: 1
    }
];

const notification = [
    {
        title: "Novo",
        items: [
            {
                id: 1,
                background: "bg-info-subtle text-info",
                icon: "ti ti-gift",
                text: "Sua recompensa de Otimização Gráfica Elite está pronta!",
                link: "javascript:void(0);",
                timestamp: "Há 30 segundos",
                checkboxId: "all-notification-check01",
                state: false
            },
            {
                id: 2,
                avatar: "assets/images/users/avatar-2.jpg",
                activeBadge: "Novos alertas",
                name: "Angela Bernier",
                text: "Respondeu ao seu comentário no gráfico de previsão de fluxo de caixa 🔔.",
                link: "javascript:void(0);",
                timestamp: "Há 48 minutos",
                checkboxId: "all-notification-check02",
                state: false
            },
            {
                id: 3,
                background: "bg-danger-subtle text-danger",
                icon: "ti ti-message-2",
                text: "Você recebeu 20 novas mensagens na conversa",
                link: "javascript:void(0);",
                timestamp: "Há 2 horas",
                checkboxId: "all-notification-check03",
                state: false
            }
        ]
    },
    {
        title: "Lidas Anteriormente",
        items: [
            {
                id: 4,
                avatar: "assets/images/users/avatar-8.jpg",
                activeBadge: "Novos alertas",
                name: "Maureen Gibson",
                text: "Conversamos sobre um projeto no LinkedIn.",
                link: "javascript:void(0);",
                timestamp: "Há 4 horas",
                checkboxId: "all-notification-check04",
                state: false
            }
        ]
    }
];

export { cartList, notification };