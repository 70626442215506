var phosphoricons = {
    'ph-activity-thin': 59906,
    'ph-address-book-thin': 59907,
    'ph-airplane-in-flight-thin': 59908,
    'ph-airplane-landing-thin': 59909,
    'ph-airplane-takeoff-thin': 59910,
    'ph-airplane-thin': 59911,
    'ph-airplane-tilt-thin': 59912,
    'ph-airplay-thin': 59913,
    'ph-alarm-thin': 59914,
    'ph-alien-thin': 59915,
    'ph-align-bottom-simple-thin': 59916,
    'ph-align-bottom-thin': 59917,
    'ph-align-center-horizontal-simple-thin': 59918,
    'ph-align-center-horizontal-thin': 59919,
    'ph-align-center-vertical-simple-thin': 59920,
    'ph-align-center-vertical-thin': 59921,
    'ph-align-left-simple-thin': 59922,
    'ph-align-left-thin': 59923,
    'ph-align-right-simple-thin': 59924,
    'ph-align-right-thin': 59925,
    'ph-align-top-simple-thin': 59926,
    'ph-align-top-thin': 59927,
    'ph-anchor-simple-thin': 59928,
    'ph-anchor-thin': 59929,
    'ph-android-logo-thin': 59930,
    'ph-angular-logo-thin': 59931,
    'ph-aperture-thin': 59932,
    'ph-app-store-logo-thin': 59933,
    'ph-app-window-thin': 59934,
    'ph-apple-logo-thin': 59935,
    'ph-apple-podcasts-logo-thin': 59936,
    'ph-archive-box-thin': 59937,
    'ph-archive-thin': 59938,
    'ph-archive-tray-thin': 59939,
    'ph-armchair-thin': 59940,
    'ph-arrow-arc-left-thin': 59941,
    'ph-arrow-arc-right-thin': 59942,
    'ph-arrow-bend-double-up-left-thin': 59943,
    'ph-arrow-bend-double-up-right-thin': 59944,
    'ph-arrow-bend-down-left-thin': 59945,
    'ph-arrow-bend-down-right-thin': 59946,
    'ph-arrow-bend-left-down-thin': 59947,
    'ph-arrow-bend-left-up-thin': 59948,
    'ph-arrow-bend-right-down-thin': 59949,
    'ph-arrow-bend-right-up-thin': 59950,
    'ph-arrow-bend-up-left-thin': 59951,
    'ph-arrow-bend-up-right-thin': 59952,
    'ph-arrow-circle-down-left-thin': 59953,
    'ph-arrow-circle-down-right-thin': 59954,
    'ph-arrow-circle-down-thin': 59955,
    'ph-arrow-circle-left-thin': 59956,
    'ph-arrow-circle-right-thin': 59957,
    'ph-arrow-circle-up-left-thin': 59958,
    'ph-arrow-circle-up-right-thin': 59959,
    'ph-arrow-circle-up-thin': 59960,
    'ph-arrow-clockwise-thin': 59961,
    'ph-arrow-counter-clockwise-thin': 59962,
    'ph-arrow-down-left-thin': 59963,
    'ph-arrow-down-right-thin': 59964,
    'ph-arrow-down-thin': 59965,
    'ph-arrow-elbow-down-left-thin': 59966,
    'ph-arrow-elbow-down-right-thin': 59967,
    'ph-arrow-elbow-left-down-thin': 59968,
    'ph-arrow-elbow-left-thin': 59969,
    'ph-arrow-elbow-left-up-thin': 59970,
    'ph-arrow-elbow-right-down-thin': 59971,
    'ph-arrow-elbow-right-thin': 59972,
    'ph-arrow-elbow-right-up-thin': 59973,
    'ph-arrow-elbow-up-left-thin': 59974,
    'ph-arrow-elbow-up-right-thin': 59975,
    'ph-arrow-fat-down-thin': 59976,
    'ph-arrow-fat-left-thin': 59977,
    'ph-arrow-fat-line-down-thin': 59978,
    'ph-arrow-fat-line-left-thin': 59979,
    'ph-arrow-fat-line-right-thin': 59980,
    'ph-arrow-fat-line-up-thin': 59981,
    'ph-arrow-fat-lines-down-thin': 59982,
    'ph-arrow-fat-lines-left-thin': 59983,
    'ph-arrow-fat-lines-right-thin': 59984,
    'ph-arrow-fat-lines-up-thin': 59985,
    'ph-arrow-fat-right-thin': 59986,
    'ph-arrow-fat-up-thin': 59987,
    'ph-arrow-left-thin': 59988,
    'ph-arrow-line-down-left-thin': 59989,
    'ph-arrow-line-down-right-thin': 59990,
    'ph-arrow-line-down-thin': 59991,
    'ph-arrow-line-left-thin': 59992,
    'ph-arrow-line-right-thin': 59993,
    'ph-arrow-line-up-left-thin': 59994,
    'ph-arrow-line-up-right-thin': 59995,
    'ph-arrow-line-up-thin': 59996,
    'ph-arrow-right-thin': 59997,
    'ph-arrow-square-down-left-thin': 59998,
    'ph-arrow-square-down-right-thin': 59999,
    'ph-arrow-square-down-thin': 60000,
    'ph-arrow-square-in-thin': 60001,
    'ph-arrow-square-left-thin': 60002,
    'ph-arrow-square-out-thin': 60003,
    'ph-arrow-square-right-thin': 60004,
    'ph-arrow-square-up-left-thin': 60005,
    'ph-arrow-square-up-right-thin': 60006,
    'ph-arrow-square-up-thin': 60007,
    'ph-arrow-u-down-left-thin': 60008,
    'ph-arrow-u-down-right-thin': 60009,
    'ph-arrow-u-left-down-thin': 60010,
    'ph-arrow-u-left-up-thin': 60011,
    'ph-arrow-u-right-down-thin': 60012,
    'ph-arrow-u-right-up-thin': 60013,
    'ph-arrow-u-up-left-thin': 60014,
    'ph-arrow-u-up-right-thin': 60015,
    'ph-arrow-up-left-thin': 60016,
    'ph-arrow-up-right-thin': 60017,
    'ph-arrow-up-thin': 60018,
    'ph-arrows-clockwise-thin': 60019,
    'ph-arrows-counter-clockwise-thin': 60020,
    'ph-arrows-down-up-thin': 60021,
    'ph-arrows-horizontal-thin': 60022,
    'ph-arrows-in-cardinal-thin': 60023,
    'ph-arrows-in-line-horizontal-thin': 60024,
    'ph-arrows-in-line-vertical-thin': 60025,
    'ph-arrows-in-simple-thin': 60026,
    'ph-arrows-in-thin': 60027,
    'ph-arrows-left-right-thin': 60028,
    'ph-arrows-out-cardinal-thin': 60029,
    'ph-arrows-out-line-horizontal-thin': 60030,
    'ph-arrows-out-line-vertical-thin': 60031,
    'ph-arrows-out-simple-thin': 60032,
    'ph-arrows-out-thin': 60033,
    'ph-arrows-vertical-thin': 60034,
    'ph-article-medium-thin': 60035,
    'ph-article-ny-times-thin': 60036,
    'ph-article-thin': 60037,
    'ph-asterisk-simple-thin': 60038,
    'ph-asterisk-thin': 60039,
    'ph-at-thin': 60040,
    'ph-atom-thin': 60041,
    'ph-baby-thin': 60042,
    'ph-backpack-thin': 60043,
    'ph-backspace-thin': 60044,
    'ph-bag-simple-thin': 60045,
    'ph-bag-thin': 60046,
    'ph-balloon-thin': 60047,
    'ph-bandaids-thin': 60048,
    'ph-bank-thin': 60049,
    'ph-barbell-thin': 60050,
    'ph-barcode-thin': 60051,
    'ph-barricade-thin': 60052,
    'ph-baseball-thin': 60053,
    'ph-basketball-thin': 60054,
    'ph-bathtub-thin': 60055,
    'ph-battery-charging-thin': 60056,
    'ph-battery-charging-vertical-thin': 60057,
    'ph-battery-empty-thin': 60058,
    'ph-battery-full-thin': 60059,
    'ph-battery-high-thin': 60060,
    'ph-battery-low-thin': 60061,
    'ph-battery-medium-thin': 60062,
    'ph-battery-plus-thin': 60063,
    'ph-battery-warning-thin': 60064,
    'ph-battery-warning-vertical-thin': 60065,
    'ph-bed-thin': 60066,
    'ph-beer-bottle-thin': 60067,
    'ph-behance-logo-thin': 60068,
    'ph-bell-ringing-thin': 60069,
    'ph-bell-simple-ringing-thin': 60070,
    'ph-bell-simple-slash-thin': 60071,
    'ph-bell-simple-thin': 60072,
    'ph-bell-simple-z-thin': 60073,
    'ph-bell-slash-thin': 60074,
    'ph-bell-thin': 60075,
    'ph-bell-z-thin': 60076,
    'ph-bezier-curve-thin': 60077,
    'ph-bicycle-thin': 60078,
    'ph-binoculars-thin': 60079,
    'ph-bird-thin': 60080,
    'ph-bluetooth-connected-thin': 60081,
    'ph-bluetooth-slash-thin': 60082,
    'ph-bluetooth-thin': 60083,
    'ph-bluetooth-x-thin': 60084,
    'ph-boat-thin': 60085,
    'ph-book-bookmark-thin': 60086,
    'ph-book-open-thin': 60087,
    'ph-book-thin': 60088,
    'ph-bookmark-simple-thin': 60089,
    'ph-bookmark-thin': 60090,
    'ph-bookmarks-simple-thin': 60091,
    'ph-bookmarks-thin': 60092,
    'ph-books-thin': 60093,
    'ph-bounding-box-thin': 60094,
    'ph-brackets-angle-thin': 60095,
    'ph-brackets-curly-thin': 60096,
    'ph-brackets-round-thin': 60097,
    'ph-brackets-square-thin': 60098,
    'ph-brain-thin': 60099,
    'ph-brandy-thin': 60100,
    'ph-briefcase-metal-thin': 60101,
    'ph-briefcase-thin': 60102,
    'ph-broadcast-thin': 60103,
    'ph-browser-thin': 60104,
    'ph-browsers-thin': 60105,
    'ph-bug-beetle-thin': 60106,
    'ph-bug-droid-thin': 60107,
    'ph-bug-thin': 60108,
    'ph-buildings-thin': 60109,
    'ph-bus-thin': 60110,
    'ph-butterfly-thin': 60111,
    'ph-cactus-thin': 60112,
    'ph-cake-thin': 60113,
    'ph-calculator-thin': 60114,
    'ph-calendar-blank-thin': 60115,
    'ph-calendar-check-thin': 60116,
    'ph-calendar-plus-thin': 60117,
    'ph-calendar-thin': 60118,
    'ph-calendar-x-thin': 60119,
    'ph-camera-rotate-thin': 60120,
    'ph-camera-slash-thin': 60121,
    'ph-camera-thin': 60122,
    'ph-campfire-thin': 60123,
    'ph-car-simple-thin': 60124,
    'ph-car-thin': 60125,
    'ph-cardholder-thin': 60126,
    'ph-cards-thin': 60127,
    'ph-caret-circle-double-down-thin': 60128,
    'ph-caret-circle-double-left-thin': 60129,
    'ph-caret-circle-double-right-thin': 60130,
    'ph-caret-circle-double-up-thin': 60131,
    'ph-caret-circle-down-thin': 60132,
    'ph-caret-circle-left-thin': 60133,
    'ph-caret-circle-right-thin': 60134,
    'ph-caret-circle-up-thin': 60135,
    'ph-caret-double-down-thin': 60136,
    'ph-caret-double-left-thin': 60137,
    'ph-caret-double-right-thin': 60138,
    'ph-caret-double-up-thin': 60139,
    'ph-caret-down-thin': 60140,
    'ph-caret-left-thin': 60141,
    'ph-caret-right-thin': 60142,
    'ph-caret-up-thin': 60143,
    'ph-cat-thin': 60144,
    'ph-cell-signal-full-thin': 60145,
    'ph-cell-signal-high-thin': 60146,
    'ph-cell-signal-low-thin': 60147,
    'ph-cell-signal-medium-thin': 60148,
    'ph-cell-signal-none-thin': 60149,
    'ph-cell-signal-slash-thin': 60150,
    'ph-cell-signal-x-thin': 60151,
    'ph-chalkboard-simple-thin': 60152,
    'ph-chalkboard-teacher-thin': 60153,
    'ph-chalkboard-thin': 60154,
    'ph-chart-bar-horizontal-thin': 60155,
    'ph-chart-bar-thin': 60156,
    'ph-chart-line-thin': 60157,
    'ph-chart-line-up-thin': 60158,
    'ph-chart-pie-slice-thin': 60159,
    'ph-chart-pie-thin': 60160,
    'ph-chat-centered-dots-thin': 60161,
    'ph-chat-centered-text-thin': 60162,
    'ph-chat-centered-thin': 60163,
    'ph-chat-circle-dots-thin': 60164,
    'ph-chat-circle-text-thin': 60165,
    'ph-chat-circle-thin': 60166,
    'ph-chat-dots-thin': 60167,
    'ph-chat-teardrop-dots-thin': 60168,
    'ph-chat-teardrop-text-thin': 60169,
    'ph-chat-teardrop-thin': 60170,
    'ph-chat-text-thin': 60171,
    'ph-chat-thin': 60172,
    'ph-chats-circle-thin': 60173,
    'ph-chats-teardrop-thin': 60174,
    'ph-chats-thin': 60175,
    'ph-check-circle-thin': 60176,
    'ph-check-square-offset-thin': 60177,
    'ph-check-square-thin': 60178,
    'ph-check-thin': 60179,
    'ph-checks-thin': 60180,
    'ph-circle-dashed-thin': 60181,
    'ph-circle-half-thin': 60182,
    'ph-circle-half-tilt-thin': 60183,
    'ph-circle-notch-thin': 60184,
    'ph-circle-thin': 60185,
    'ph-circle-wavy-check-thin': 60186,
    'ph-circle-wavy-question-thin': 60187,
    'ph-circle-wavy-thin': 60188,
    'ph-circle-wavy-warning-thin': 60189,
    'ph-circles-four-thin': 60190,
    'ph-circles-three-plus-thin': 60191,
    'ph-circles-three-thin': 60192,
    'ph-clipboard-text-thin': 60193,
    'ph-clipboard-thin': 60194,
    'ph-clock-afternoon-thin': 60195,
    'ph-clock-clockwise-thin': 60196,
    'ph-clock-counter-clockwise-thin': 60197,
    'ph-clock-thin': 60198,
    'ph-closed-captioning-thin': 60199,
    'ph-cloud-arrow-down-thin': 60200,
    'ph-cloud-arrow-up-thin': 60201,
    'ph-cloud-check-thin': 60202,
    'ph-cloud-fog-thin': 60203,
    'ph-cloud-lightning-thin': 60204,
    'ph-cloud-moon-thin': 60205,
    'ph-cloud-rain-thin': 60206,
    'ph-cloud-slash-thin': 60207,
    'ph-cloud-snow-thin': 60208,
    'ph-cloud-sun-thin': 60209,
    'ph-cloud-thin': 60210,
    'ph-club-thin': 60211,
    'ph-coat-hanger-thin': 60212,
    'ph-code-simple-thin': 60213,
    'ph-code-thin': 60214,
    'ph-codepen-logo-thin': 60215,
    'ph-codesandbox-logo-thin': 60216,
    'ph-coffee-thin': 60217,
    'ph-coin-thin': 60218,
    'ph-coin-vertical-thin': 60219,
    'ph-coins-thin': 60220,
    'ph-columns-thin': 60221,
    'ph-command-thin': 60222,
    'ph-compass-thin': 60223,
    'ph-computer-tower-thin': 60224,
    'ph-confetti-thin': 60225,
    'ph-cookie-thin': 60226,
    'ph-cooking-pot-thin': 60227,
    'ph-copy-simple-thin': 60228,
    'ph-copy-thin': 60229,
    'ph-copyleft-thin': 60230,
    'ph-copyright-thin': 60231,
    'ph-corners-in-thin': 60232,
    'ph-corners-out-thin': 60233,
    'ph-cpu-thin': 60234,
    'ph-credit-card-thin': 60235,
    'ph-crop-thin': 60236,
    'ph-crosshair-simple-thin': 60237,
    'ph-crosshair-thin': 60238,
    'ph-crown-simple-thin': 60239,
    'ph-crown-thin': 60240,
    'ph-cube-thin': 60241,
    'ph-currency-btc-thin': 60242,
    'ph-currency-circle-dollar-thin': 60243,
    'ph-currency-cny-thin': 60244,
    'ph-currency-dollar-simple-thin': 60245,
    'ph-currency-dollar-thin': 60246,
    'ph-currency-eth-thin': 60247,
    'ph-currency-eur-thin': 60248,
    'ph-currency-gbp-thin': 60249,
    'ph-currency-inr-thin': 60250,
    'ph-currency-jpy-thin': 60251,
    'ph-currency-krw-thin': 60252,
    'ph-currency-kzt-thin': 60253,
    'ph-currency-ngn-thin': 60254,
    'ph-currency-rub-thin': 60255,
    'ph-cursor-text-thin': 60256,
    'ph-cursor-thin': 60257,
    'ph-cylinder-thin': 60258,
    'ph-database-thin': 60259,
    'ph-desktop-thin': 60260,
    'ph-desktop-tower-thin': 60261,
    'ph-detective-thin': 60262,
    'ph-device-mobile-camera-thin': 60263,
    'ph-device-mobile-speaker-thin': 60264,
    'ph-device-mobile-thin': 60265,
    'ph-device-tablet-camera-thin': 60266,
    'ph-device-tablet-speaker-thin': 60267,
    'ph-device-tablet-thin': 60268,
    'ph-diamond-thin': 60269,
    'ph-diamonds-four-thin': 60270,
    'ph-dice-five-thin': 60271,
    'ph-dice-four-thin': 60272,
    'ph-dice-one-thin': 60273,
    'ph-dice-six-thin': 60274,
    'ph-dice-three-thin': 60275,
    'ph-dice-two-thin': 60276,
    'ph-disc-thin': 60277,
    'ph-discord-logo-thin': 60278,
    'ph-divide-thin': 60279,
    'ph-dog-thin': 60280,
    'ph-door-thin': 60281,
    'ph-dots-nine-thin': 60282,
    'ph-dots-six-thin': 60283,
    'ph-dots-six-vertical-thin': 60284,
    'ph-dots-three-circle-thin': 60285,
    'ph-dots-three-circle-vertical-thin': 60286,
    'ph-dots-three-outline-thin': 60287,
    'ph-dots-three-outline-vertical-thin': 60288,
    'ph-dots-three-thin': 60289,
    'ph-dots-three-vertical-thin': 60290,
    'ph-download-simple-thin': 60291,
    'ph-download-thin': 60292,
    'ph-dribbble-logo-thin': 60293,
    'ph-drop-half-bottom-thin': 60294,
    'ph-drop-half-thin': 60295,
    'ph-drop-thin': 60296,
    'ph-ear-slash-thin': 60297,
    'ph-ear-thin': 60298,
    'ph-egg-crack-thin': 60299,
    'ph-egg-thin': 60300,
    'ph-eject-simple-thin': 60301,
    'ph-eject-thin': 60302,
    'ph-envelope-open-thin': 60303,
    'ph-envelope-simple-open-thin': 60304,
    'ph-envelope-simple-thin': 60305,
    'ph-envelope-thin': 60306,
    'ph-equalizer-thin': 60307,
    'ph-equals-thin': 60308,
    'ph-eraser-thin': 60309,
    'ph-exam-thin': 60310,
    'ph-export-thin': 60311,
    'ph-eye-closed-thin': 60312,
    'ph-eye-slash-thin': 60313,
    'ph-eye-thin': 60314,
    'ph-eyedropper-sample-thin': 60315,
    'ph-eyedropper-thin': 60316,
    'ph-eyeglasses-thin': 60317,
    'ph-face-mask-thin': 60318,
    'ph-facebook-logo-thin': 60319,
    'ph-factory-thin': 60320,
    'ph-faders-horizontal-thin': 60321,
    'ph-faders-thin': 60322,
    'ph-fast-forward-circle-thin': 60323,
    'ph-fast-forward-thin': 60324,
    'ph-figma-logo-thin': 60325,
    'ph-file-arrow-down-thin': 60326,
    'ph-file-arrow-up-thin': 60327,
    'ph-file-audio-thin': 60328,
    'ph-file-cloud-thin': 60329,
    'ph-file-code-thin': 60330,
    'ph-file-css-thin': 60331,
    'ph-file-csv-thin': 60332,
    'ph-file-doc-thin': 60333,
    'ph-file-dotted-thin': 60334,
    'ph-file-html-thin': 60335,
    'ph-file-image-thin': 60336,
    'ph-file-jpg-thin': 60337,
    'ph-file-js-thin': 60338,
    'ph-file-jsx-thin': 60339,
    'ph-file-lock-thin': 60340,
    'ph-file-minus-thin': 60341,
    'ph-file-pdf-thin': 60342,
    'ph-file-plus-thin': 60343,
    'ph-file-png-thin': 60344,
    'ph-file-ppt-thin': 60345,
    'ph-file-rs-thin': 60346,
    'ph-file-search-thin': 60347,
    'ph-file-text-thin': 60348,
    'ph-file-thin': 60349,
    'ph-file-ts-thin': 60350,
    'ph-file-tsx-thin': 60351,
    'ph-file-video-thin': 60352,
    'ph-file-vue-thin': 60353,
    'ph-file-x-thin': 60354,
    'ph-file-xls-thin': 60355,
    'ph-file-zip-thin': 60356,
    'ph-files-thin': 60357,
    'ph-film-script-thin': 60358,
    'ph-film-slate-thin': 60359,
    'ph-film-strip-thin': 60360,
    'ph-fingerprint-simple-thin': 60361,
    'ph-fingerprint-thin': 60362,
    'ph-finn-the-human-thin': 60363,
    'ph-fire-simple-thin': 60364,
    'ph-fire-thin': 60365,
    'ph-first-aid-kit-thin': 60366,
    'ph-first-aid-thin': 60367,
    'ph-fish-simple-thin': 60368,
    'ph-fish-thin': 60369,
    'ph-flag-banner-thin': 60370,
    'ph-flag-checkered-thin': 60371,
    'ph-flag-thin': 60372,
    'ph-flame-thin': 60373,
    'ph-flashlight-thin': 60374,
    'ph-flask-thin': 60375,
    'ph-floppy-disk-back-thin': 60376,
    'ph-floppy-disk-thin': 60377,
    'ph-flow-arrow-thin': 60378,
    'ph-flower-lotus-thin': 60379,
    'ph-flower-thin': 60380,
    'ph-flying-saucer-thin': 60381,
    'ph-folder-dotted-thin': 60382,
    'ph-folder-lock-thin': 60383,
    'ph-folder-minus-thin': 60384,
    'ph-folder-notch-minus-thin': 60385,
    'ph-folder-notch-open-thin': 60386,
    'ph-folder-notch-plus-thin': 60387,
    'ph-folder-notch-thin': 60388,
    'ph-folder-open-thin': 60389,
    'ph-folder-plus-thin': 60390,
    'ph-folder-simple-dotted-thin': 60391,
    'ph-folder-simple-lock-thin': 60392,
    'ph-folder-simple-minus-thin': 60393,
    'ph-folder-simple-plus-thin': 60394,
    'ph-folder-simple-star-thin': 60395,
    'ph-folder-simple-thin': 60396,
    'ph-folder-simple-user-thin': 60397,
    'ph-folder-star-thin': 60398,
    'ph-folder-thin': 60399,
    'ph-folder-user-thin': 60400,
    'ph-folders-thin': 60401,
    'ph-football-thin': 60402,
    'ph-fork-knife-thin': 60403,
    'ph-frame-corners-thin': 60404,
    'ph-framer-logo-thin': 60405,
    'ph-function-thin': 60406,
    'ph-funnel-simple-thin': 60407,
    'ph-funnel-thin': 60408,
    'ph-game-controller-thin': 60409,
    'ph-gas-pump-thin': 60410,
    'ph-gauge-thin': 60411,
    'ph-gear-six-thin': 60412,
    'ph-gear-thin': 60413,
    'ph-gender-female-thin': 60414,
    'ph-gender-intersex-thin': 60415,
    'ph-gender-male-thin': 60416,
    'ph-gender-neuter-thin': 60417,
    'ph-gender-nonbinary-thin': 60418,
    'ph-gender-transgender-thin': 60419,
    'ph-ghost-thin': 60420,
    'ph-gif-thin': 60421,
    'ph-gift-thin': 60422,
    'ph-git-branch-thin': 60423,
    'ph-git-commit-thin': 60424,
    'ph-git-diff-thin': 60425,
    'ph-git-fork-thin': 60426,
    'ph-git-merge-thin': 60427,
    'ph-git-pull-request-thin': 60428,
    'ph-github-logo-thin': 60429,
    'ph-gitlab-logo-simple-thin': 60430,
    'ph-gitlab-logo-thin': 60431,
    'ph-globe-hemisphere-east-thin': 60432,
    'ph-globe-hemisphere-west-thin': 60433,
    'ph-globe-simple-thin': 60434,
    'ph-globe-stand-thin': 60435,
    'ph-globe-thin': 60436,
    'ph-google-chrome-logo-thin': 60437,
    'ph-google-logo-thin': 60438,
    'ph-google-photos-logo-thin': 60439,
    'ph-google-play-logo-thin': 60440,
    'ph-google-podcasts-logo-thin': 60441,
    'ph-gradient-thin': 60442,
    'ph-graduation-cap-thin': 60443,
    'ph-graph-thin': 60444,
    'ph-grid-four-thin': 60445,
    'ph-hamburger-thin': 60446,
    'ph-hand-eye-thin': 60447,
    'ph-hand-fist-thin': 60448,
    'ph-hand-grabbing-thin': 60449,
    'ph-hand-palm-thin': 60450,
    'ph-hand-pointing-thin': 60451,
    'ph-hand-soap-thin': 60452,
    'ph-hand-thin': 60453,
    'ph-hand-waving-thin': 60454,
    'ph-handbag-simple-thin': 60455,
    'ph-handbag-thin': 60456,
    'ph-hands-clapping-thin': 60457,
    'ph-handshake-thin': 60458,
    'ph-hard-drive-thin': 60459,
    'ph-hard-drives-thin': 60460,
    'ph-hash-straight-thin': 60461,
    'ph-hash-thin': 60462,
    'ph-headlights-thin': 60463,
    'ph-headphones-thin': 60464,
    'ph-headset-thin': 60465,
    'ph-heart-break-thin': 60466,
    'ph-heart-straight-break-thin': 60467,
    'ph-heart-straight-thin': 60468,
    'ph-heart-thin': 60469,
    'ph-heartbeat-thin': 60470,
    'ph-hexagon-thin': 60471,
    'ph-highlighter-circle-thin': 60472,
    'ph-horse-thin': 60473,
    'ph-hourglass-high-thin': 60474,
    'ph-hourglass-low-thin': 60475,
    'ph-hourglass-medium-thin': 60476,
    'ph-hourglass-simple-high-thin': 60477,
    'ph-hourglass-simple-low-thin': 60478,
    'ph-hourglass-simple-medium-thin': 60479,
    'ph-hourglass-simple-thin': 60480,
    'ph-hourglass-thin': 60481,
    'ph-house-line-thin': 60482,
    'ph-house-simple-thin': 60483,
    'ph-house-thin': 60484,
    'ph-identification-badge-thin': 60485,
    'ph-identification-card-thin': 60486,
    'ph-image-square-thin': 60487,
    'ph-image-thin': 60488,
    'ph-infinity-thin': 60489,
    'ph-info-thin': 60490,
    'ph-instagram-logo-thin': 60491,
    'ph-intersect-thin': 60492,
    'ph-jeep-thin': 60493,
    'ph-kanban-thin': 60494,
    'ph-key-return-thin': 60495,
    'ph-key-thin': 60496,
    'ph-keyboard-thin': 60497,
    'ph-keyhole-thin': 60498,
    'ph-knife-thin': 60499,
    'ph-ladder-simple-thin': 60500,
    'ph-ladder-thin': 60501,
    'ph-lamp-thin': 60502,
    'ph-laptop-thin': 60503,
    'ph-layout-thin': 60504,
    'ph-leaf-thin': 60505,
    'ph-lifebuoy-thin': 60506,
    'ph-lightbulb-filament-thin': 60507,
    'ph-lightbulb-thin': 60508,
    'ph-lightning-slash-thin': 60509,
    'ph-lightning-thin': 60510,
    'ph-line-segment-thin': 60511,
    'ph-line-segments-thin': 60512,
    'ph-link-break-thin': 60513,
    'ph-link-simple-break-thin': 60514,
    'ph-link-simple-horizontal-break-thin': 60515,
    'ph-link-simple-horizontal-thin': 60516,
    'ph-link-simple-thin': 60517,
    'ph-link-thin': 60518,
    'ph-linkedin-logo-thin': 60519,
    'ph-linux-logo-thin': 60520,
    'ph-list-bullets-thin': 60521,
    'ph-list-checks-thin': 60522,
    'ph-list-dashes-thin': 60523,
    'ph-list-numbers-thin': 60524,
    'ph-list-plus-thin': 60525,
    'ph-list-thin': 60526,
    'ph-lock-key-open-thin': 60527,
    'ph-lock-key-thin': 60528,
    'ph-lock-laminated-open-thin': 60529,
    'ph-lock-laminated-thin': 60530,
    'ph-lock-open-thin': 60531,
    'ph-lock-simple-open-thin': 60532,
    'ph-lock-simple-thin': 60533,
    'ph-lock-thin': 60534,
    'ph-magic-wand-thin': 60535,
    'ph-magnet-straight-thin': 60536,
    'ph-magnet-thin': 60537,
    'ph-magnifying-glass-minus-thin': 60538,
    'ph-magnifying-glass-plus-thin': 60539,
    'ph-magnifying-glass-thin': 60540,
    'ph-map-pin-line-thin': 60541,
    'ph-map-pin-thin': 60542,
    'ph-map-trifold-thin': 60543,
    'ph-marker-circle-thin': 60544,
    'ph-martini-thin': 60545,
    'ph-mask-happy-thin': 60546,
    'ph-mask-sad-thin': 60547,
    'ph-math-operations-thin': 60548,
    'ph-medal-thin': 60549,
    'ph-medium-logo-thin': 60550,
    'ph-megaphone-simple-thin': 60551,
    'ph-megaphone-thin': 60552,
    'ph-messenger-logo-thin': 60553,
    'ph-microphone-slash-thin': 60554,
    'ph-microphone-stage-thin': 60555,
    'ph-microphone-thin': 60556,
    'ph-microsoft-excel-logo-thin': 60557,
    'ph-microsoft-powerpoint-logo-thin': 60558,
    'ph-microsoft-teams-logo-thin': 60559,
    'ph-microsoft-word-logo-thin': 60560,
    'ph-minus-circle-thin': 60561,
    'ph-minus-thin': 60562,
    'ph-money-thin': 60563,
    'ph-monitor-play-thin': 60564,
    'ph-monitor-thin': 60565,
    'ph-moon-stars-thin': 60566,
    'ph-moon-thin': 60567,
    'ph-mountains-thin': 60568,
    'ph-mouse-simple-thin': 60569,
    'ph-mouse-thin': 60570,
    'ph-music-note-simple-thin': 60571,
    'ph-music-note-thin': 60572,
    'ph-music-notes-plus-thin': 60573,
    'ph-music-notes-simple-thin': 60574,
    'ph-music-notes-thin': 60575,
    'ph-navigation-arrow-thin': 60576,
    'ph-needle-thin': 60577,
    'ph-newspaper-clipping-thin': 60578,
    'ph-newspaper-thin': 60579,
    'ph-note-blank-thin': 60580,
    'ph-note-pencil-thin': 60581,
    'ph-note-thin': 60582,
    'ph-notebook-thin': 60583,
    'ph-notepad-thin': 60584,
    'ph-notification-thin': 60585,
    'ph-number-circle-eight-thin': 60586,
    'ph-number-circle-five-thin': 60587,
    'ph-number-circle-four-thin': 60588,
    'ph-number-circle-nine-thin': 60589,
    'ph-number-circle-one-thin': 60590,
    'ph-number-circle-seven-thin': 60591,
    'ph-number-circle-six-thin': 60592,
    'ph-number-circle-three-thin': 60593,
    'ph-number-circle-two-thin': 60594,
    'ph-number-circle-zero-thin': 60595,
    'ph-number-eight-thin': 60596,
    'ph-number-five-thin': 60597,
    'ph-number-four-thin': 60598,
    'ph-number-nine-thin': 60599,
    'ph-number-one-thin': 60600,
    'ph-number-seven-thin': 60601,
    'ph-number-six-thin': 60602,
    'ph-number-square-eight-thin': 60603,
    'ph-number-square-five-thin': 60604,
    'ph-number-square-four-thin': 60605,
    'ph-number-square-nine-thin': 60606,
    'ph-number-square-one-thin': 60607,
    'ph-number-square-seven-thin': 60608,
    'ph-number-square-six-thin': 60609,
    'ph-number-square-three-thin': 60610,
    'ph-number-square-two-thin': 60611,
    'ph-number-square-zero-thin': 60612,
    'ph-number-three-thin': 60613,
    'ph-number-two-thin': 60614,
    'ph-number-zero-thin': 60615,
    'ph-nut-thin': 60616,
    'ph-ny-times-logo-thin': 60617,
    'ph-octagon-thin': 60618,
    'ph-option-thin': 60619,
    'ph-package-thin': 60620,
    'ph-paint-brush-broad-thin': 60621,
    'ph-paint-brush-household-thin': 60622,
    'ph-paint-brush-thin': 60623,
    'ph-paint-bucket-thin': 60624,
    'ph-paint-roller-thin': 60625,
    'ph-palette-thin': 60626,
    'ph-paper-plane-right-thin': 60627,
    'ph-paper-plane-thin': 60628,
    'ph-paper-plane-tilt-thin': 60629,
    'ph-paperclip-horizontal-thin': 60630,
    'ph-paperclip-thin': 60631,
    'ph-parachute-thin': 60632,
    'ph-password-thin': 60633,
    'ph-path-thin': 60634,
    'ph-pause-circle-thin': 60635,
    'ph-pause-thin': 60636,
    'ph-paw-print-thin': 60637,
    'ph-peace-thin': 60638,
    'ph-pen-nib-straight-thin': 60639,
    'ph-pen-nib-thin': 60640,
    'ph-pen-thin': 60641,
    'ph-pencil-circle-thin': 60642,
    'ph-pencil-line-thin': 60643,
    'ph-pencil-simple-line-thin': 60644,
    'ph-pencil-simple-thin': 60645,
    'ph-pencil-thin': 60646,
    'ph-percent-thin': 60647,
    'ph-person-simple-run-thin': 60648,
    'ph-person-simple-thin': 60649,
    'ph-person-simple-walk-thin': 60650,
    'ph-person-thin': 60651,
    'ph-perspective-thin': 60652,
    'ph-phone-call-thin': 60653,
    'ph-phone-disconnect-thin': 60654,
    'ph-phone-incoming-thin': 60655,
    'ph-phone-outgoing-thin': 60656,
    'ph-phone-slash-thin': 60657,
    'ph-phone-thin': 60658,
    'ph-phone-x-thin': 60659,
    'ph-phosphor-logo-thin': 60660,
    'ph-piano-keys-thin': 60661,
    'ph-picture-in-picture-thin': 60662,
    'ph-pill-thin': 60663,
    'ph-pinterest-logo-thin': 60664,
    'ph-pinwheel-thin': 60665,
    'ph-pizza-thin': 60666,
    'ph-placeholder-thin': 60667,
    'ph-planet-thin': 60668,
    'ph-play-circle-thin': 60669,
    'ph-play-thin': 60670,
    'ph-playlist-thin': 60671,
    'ph-plug-thin': 60672,
    'ph-plugs-connected-thin': 60673,
    'ph-plugs-thin': 60674,
    'ph-plus-circle-thin': 60675,
    'ph-plus-minus-thin': 60676,
    'ph-plus-thin': 60677,
    'ph-poker-chip-thin': 60678,
    'ph-police-car-thin': 60679,
    'ph-polygon-thin': 60680,
    'ph-popcorn-thin': 60681,
    'ph-power-thin': 60682,
    'ph-prescription-thin': 60683,
    'ph-presentation-chart-thin': 60684,
    'ph-presentation-thin': 60685,
    'ph-printer-thin': 60686,
    'ph-prohibit-inset-thin': 60687,
    'ph-prohibit-thin': 60688,
    'ph-projector-screen-chart-thin': 60689,
    'ph-projector-screen-thin': 60690,
    'ph-push-pin-simple-slash-thin': 60691,
    'ph-push-pin-simple-thin': 60692,
    'ph-push-pin-slash-thin': 60693,
    'ph-push-pin-thin': 60694,
    'ph-puzzle-piece-thin': 60695,
    'ph-qr-code-thin': 60696,
    'ph-question-thin': 60697,
    'ph-queue-thin': 60698,
    'ph-quotes-thin': 60699,
    'ph-radical-thin': 60700,
    'ph-radio-button-thin': 60701,
    'ph-radio-thin': 60702,
    'ph-rainbow-cloud-thin': 60703,
    'ph-rainbow-thin': 60704,
    'ph-receipt-thin': 60705,
    'ph-record-thin': 60706,
    'ph-rectangle-thin': 60707,
    'ph-recycle-thin': 60708,
    'ph-reddit-logo-thin': 60709,
    'ph-repeat-once-thin': 60710,
    'ph-repeat-thin': 60711,
    'ph-rewind-circle-thin': 60712,
    'ph-rewind-thin': 60713,
    'ph-robot-thin': 60714,
    'ph-rocket-launch-thin': 60715,
    'ph-rocket-thin': 60716,
    'ph-rows-thin': 60717,
    'ph-rss-simple-thin': 60718,
    'ph-rss-thin': 60719,
    'ph-rug-thin': 60720,
    'ph-ruler-thin': 60721,
    'ph-scales-thin': 60722,
    'ph-scan-thin': 60723,
    'ph-scissors-thin': 60724,
    'ph-screencast-thin': 60725,
    'ph-scribble-loop-thin': 60726,
    'ph-scroll-thin': 60727,
    'ph-selection-all-thin': 60728,
    'ph-selection-background-thin': 60729,
    'ph-selection-foreground-thin': 60730,
    'ph-selection-inverse-thin': 60731,
    'ph-selection-plus-thin': 60732,
    'ph-selection-slash-thin': 60733,
    'ph-selection-thin': 60734,
    'ph-share-network-thin': 60735,
    'ph-share-thin': 60736,
    'ph-shield-check-thin': 60737,
    'ph-shield-checkered-thin': 60738,
    'ph-shield-chevron-thin': 60739,
    'ph-shield-plus-thin': 60740,
    'ph-shield-slash-thin': 60741,
    'ph-shield-star-thin': 60742,
    'ph-shield-thin': 60743,
    'ph-shield-warning-thin': 60744,
    'ph-shopping-bag-open-thin': 60745,
    'ph-shopping-bag-thin': 60746,
    'ph-shopping-cart-simple-thin': 60747,
    'ph-shopping-cart-thin': 60748,
    'ph-shower-thin': 60749,
    'ph-shuffle-angular-thin': 60750,
    'ph-shuffle-simple-thin': 60751,
    'ph-shuffle-thin': 60752,
    'ph-sidebar-simple-thin': 60753,
    'ph-sidebar-thin': 60754,
    'ph-sign-in-thin': 60755,
    'ph-sign-out-thin': 60756,
    'ph-signpost-thin': 60757,
    'ph-sim-card-thin': 60758,
    'ph-sketch-logo-thin': 60759,
    'ph-skip-back-circle-thin': 60760,
    'ph-skip-back-thin': 60761,
    'ph-skip-forward-circle-thin': 60762,
    'ph-skip-forward-thin': 60763,
    'ph-skull-thin': 60764,
    'ph-slack-logo-thin': 60765,
    'ph-sliders-horizontal-thin': 60766,
    'ph-sliders-thin': 60767,
    'ph-smiley-blank-thin': 60768,
    'ph-smiley-meh-thin': 60769,
    'ph-smiley-nervous-thin': 60770,
    'ph-smiley-sad-thin': 60771,
    'ph-smiley-sticker-thin': 60772,
    'ph-smiley-thin': 60773,
    'ph-smiley-wink-thin': 60774,
    'ph-smiley-x-eyes-thin': 60775,
    'ph-snapchat-logo-thin': 60776,
    'ph-snowflake-thin': 60777,
    'ph-soccer-ball-thin': 60778,
    'ph-sort-ascending-thin': 60779,
    'ph-sort-descending-thin': 60780,
    'ph-spade-thin': 60781,
    'ph-sparkle-thin': 60782,
    'ph-speaker-high-thin': 60783,
    'ph-speaker-low-thin': 60784,
    'ph-speaker-none-thin': 60785,
    'ph-speaker-simple-high-thin': 60786,
    'ph-speaker-simple-low-thin': 60787,
    'ph-speaker-simple-none-thin': 60788,
    'ph-speaker-simple-slash-thin': 60789,
    'ph-speaker-simple-x-thin': 60790,
    'ph-speaker-slash-thin': 60791,
    'ph-speaker-x-thin': 60792,
    'ph-spinner-gap-thin': 60793,
    'ph-spinner-thin': 60794,
    'ph-spiral-thin': 60795,
    'ph-spotify-logo-thin': 60796,
    'ph-square-half-bottom-thin': 60797,
    'ph-square-half-thin': 60798,
    'ph-square-logo-thin': 60799,
    'ph-square-thin': 60800,
    'ph-squares-four-thin': 60801,
    'ph-stack-overflow-logo-thin': 60802,
    'ph-stack-simple-thin': 60803,
    'ph-stack-thin': 60804,
    'ph-stamp-thin': 60805,
    'ph-star-four-thin': 60806,
    'ph-star-half-thin': 60807,
    'ph-star-thin': 60808,
    'ph-sticker-thin': 60809,
    'ph-stop-circle-thin': 60810,
    'ph-stop-thin': 60811,
    'ph-storefront-thin': 60812,
    'ph-strategy-thin': 60813,
    'ph-stripe-logo-thin': 60814,
    'ph-student-thin': 60815,
    'ph-suitcase-simple-thin': 60816,
    'ph-suitcase-thin': 60817,
    'ph-sun-dim-thin': 60818,
    'ph-sun-horizon-thin': 60819,
    'ph-sun-thin': 60820,
    'ph-sunglasses-thin': 60821,
    'ph-swap-thin': 60822,
    'ph-swatches-thin': 60823,
    'ph-sword-thin': 60824,
    'ph-syringe-thin': 60825,
    'ph-t-shirt-thin': 60826,
    'ph-table-thin': 60827,
    'ph-tabs-thin': 60828,
    'ph-tag-chevron-thin': 60829,
    'ph-tag-simple-thin': 60830,
    'ph-tag-thin': 60831,
    'ph-target-thin': 60832,
    'ph-taxi-thin': 60833,
    'ph-telegram-logo-thin': 60834,
    'ph-television-simple-thin': 60835,
    'ph-television-thin': 60836,
    'ph-tennis-ball-thin': 60837,
    'ph-terminal-thin': 60838,
    'ph-terminal-window-thin': 60839,
    'ph-test-tube-thin': 60840,
    'ph-text-aa-thin': 60841,
    'ph-text-align-center-thin': 60842,
    'ph-text-align-justify-thin': 60843,
    'ph-text-align-left-thin': 60844,
    'ph-text-align-right-thin': 60845,
    'ph-text-bolder-thin': 60846,
    'ph-text-h-five-thin': 60847,
    'ph-text-h-four-thin': 60848,
    'ph-text-h-one-thin': 60849,
    'ph-text-h-six-thin': 60850,
    'ph-text-h-thin': 60851,
    'ph-text-h-three-thin': 60852,
    'ph-text-h-two-thin': 60853,
    'ph-text-indent-thin': 60854,
    'ph-text-italic-thin': 60855,
    'ph-text-outdent-thin': 60856,
    'ph-text-strikethrough-thin': 60857,
    'ph-text-t-thin': 60858,
    'ph-text-underline-thin': 60859,
    'ph-textbox-thin': 60860,
    'ph-thermometer-cold-thin': 60861,
    'ph-thermometer-hot-thin': 60862,
    'ph-thermometer-simple-thin': 60863,
    'ph-thermometer-thin': 60864,
    'ph-thumbs-down-thin': 60865,
    'ph-thumbs-up-thin': 60866,
    'ph-ticket-thin': 60867,
    'ph-tiktok-logo-thin': 60868,
    'ph-timer-thin': 60869,
    'ph-toggle-left-thin': 60870,
    'ph-toggle-right-thin': 60871,
    'ph-toilet-paper-thin': 60872,
    'ph-toilet-thin': 60873,
    'ph-tote-simple-thin': 60874,
    'ph-tote-thin': 60875,
    'ph-trademark-registered-thin': 60876,
    'ph-traffic-cone-thin': 60877,
    'ph-traffic-sign-thin': 60878,
    'ph-traffic-signal-thin': 60879,
    'ph-train-regional-thin': 60880,
    'ph-train-simple-thin': 60881,
    'ph-train-thin': 60882,
    'ph-translate-thin': 60883,
    'ph-trash-simple-thin': 60884,
    'ph-trash-thin': 60885,
    'ph-tray-thin': 60886,
    'ph-tree-evergreen-thin': 60887,
    'ph-tree-structure-thin': 60888,
    'ph-tree-thin': 60889,
    'ph-trend-down-thin': 60890,
    'ph-trend-up-thin': 60891,
    'ph-triangle-thin': 60892,
    'ph-trophy-thin': 60893,
    'ph-truck-thin': 60894,
    'ph-twitch-logo-thin': 60895,
    'ph-twitter-logo-thin': 60896,
    'ph-umbrella-simple-thin': 60897,
    'ph-umbrella-thin': 60898,
    'ph-upload-simple-thin': 60899,
    'ph-upload-thin': 60900,
    'ph-user-circle-gear-thin': 60901,
    'ph-user-circle-minus-thin': 60902,
    'ph-user-circle-plus-thin': 60903,
    'ph-user-circle-thin': 60904,
    'ph-user-focus-thin': 60905,
    'ph-user-gear-thin': 60906,
    'ph-user-list-thin': 60907,
    'ph-user-minus-thin': 60908,
    'ph-user-plus-thin': 60909,
    'ph-user-rectangle-thin': 60910,
    'ph-user-square-thin': 60911,
    'ph-user-switch-thin': 60912,
    'ph-user-thin': 60913,
    'ph-users-four-thin': 60914,
    'ph-users-thin': 60915,
    'ph-users-three-thin': 60916,
    'ph-vault-thin': 60917,
    'ph-vibrate-thin': 60918,
    'ph-video-camera-slash-thin': 60919,
    'ph-video-camera-thin': 60920,
    'ph-vignette-thin': 60921,
    'ph-voicemail-thin': 60922,
    'ph-volleyball-thin': 60923,
    'ph-wall-thin': 60924,
    'ph-wallet-thin': 60925,
    'ph-warning-circle-thin': 60926,
    'ph-warning-octagon-thin': 60927,
    'ph-warning-thin': 60928,
    'ph-watch-thin': 60929,
    'ph-wave-sawtooth-thin': 60930,
    'ph-wave-sine-thin': 60931,
    'ph-wave-square-thin': 60932,
    'ph-wave-triangle-thin': 60933,
    'ph-waves-thin': 60934,
    'ph-webcam-thin': 60935,
    'ph-whatsapp-logo-thin': 60936,
    'ph-wheelchair-thin': 60937,
    'ph-wifi-high-thin': 60938,
    'ph-wifi-low-thin': 60939,
    'ph-wifi-medium-thin': 60940,
    'ph-wifi-none-thin': 60941,
    'ph-wifi-slash-thin': 60942,
    'ph-wifi-x-thin': 60943,
    'ph-wind-thin': 60944,
    'ph-windows-logo-thin': 60945,
    'ph-wine-thin': 60946,
    'ph-wrench-thin': 60947,
    'ph-x-circle-thin': 60948,
    'ph-x-square-thin': 60949,
    'ph-x-thin': 60950,
    'ph-yin-yang-thin': 60951,
    'ph-youtube-logo-thin': 60952,
    'ph-activity-light': 60953,
    'ph-address-book-light': 60954,
    'ph-airplane-in-flight-light': 60955,
    'ph-airplane-landing-light': 60956,
    'ph-airplane-light': 60957,
    'ph-airplane-takeoff-light': 60958,
    'ph-airplane-tilt-light': 60959,
    'ph-airplay-light': 60960,
    'ph-alarm-light': 60961,
    'ph-alien-light': 60962,
    'ph-align-bottom-light': 60963,
    'ph-align-bottom-simple-light': 60964,
    'ph-align-center-horizontal-light': 60965,
    'ph-align-center-horizontal-simple-light': 60966,
    'ph-align-center-vertical-light': 60967,
    'ph-align-center-vertical-simple-light': 60968,
    'ph-align-left-light': 60969,
    'ph-align-left-simple-light': 60970,
    'ph-align-right-light': 60971,
    'ph-align-right-simple-light': 60972,
    'ph-align-top-light': 60973,
    'ph-align-top-simple-light': 60974,
    'ph-anchor-light': 60975,
    'ph-anchor-simple-light': 60976,
    'ph-android-logo-light': 60977,
    'ph-angular-logo-light': 60978,
    'ph-aperture-light': 60979,
    'ph-app-store-logo-light': 60980,
    'ph-app-window-light': 60981,
    'ph-apple-logo-light': 60982,
    'ph-apple-podcasts-logo-light': 60983,
    'ph-archive-box-light': 60984,
    'ph-archive-light': 60985,
    'ph-archive-tray-light': 60986,
    'ph-armchair-light': 60987,
    'ph-arrow-arc-left-light': 60988,
    'ph-arrow-arc-right-light': 60989,
    'ph-arrow-bend-double-up-left-light': 60990,
    'ph-arrow-bend-double-up-right-light': 60991,
    'ph-arrow-bend-down-left-light': 60992,
    'ph-arrow-bend-down-right-light': 60993,
    'ph-arrow-bend-left-down-light': 60994,
    'ph-arrow-bend-left-up-light': 60995,
    'ph-arrow-bend-right-down-light': 60996,
    'ph-arrow-bend-right-up-light': 60997,
    'ph-arrow-bend-up-left-light': 60998,
    'ph-arrow-bend-up-right-light': 60999,
    'ph-arrow-circle-down-left-light': 61000,
    'ph-arrow-circle-down-light': 61001,
    'ph-arrow-circle-down-right-light': 61002,
    'ph-arrow-circle-left-light': 61003,
    'ph-arrow-circle-right-light': 61004,
    'ph-arrow-circle-up-left-light': 61005,
    'ph-arrow-circle-up-light': 61006,
    'ph-arrow-circle-up-right-light': 61007,
    'ph-arrow-clockwise-light': 61008,
    'ph-arrow-counter-clockwise-light': 61009,
    'ph-arrow-down-left-light': 61010,
    'ph-arrow-down-light': 61011,
    'ph-arrow-down-right-light': 61012,
    'ph-arrow-elbow-down-left-light': 61013,
    'ph-arrow-elbow-down-right-light': 61014,
    'ph-arrow-elbow-left-down-light': 61015,
    'ph-arrow-elbow-left-light': 61016,
    'ph-arrow-elbow-left-up-light': 61017,
    'ph-arrow-elbow-right-down-light': 61018,
    'ph-arrow-elbow-right-light': 61019,
    'ph-arrow-elbow-right-up-light': 61020,
    'ph-arrow-elbow-up-left-light': 61021,
    'ph-arrow-elbow-up-right-light': 61022,
    'ph-arrow-fat-down-light': 61023,
    'ph-arrow-fat-left-light': 61024,
    'ph-arrow-fat-line-down-light': 61025,
    'ph-arrow-fat-line-left-light': 61026,
    'ph-arrow-fat-line-right-light': 61027,
    'ph-arrow-fat-line-up-light': 61028,
    'ph-arrow-fat-lines-down-light': 61029,
    'ph-arrow-fat-lines-left-light': 61030,
    'ph-arrow-fat-lines-right-light': 61031,
    'ph-arrow-fat-lines-up-light': 61032,
    'ph-arrow-fat-right-light': 61033,
    'ph-arrow-fat-up-light': 61034,
    'ph-arrow-left-light': 61035,
    'ph-arrow-line-down-left-light': 61036,
    'ph-arrow-line-down-light': 61037,
    'ph-arrow-line-down-right-light': 61038,
    'ph-arrow-line-left-light': 61039,
    'ph-arrow-line-right-light': 61040,
    'ph-arrow-line-up-left-light': 61041,
    'ph-arrow-line-up-light': 61042,
    'ph-arrow-line-up-right-light': 61043,
    'ph-arrow-right-light': 61044,
    'ph-arrow-square-down-left-light': 61045,
    'ph-arrow-square-down-light': 61046,
    'ph-arrow-square-down-right-light': 61047,
    'ph-arrow-square-in-light': 61048,
    'ph-arrow-square-left-light': 61049,
    'ph-arrow-square-out-light': 61050,
    'ph-arrow-square-right-light': 61051,
    'ph-arrow-square-up-left-light': 61052,
    'ph-arrow-square-up-light': 61053,
    'ph-arrow-square-up-right-light': 61054,
    'ph-arrow-u-down-left-light': 61055,
    'ph-arrow-u-down-right-light': 61056,
    'ph-arrow-u-left-down-light': 61057,
    'ph-arrow-u-left-up-light': 61058,
    'ph-arrow-u-right-down-light': 61059,
    'ph-arrow-u-right-up-light': 61060,
    'ph-arrow-u-up-left-light': 61061,
    'ph-arrow-u-up-right-light': 61062,
    'ph-arrow-up-left-light': 61063,
    'ph-arrow-up-light': 61064,
    'ph-arrow-up-right-light': 61065,
    'ph-arrows-clockwise-light': 61066,
    'ph-arrows-counter-clockwise-light': 61067,
    'ph-arrows-down-up-light': 61068,
    'ph-arrows-horizontal-light': 61069,
    'ph-arrows-in-cardinal-light': 61070,
    'ph-arrows-in-light': 61071,
    'ph-arrows-in-line-horizontal-light': 61072,
    'ph-arrows-in-line-vertical-light': 61073,
    'ph-arrows-in-simple-light': 61074,
    'ph-arrows-left-right-light': 61075,
    'ph-arrows-out-cardinal-light': 61076,
    'ph-arrows-out-light': 61077,
    'ph-arrows-out-line-horizontal-light': 61078,
    'ph-arrows-out-line-vertical-light': 61079,
    'ph-arrows-out-simple-light': 61080,
    'ph-arrows-vertical-light': 61081,
    'ph-article-light': 61082,
    'ph-article-medium-light': 61083,
    'ph-article-ny-times-light': 61084,
    'ph-asterisk-light': 61085,
    'ph-asterisk-simple-light': 61086,
    'ph-at-light': 61087,
    'ph-atom-light': 61088,
    'ph-baby-light': 61089,
    'ph-backpack-light': 61090,
    'ph-backspace-light': 61091,
    'ph-bag-light': 61092,
    'ph-bag-simple-light': 61093,
    'ph-balloon-light': 61094,
    'ph-bandaids-light': 61095,
    'ph-bank-light': 61096,
    'ph-barbell-light': 61097,
    'ph-barcode-light': 61098,
    'ph-barricade-light': 61099,
    'ph-baseball-light': 61100,
    'ph-basketball-light': 61101,
    'ph-bathtub-light': 61102,
    'ph-battery-charging-light': 61103,
    'ph-battery-charging-vertical-light': 61104,
    'ph-battery-empty-light': 61105,
    'ph-battery-full-light': 61106,
    'ph-battery-high-light': 61107,
    'ph-battery-low-light': 61108,
    'ph-battery-medium-light': 61109,
    'ph-battery-plus-light': 61110,
    'ph-battery-warning-light': 61111,
    'ph-battery-warning-vertical-light': 61112,
    'ph-bed-light': 61113,
    'ph-beer-bottle-light': 61114,
    'ph-behance-logo-light': 61115,
    'ph-bell-light': 61116,
    'ph-bell-ringing-light': 61117,
    'ph-bell-simple-light': 61118,
    'ph-bell-simple-ringing-light': 61119,
    'ph-bell-simple-slash-light': 61120,
    'ph-bell-simple-z-light': 61121,
    'ph-bell-slash-light': 61122,
    'ph-bell-z-light': 61123,
    'ph-bezier-curve-light': 61124,
    'ph-bicycle-light': 61125,
    'ph-binoculars-light': 61126,
    'ph-bird-light': 61127,
    'ph-bluetooth-connected-light': 61128,
    'ph-bluetooth-light': 61129,
    'ph-bluetooth-slash-light': 61130,
    'ph-bluetooth-x-light': 61131,
    'ph-boat-light': 61132,
    'ph-book-bookmark-light': 61133,
    'ph-book-light': 61134,
    'ph-book-open-light': 61135,
    'ph-bookmark-light': 61136,
    'ph-bookmark-simple-light': 61137,
    'ph-bookmarks-light': 61138,
    'ph-bookmarks-simple-light': 61139,
    'ph-books-light': 61140,
    'ph-bounding-box-light': 61141,
    'ph-brackets-angle-light': 61142,
    'ph-brackets-curly-light': 61143,
    'ph-brackets-round-light': 61144,
    'ph-brackets-square-light': 61145,
    'ph-brain-light': 61146,
    'ph-brandy-light': 61147,
    'ph-briefcase-light': 61148,
    'ph-briefcase-metal-light': 61149,
    'ph-broadcast-light': 61150,
    'ph-browser-light': 61151,
    'ph-browsers-light': 61152,
    'ph-bug-beetle-light': 61153,
    'ph-bug-droid-light': 61154,
    'ph-bug-light': 61155,
    'ph-buildings-light': 61156,
    'ph-bus-light': 61157,
    'ph-butterfly-light': 61158,
    'ph-cactus-light': 61159,
    'ph-cake-light': 61160,
    'ph-calculator-light': 61161,
    'ph-calendar-blank-light': 61162,
    'ph-calendar-check-light': 61163,
    'ph-calendar-light': 61164,
    'ph-calendar-plus-light': 61165,
    'ph-calendar-x-light': 61166,
    'ph-camera-light': 61167,
    'ph-camera-rotate-light': 61168,
    'ph-camera-slash-light': 61169,
    'ph-campfire-light': 61170,
    'ph-car-light': 61171,
    'ph-car-simple-light': 61172,
    'ph-cardholder-light': 61173,
    'ph-cards-light': 61174,
    'ph-caret-circle-double-down-light': 61175,
    'ph-caret-circle-double-left-light': 61176,
    'ph-caret-circle-double-right-light': 61177,
    'ph-caret-circle-double-up-light': 61178,
    'ph-caret-circle-down-light': 61179,
    'ph-caret-circle-left-light': 61180,
    'ph-caret-circle-right-light': 61181,
    'ph-caret-circle-up-light': 61182,
    'ph-caret-double-down-light': 61183,
    'ph-caret-double-left-light': 61184,
    'ph-caret-double-right-light': 61185,
    'ph-caret-double-up-light': 61186,
    'ph-caret-down-light': 61187,
    'ph-caret-left-light': 61188,
    'ph-caret-right-light': 61189,
    'ph-caret-up-light': 61190,
    'ph-cat-light': 61191,
    'ph-cell-signal-full-light': 61192,
    'ph-cell-signal-high-light': 61193,
    'ph-cell-signal-low-light': 61194,
    'ph-cell-signal-medium-light': 61195,
    'ph-cell-signal-none-light': 61196,
    'ph-cell-signal-slash-light': 61197,
    'ph-cell-signal-x-light': 61198,
    'ph-chalkboard-light': 61199,
    'ph-chalkboard-simple-light': 61200,
    'ph-chalkboard-teacher-light': 61201,
    'ph-chart-bar-horizontal-light': 61202,
    'ph-chart-bar-light': 61203,
    'ph-chart-line-light': 61204,
    'ph-chart-line-up-light': 61205,
    'ph-chart-pie-light': 61206,
    'ph-chart-pie-slice-light': 61207,
    'ph-chat-centered-dots-light': 61208,
    'ph-chat-centered-light': 61209,
    'ph-chat-centered-text-light': 61210,
    'ph-chat-circle-dots-light': 61211,
    'ph-chat-circle-light': 61212,
    'ph-chat-circle-text-light': 61213,
    'ph-chat-dots-light': 61214,
    'ph-chat-light': 61215,
    'ph-chat-teardrop-dots-light': 61216,
    'ph-chat-teardrop-light': 61217,
    'ph-chat-teardrop-text-light': 61218,
    'ph-chat-text-light': 61219,
    'ph-chats-circle-light': 61220,
    'ph-chats-light': 61221,
    'ph-chats-teardrop-light': 61222,
    'ph-check-circle-light': 61223,
    'ph-check-light': 61224,
    'ph-check-square-light': 61225,
    'ph-check-square-offset-light': 61226,
    'ph-checks-light': 61227,
    'ph-circle-dashed-light': 61228,
    'ph-circle-half-light': 61229,
    'ph-circle-half-tilt-light': 61230,
    'ph-circle-light': 61231,
    'ph-circle-notch-light': 61232,
    'ph-circle-wavy-check-light': 61233,
    'ph-circle-wavy-light': 61234,
    'ph-circle-wavy-question-light': 61235,
    'ph-circle-wavy-warning-light': 61236,
    'ph-circles-four-light': 61237,
    'ph-circles-three-light': 61238,
    'ph-circles-three-plus-light': 61239,
    'ph-clipboard-light': 61240,
    'ph-clipboard-text-light': 61241,
    'ph-clock-afternoon-light': 61242,
    'ph-clock-clockwise-light': 61243,
    'ph-clock-counter-clockwise-light': 61244,
    'ph-clock-light': 61245,
    'ph-closed-captioning-light': 61246,
    'ph-cloud-arrow-down-light': 61247,
    'ph-cloud-arrow-up-light': 61248,
    'ph-cloud-check-light': 61249,
    'ph-cloud-fog-light': 61250,
    'ph-cloud-light': 61251,
    'ph-cloud-lightning-light': 61252,
    'ph-cloud-moon-light': 61253,
    'ph-cloud-rain-light': 61254,
    'ph-cloud-slash-light': 61255,
    'ph-cloud-snow-light': 61256,
    'ph-cloud-sun-light': 61257,
    'ph-club-light': 61258,
    'ph-coat-hanger-light': 61259,
    'ph-code-light': 61260,
    'ph-code-simple-light': 61261,
    'ph-codepen-logo-light': 61262,
    'ph-codesandbox-logo-light': 61263,
    'ph-coffee-light': 61264,
    'ph-coin-light': 61265,
    'ph-coin-vertical-light': 61266,
    'ph-coins-light': 61267,
    'ph-columns-light': 61268,
    'ph-command-light': 61269,
    'ph-compass-light': 61270,
    'ph-computer-tower-light': 61271,
    'ph-confetti-light': 61272,
    'ph-cookie-light': 61273,
    'ph-cooking-pot-light': 61274,
    'ph-copy-light': 61275,
    'ph-copy-simple-light': 61276,
    'ph-copyleft-light': 61277,
    'ph-copyright-light': 61278,
    'ph-corners-in-light': 61279,
    'ph-corners-out-light': 61280,
    'ph-cpu-light': 61281,
    'ph-credit-card-light': 61282,
    'ph-crop-light': 61283,
    'ph-crosshair-light': 61284,
    'ph-crosshair-simple-light': 61285,
    'ph-crown-light': 61286,
    'ph-crown-simple-light': 61287,
    'ph-cube-light': 61288,
    'ph-currency-btc-light': 61289,
    'ph-currency-circle-dollar-light': 61290,
    'ph-currency-cny-light': 61291,
    'ph-currency-dollar-light': 61292,
    'ph-currency-dollar-simple-light': 61293,
    'ph-currency-eth-light': 61294,
    'ph-currency-eur-light': 61295,
    'ph-currency-gbp-light': 61296,
    'ph-currency-inr-light': 61297,
    'ph-currency-jpy-light': 61298,
    'ph-currency-krw-light': 61299,
    'ph-currency-kzt-light': 61300,
    'ph-currency-ngn-light': 61301,
    'ph-currency-rub-light': 61302,
    'ph-cursor-light': 61303,
    'ph-cursor-text-light': 61304,
    'ph-cylinder-light': 61305,
    'ph-database-light': 61306,
    'ph-desktop-light': 61307,
    'ph-desktop-tower-light': 61308,
    'ph-detective-light': 61309,
    'ph-device-mobile-camera-light': 61310,
    'ph-device-mobile-light': 61311,
    'ph-device-mobile-speaker-light': 61312,
    'ph-device-tablet-camera-light': 61313,
    'ph-device-tablet-light': 61314,
    'ph-device-tablet-speaker-light': 61315,
    'ph-diamond-light': 61316,
    'ph-diamonds-four-light': 61317,
    'ph-dice-five-light': 61318,
    'ph-dice-four-light': 61319,
    'ph-dice-one-light': 61320,
    'ph-dice-six-light': 61321,
    'ph-dice-three-light': 61322,
    'ph-dice-two-light': 61323,
    'ph-disc-light': 61324,
    'ph-discord-logo-light': 61325,
    'ph-divide-light': 61326,
    'ph-dog-light': 61327,
    'ph-door-light': 61328,
    'ph-dots-nine-light': 61329,
    'ph-dots-six-light': 61330,
    'ph-dots-six-vertical-light': 61331,
    'ph-dots-three-circle-light': 61332,
    'ph-dots-three-circle-vertical-light': 61333,
    'ph-dots-three-light': 61334,
    'ph-dots-three-outline-light': 61335,
    'ph-dots-three-outline-vertical-light': 61336,
    'ph-dots-three-vertical-light': 61337,
    'ph-download-light': 61338,
    'ph-download-simple-light': 61339,
    'ph-dribbble-logo-light': 61340,
    'ph-drop-half-bottom-light': 61341,
    'ph-drop-half-light': 61342,
    'ph-drop-light': 61343,
    'ph-ear-light': 61344,
    'ph-ear-slash-light': 61345,
    'ph-egg-crack-light': 61346,
    'ph-egg-light': 61347,
    'ph-eject-light': 61348,
    'ph-eject-simple-light': 61349,
    'ph-envelope-light': 61350,
    'ph-envelope-open-light': 61351,
    'ph-envelope-simple-light': 61352,
    'ph-envelope-simple-open-light': 61353,
    'ph-equalizer-light': 61354,
    'ph-equals-light': 61355,
    'ph-eraser-light': 61356,
    'ph-exam-light': 61357,
    'ph-export-light': 61358,
    'ph-eye-closed-light': 61359,
    'ph-eye-light': 61360,
    'ph-eye-slash-light': 61361,
    'ph-eyedropper-light': 61362,
    'ph-eyedropper-sample-light': 61363,
    'ph-eyeglasses-light': 61364,
    'ph-face-mask-light': 61365,
    'ph-facebook-logo-light': 61366,
    'ph-factory-light': 61367,
    'ph-faders-horizontal-light': 61368,
    'ph-faders-light': 61369,
    'ph-fast-forward-circle-light': 61370,
    'ph-fast-forward-light': 61371,
    'ph-figma-logo-light': 61372,
    'ph-file-arrow-down-light': 61373,
    'ph-file-arrow-up-light': 61374,
    'ph-file-audio-light': 61375,
    'ph-file-cloud-light': 61376,
    'ph-file-code-light': 61377,
    'ph-file-css-light': 61378,
    'ph-file-csv-light': 61379,
    'ph-file-doc-light': 61380,
    'ph-file-dotted-light': 61381,
    'ph-file-html-light': 61382,
    'ph-file-image-light': 61383,
    'ph-file-jpg-light': 61384,
    'ph-file-js-light': 61385,
    'ph-file-jsx-light': 61386,
    'ph-file-light': 61387,
    'ph-file-lock-light': 61388,
    'ph-file-minus-light': 61389,
    'ph-file-pdf-light': 61390,
    'ph-file-plus-light': 61391,
    'ph-file-png-light': 61392,
    'ph-file-ppt-light': 61393,
    'ph-file-rs-light': 61394,
    'ph-file-search-light': 61395,
    'ph-file-text-light': 61396,
    'ph-file-ts-light': 61397,
    'ph-file-tsx-light': 61398,
    'ph-file-video-light': 61399,
    'ph-file-vue-light': 61400,
    'ph-file-x-light': 61401,
    'ph-file-xls-light': 61402,
    'ph-file-zip-light': 61403,
    'ph-files-light': 61404,
    'ph-film-script-light': 61405,
    'ph-film-slate-light': 61406,
    'ph-film-strip-light': 61407,
    'ph-fingerprint-light': 61408,
    'ph-fingerprint-simple-light': 61409,
    'ph-finn-the-human-light': 61410,
    'ph-fire-light': 61411,
    'ph-fire-simple-light': 61412,
    'ph-first-aid-kit-light': 61413,
    'ph-first-aid-light': 61414,
    'ph-fish-light': 61415,
    'ph-fish-simple-light': 61416,
    'ph-flag-banner-light': 61417,
    'ph-flag-checkered-light': 61418,
    'ph-flag-light': 61419,
    'ph-flame-light': 61420,
    'ph-flashlight-light': 61421,
    'ph-flask-light': 61422,
    'ph-floppy-disk-back-light': 61423,
    'ph-floppy-disk-light': 61424,
    'ph-flow-arrow-light': 61425,
    'ph-flower-light': 61426,
    'ph-flower-lotus-light': 61427,
    'ph-flying-saucer-light': 61428,
    'ph-folder-dotted-light': 61429,
    'ph-folder-light': 61430,
    'ph-folder-lock-light': 61431,
    'ph-folder-minus-light': 61432,
    'ph-folder-notch-light': 61433,
    'ph-folder-notch-minus-light': 61434,
    'ph-folder-notch-open-light': 61435,
    'ph-folder-notch-plus-light': 61436,
    'ph-folder-open-light': 61437,
    'ph-folder-plus-light': 61438,
    'ph-folder-simple-dotted-light': 61439,
    'ph-folder-simple-light': 61440,
    'ph-folder-simple-lock-light': 61441,
    'ph-folder-simple-minus-light': 61442,
    'ph-folder-simple-plus-light': 61443,
    'ph-folder-simple-star-light': 61444,
    'ph-folder-simple-user-light': 61445,
    'ph-folder-star-light': 61446,
    'ph-folder-user-light': 61447,
    'ph-folders-light': 61448,
    'ph-football-light': 61449,
    'ph-fork-knife-light': 61450,
    'ph-frame-corners-light': 61451,
    'ph-framer-logo-light': 61452,
    'ph-function-light': 61453,
    'ph-funnel-light': 61454,
    'ph-funnel-simple-light': 61455,
    'ph-game-controller-light': 61456,
    'ph-gas-pump-light': 61457,
    'ph-gauge-light': 61458,
    'ph-gear-light': 61459,
    'ph-gear-six-light': 61460,
    'ph-gender-female-light': 61461,
    'ph-gender-intersex-light': 61462,
    'ph-gender-male-light': 61463,
    'ph-gender-neuter-light': 61464,
    'ph-gender-nonbinary-light': 61465,
    'ph-gender-transgender-light': 61466,
    'ph-ghost-light': 61467,
    'ph-gif-light': 61468,
    'ph-gift-light': 61469,
    'ph-git-branch-light': 61470,
    'ph-git-commit-light': 61471,
    'ph-git-diff-light': 61472,
    'ph-git-fork-light': 61473,
    'ph-git-merge-light': 61474,
    'ph-git-pull-request-light': 61475,
    'ph-github-logo-light': 61476,
    'ph-gitlab-logo-light': 61477,
    'ph-gitlab-logo-simple-light': 61478,
    'ph-globe-hemisphere-east-light': 61479,
    'ph-globe-hemisphere-west-light': 61480,
    'ph-globe-light': 61481,
    'ph-globe-simple-light': 61482,
    'ph-globe-stand-light': 61483,
    'ph-google-chrome-logo-light': 61484,
    'ph-google-logo-light': 61485,
    'ph-google-photos-logo-light': 61486,
    'ph-google-play-logo-light': 61487,
    'ph-google-podcasts-logo-light': 61488,
    'ph-gradient-light': 61489,
    'ph-graduation-cap-light': 61490,
    'ph-graph-light': 61491,
    'ph-grid-four-light': 61492,
    'ph-hamburger-light': 61493,
    'ph-hand-eye-light': 61494,
    'ph-hand-fist-light': 61495,
    'ph-hand-grabbing-light': 61496,
    'ph-hand-light': 61497,
    'ph-hand-palm-light': 61498,
    'ph-hand-pointing-light': 61499,
    'ph-hand-soap-light': 61500,
    'ph-hand-waving-light': 61501,
    'ph-handbag-light': 61502,
    'ph-handbag-simple-light': 61503,
    'ph-hands-clapping-light': 61504,
    'ph-handshake-light': 61505,
    'ph-hard-drive-light': 61506,
    'ph-hard-drives-light': 61507,
    'ph-hash-light': 61508,
    'ph-hash-straight-light': 61509,
    'ph-headlights-light': 61510,
    'ph-headphones-light': 61511,
    'ph-headset-light': 61512,
    'ph-heart-break-light': 61513,
    'ph-heart-light': 61514,
    'ph-heart-straight-break-light': 61515,
    'ph-heart-straight-light': 61516,
    'ph-heartbeat-light': 61517,
    'ph-hexagon-light': 61518,
    'ph-highlighter-circle-light': 61519,
    'ph-horse-light': 61520,
    'ph-hourglass-high-light': 61521,
    'ph-hourglass-light': 61522,
    'ph-hourglass-low-light': 61523,
    'ph-hourglass-medium-light': 61524,
    'ph-hourglass-simple-high-light': 61525,
    'ph-hourglass-simple-light': 61526,
    'ph-hourglass-simple-low-light': 61527,
    'ph-hourglass-simple-medium-light': 61528,
    'ph-house-light': 61529,
    'ph-house-line-light': 61530,
    'ph-house-simple-light': 61531,
    'ph-identification-badge-light': 61532,
    'ph-identification-card-light': 61533,
    'ph-image-light': 61534,
    'ph-image-square-light': 61535,
    'ph-infinity-light': 61536,
    'ph-info-light': 61537,
    'ph-instagram-logo-light': 61538,
    'ph-intersect-light': 61539,
    'ph-jeep-light': 61540,
    'ph-kanban-light': 61541,
    'ph-key-light': 61542,
    'ph-key-return-light': 61543,
    'ph-keyboard-light': 61544,
    'ph-keyhole-light': 61545,
    'ph-knife-light': 61546,
    'ph-ladder-light': 61547,
    'ph-ladder-simple-light': 61548,
    'ph-lamp-light': 61549,
    'ph-laptop-light': 61550,
    'ph-layout-light': 61551,
    'ph-leaf-light': 61552,
    'ph-lifebuoy-light': 61553,
    'ph-lightbulb-filament-light': 61554,
    'ph-lightbulb-light': 61555,
    'ph-lightning-light': 61556,
    'ph-lightning-slash-light': 61557,
    'ph-line-segment-light': 61558,
    'ph-line-segments-light': 61559,
    'ph-link-break-light': 61560,
    'ph-link-light': 61561,
    'ph-link-simple-break-light': 61562,
    'ph-link-simple-horizontal-break-light': 61563,
    'ph-link-simple-horizontal-light': 61564,
    'ph-link-simple-light': 61565,
    'ph-linkedin-logo-light': 61566,
    'ph-linux-logo-light': 61567,
    'ph-list-bullets-light': 61568,
    'ph-list-checks-light': 61569,
    'ph-list-dashes-light': 61570,
    'ph-list-light': 61571,
    'ph-list-numbers-light': 61572,
    'ph-list-plus-light': 61573,
    'ph-lock-key-light': 61574,
    'ph-lock-key-open-light': 61575,
    'ph-lock-laminated-light': 61576,
    'ph-lock-laminated-open-light': 61577,
    'ph-lock-light': 61578,
    'ph-lock-open-light': 61579,
    'ph-lock-simple-light': 61580,
    'ph-lock-simple-open-light': 61581,
    'ph-magic-wand-light': 61582,
    'ph-magnet-light': 61583,
    'ph-magnet-straight-light': 61584,
    'ph-magnifying-glass-light': 61585,
    'ph-magnifying-glass-minus-light': 61586,
    'ph-magnifying-glass-plus-light': 61587,
    'ph-map-pin-light': 61588,
    'ph-map-pin-line-light': 61589,
    'ph-map-trifold-light': 61590,
    'ph-marker-circle-light': 61591,
    'ph-martini-light': 61592,
    'ph-mask-happy-light': 61593,
    'ph-mask-sad-light': 61594,
    'ph-math-operations-light': 61595,
    'ph-medal-light': 61596,
    'ph-medium-logo-light': 61597,
    'ph-megaphone-light': 61598,
    'ph-megaphone-simple-light': 61599,
    'ph-messenger-logo-light': 61600,
    'ph-microphone-light': 61601,
    'ph-microphone-slash-light': 61602,
    'ph-microphone-stage-light': 61603,
    'ph-microsoft-excel-logo-light': 61604,
    'ph-microsoft-powerpoint-logo-light': 61605,
    'ph-microsoft-teams-logo-light': 61606,
    'ph-microsoft-word-logo-light': 61607,
    'ph-minus-circle-light': 61608,
    'ph-minus-light': 61609,
    'ph-money-light': 61610,
    'ph-monitor-light': 61611,
    'ph-monitor-play-light': 61612,
    'ph-moon-light': 61613,
    'ph-moon-stars-light': 61614,
    'ph-mountains-light': 61615,
    'ph-mouse-light': 61616,
    'ph-mouse-simple-light': 61617,
    'ph-music-note-light': 61618,
    'ph-music-note-simple-light': 61619,
    'ph-music-notes-light': 61620,
    'ph-music-notes-plus-light': 61621,
    'ph-music-notes-simple-light': 61622,
    'ph-navigation-arrow-light': 61623,
    'ph-needle-light': 61624,
    'ph-newspaper-clipping-light': 61625,
    'ph-newspaper-light': 61626,
    'ph-note-blank-light': 61627,
    'ph-note-light': 61628,
    'ph-note-pencil-light': 61629,
    'ph-notebook-light': 61630,
    'ph-notepad-light': 61631,
    'ph-notification-light': 61632,
    'ph-number-circle-eight-light': 61633,
    'ph-number-circle-five-light': 61634,
    'ph-number-circle-four-light': 61635,
    'ph-number-circle-nine-light': 61636,
    'ph-number-circle-one-light': 61637,
    'ph-number-circle-seven-light': 61638,
    'ph-number-circle-six-light': 61639,
    'ph-number-circle-three-light': 61640,
    'ph-number-circle-two-light': 61641,
    'ph-number-circle-zero-light': 61642,
    'ph-number-eight-light': 61643,
    'ph-number-five-light': 61644,
    'ph-number-four-light': 61645,
    'ph-number-nine-light': 61646,
    'ph-number-one-light': 61647,
    'ph-number-seven-light': 61648,
    'ph-number-six-light': 61649,
    'ph-number-square-eight-light': 61650,
    'ph-number-square-five-light': 61651,
    'ph-number-square-four-light': 61652,
    'ph-number-square-nine-light': 61653,
    'ph-number-square-one-light': 61654,
    'ph-number-square-seven-light': 61655,
    'ph-number-square-six-light': 61656,
    'ph-number-square-three-light': 61657,
    'ph-number-square-two-light': 61658,
    'ph-number-square-zero-light': 61659,
    'ph-number-three-light': 61660,
    'ph-number-two-light': 61661,
    'ph-number-zero-light': 61662,
    'ph-nut-light': 61663,
    'ph-ny-times-logo-light': 61664,
    'ph-octagon-light': 61665,
    'ph-option-light': 61666,
    'ph-package-light': 61667,
    'ph-paint-brush-broad-light': 61668,
    'ph-paint-brush-household-light': 61669,
    'ph-paint-brush-light': 61670,
    'ph-paint-bucket-light': 61671,
    'ph-paint-roller-light': 61672,
    'ph-palette-light': 61673,
    'ph-paper-plane-light': 61674,
    'ph-paper-plane-right-light': 61675,
    'ph-paper-plane-tilt-light': 61676,
    'ph-paperclip-horizontal-light': 61677,
    'ph-paperclip-light': 61678,
    'ph-parachute-light': 61679,
    'ph-password-light': 61680,
    'ph-path-light': 61681,
    'ph-pause-circle-light': 61682,
    'ph-pause-light': 61683,
    'ph-paw-print-light': 61684,
    'ph-peace-light': 61685,
    'ph-pen-light': 61686,
    'ph-pen-nib-light': 61687,
    'ph-pen-nib-straight-light': 61688,
    'ph-pencil-circle-light': 61689,
    'ph-pencil-light': 61690,
    'ph-pencil-line-light': 61691,
    'ph-pencil-simple-light': 61692,
    'ph-pencil-simple-line-light': 61693,
    'ph-percent-light': 61694,
    'ph-person-light': 61695,
    'ph-person-simple-light': 61696,
    'ph-person-simple-run-light': 61697,
    'ph-person-simple-walk-light': 61698,
    'ph-perspective-light': 61699,
    'ph-phone-call-light': 61700,
    'ph-phone-disconnect-light': 61701,
    'ph-phone-incoming-light': 61702,
    'ph-phone-light': 61703,
    'ph-phone-outgoing-light': 61704,
    'ph-phone-slash-light': 61705,
    'ph-phone-x-light': 61706,
    'ph-phosphor-logo-light': 61707,
    'ph-piano-keys-light': 61708,
    'ph-picture-in-picture-light': 61709,
    'ph-pill-light': 61710,
    'ph-pinterest-logo-light': 61711,
    'ph-pinwheel-light': 61712,
    'ph-pizza-light': 61713,
    'ph-placeholder-light': 61714,
    'ph-planet-light': 61715,
    'ph-play-circle-light': 61716,
    'ph-play-light': 61717,
    'ph-playlist-light': 61718,
    'ph-plug-light': 61719,
    'ph-plugs-connected-light': 61720,
    'ph-plugs-light': 61721,
    'ph-plus-circle-light': 61722,
    'ph-plus-light': 61723,
    'ph-plus-minus-light': 61724,
    'ph-poker-chip-light': 61725,
    'ph-police-car-light': 61726,
    'ph-polygon-light': 61727,
    'ph-popcorn-light': 61728,
    'ph-power-light': 61729,
    'ph-prescription-light': 61730,
    'ph-presentation-chart-light': 61731,
    'ph-presentation-light': 61732,
    'ph-printer-light': 61733,
    'ph-prohibit-inset-light': 61734,
    'ph-prohibit-light': 61735,
    'ph-projector-screen-chart-light': 61736,
    'ph-projector-screen-light': 61737,
    'ph-push-pin-light': 61738,
    'ph-push-pin-simple-light': 61739,
    'ph-push-pin-simple-slash-light': 61740,
    'ph-push-pin-slash-light': 61741,
    'ph-puzzle-piece-light': 61742,
    'ph-qr-code-light': 61743,
    'ph-question-light': 61744,
    'ph-queue-light': 61745,
    'ph-quotes-light': 61746,
    'ph-radical-light': 61747,
    'ph-radio-button-light': 61748,
    'ph-radio-light': 61749,
    'ph-rainbow-cloud-light': 61750,
    'ph-rainbow-light': 61751,
    'ph-receipt-light': 61752,
    'ph-record-light': 61753,
    'ph-rectangle-light': 61754,
    'ph-recycle-light': 61755,
    'ph-reddit-logo-light': 61756,
    'ph-repeat-light': 61757,
    'ph-repeat-once-light': 61758,
    'ph-rewind-circle-light': 61759,
    'ph-rewind-light': 61760,
    'ph-robot-light': 61761,
    'ph-rocket-launch-light': 61762,
    'ph-rocket-light': 61763,
    'ph-rows-light': 61764,
    'ph-rss-light': 61765,
    'ph-rss-simple-light': 61766,
    'ph-rug-light': 61767,
    'ph-ruler-light': 61768,
    'ph-scales-light': 61769,
    'ph-scan-light': 61770,
    'ph-scissors-light': 61771,
    'ph-screencast-light': 61772,
    'ph-scribble-loop-light': 61773,
    'ph-scroll-light': 61774,
    'ph-selection-all-light': 61775,
    'ph-selection-background-light': 61776,
    'ph-selection-foreground-light': 61777,
    'ph-selection-inverse-light': 61778,
    'ph-selection-light': 61779,
    'ph-selection-plus-light': 61780,
    'ph-selection-slash-light': 61781,
    'ph-share-light': 61782,
    'ph-share-network-light': 61783,
    'ph-shield-check-light': 61784,
    'ph-shield-checkered-light': 61785,
    'ph-shield-chevron-light': 61786,
    'ph-shield-light': 61787,
    'ph-shield-plus-light': 61788,
    'ph-shield-slash-light': 61789,
    'ph-shield-star-light': 61790,
    'ph-shield-warning-light': 61791,
    'ph-shopping-bag-light': 61792,
    'ph-shopping-bag-open-light': 61793,
    'ph-shopping-cart-light': 61794,
    'ph-shopping-cart-simple-light': 61795,
    'ph-shower-light': 61796,
    'ph-shuffle-angular-light': 61797,
    'ph-shuffle-light': 61798,
    'ph-shuffle-simple-light': 61799,
    'ph-sidebar-light': 61800,
    'ph-sidebar-simple-light': 61801,
    'ph-sign-in-light': 61802,
    'ph-sign-out-light': 61803,
    'ph-signpost-light': 61804,
    'ph-sim-card-light': 61805,
    'ph-sketch-logo-light': 61806,
    'ph-skip-back-circle-light': 61807,
    'ph-skip-back-light': 61808,
    'ph-skip-forward-circle-light': 61809,
    'ph-skip-forward-light': 61810,
    'ph-skull-light': 61811,
    'ph-slack-logo-light': 61812,
    'ph-sliders-horizontal-light': 61813,
    'ph-sliders-light': 61814,
    'ph-smiley-blank-light': 61815,
    'ph-smiley-light': 61816,
    'ph-smiley-meh-light': 61817,
    'ph-smiley-nervous-light': 61818,
    'ph-smiley-sad-light': 61819,
    'ph-smiley-sticker-light': 61820,
    'ph-smiley-wink-light': 61821,
    'ph-smiley-x-eyes-light': 61822,
    'ph-snapchat-logo-light': 61823,
    'ph-snowflake-light': 61824,
    'ph-soccer-ball-light': 61825,
    'ph-sort-ascending-light': 61826,
    'ph-sort-descending-light': 61827,
    'ph-spade-light': 61828,
    'ph-sparkle-light': 61829,
    'ph-speaker-high-light': 61830,
    'ph-speaker-low-light': 61831,
    'ph-speaker-none-light': 61832,
    'ph-speaker-simple-high-light': 61833,
    'ph-speaker-simple-low-light': 61834,
    'ph-speaker-simple-none-light': 61835,
    'ph-speaker-simple-slash-light': 61836,
    'ph-speaker-simple-x-light': 61837,
    'ph-speaker-slash-light': 61838,
    'ph-speaker-x-light': 61839,
    'ph-spinner-gap-light': 61840,
    'ph-spinner-light': 61841,
    'ph-spiral-light': 61842,
    'ph-spotify-logo-light': 61843,
    'ph-square-half-bottom-light': 61844,
    'ph-square-half-light': 61845,
    'ph-square-light': 61846,
    'ph-square-logo-light': 61847,
    'ph-squares-four-light': 61848,
    'ph-stack-light': 61849,
    'ph-stack-overflow-logo-light': 61850,
    'ph-stack-simple-light': 61851,
    'ph-stamp-light': 61852,
    'ph-star-four-light': 61853,
    'ph-star-half-light': 61854,
    'ph-star-light': 61855,
    'ph-sticker-light': 61856,
    'ph-stop-circle-light': 61857,
    'ph-stop-light': 61858,
    'ph-storefront-light': 61859,
    'ph-strategy-light': 61860,
    'ph-stripe-logo-light': 61861,
    'ph-student-light': 61862,
    'ph-suitcase-light': 61863,
    'ph-suitcase-simple-light': 61864,
    'ph-sun-dim-light': 61865,
    'ph-sun-horizon-light': 61866,
    'ph-sun-light': 61867,
    'ph-sunglasses-light': 61868,
    'ph-swap-light': 61869,
    'ph-swatches-light': 61870,
    'ph-sword-light': 61871,
    'ph-syringe-light': 61872,
    'ph-t-shirt-light': 61873,
    'ph-table-light': 61874,
    'ph-tabs-light': 61875,
    'ph-tag-chevron-light': 61876,
    'ph-tag-light': 61877,
    'ph-tag-simple-light': 61878,
    'ph-target-light': 61879,
    'ph-taxi-light': 61880,
    'ph-telegram-logo-light': 61881,
    'ph-television-light': 61882,
    'ph-television-simple-light': 61883,
    'ph-tennis-ball-light': 61884,
    'ph-terminal-light': 61885,
    'ph-terminal-window-light': 61886,
    'ph-test-tube-light': 61887,
    'ph-text-aa-light': 61888,
    'ph-text-align-center-light': 61889,
    'ph-text-align-justify-light': 61890,
    'ph-text-align-left-light': 61891,
    'ph-text-align-right-light': 61892,
    'ph-text-bolder-light': 61893,
    'ph-text-h-five-light': 61894,
    'ph-text-h-four-light': 61895,
    'ph-text-h-light': 61896,
    'ph-text-h-one-light': 61897,
    'ph-text-h-six-light': 61898,
    'ph-text-h-three-light': 61899,
    'ph-text-h-two-light': 61900,
    'ph-text-indent-light': 61901,
    'ph-text-italic-light': 61902,
    'ph-text-outdent-light': 61903,
    'ph-text-strikethrough-light': 61904,
    'ph-text-t-light': 61905,
    'ph-text-underline-light': 61906,
    'ph-textbox-light': 61907,
    'ph-thermometer-cold-light': 61908,
    'ph-thermometer-hot-light': 61909,
    'ph-thermometer-light': 61910,
    'ph-thermometer-simple-light': 61911,
    'ph-thumbs-down-light': 61912,
    'ph-thumbs-up-light': 61913,
    'ph-ticket-light': 61914,
    'ph-tiktok-logo-light': 61915,
    'ph-timer-light': 61916,
    'ph-toggle-left-light': 61917,
    'ph-toggle-right-light': 61918,
    'ph-toilet-light': 61919,
    'ph-toilet-paper-light': 61920,
    'ph-tote-light': 61921,
    'ph-tote-simple-light': 61922,
    'ph-trademark-registered-light': 61923,
    'ph-traffic-cone-light': 61924,
    'ph-traffic-sign-light': 61925,
    'ph-traffic-signal-light': 61926,
    'ph-train-light': 61927,
    'ph-train-regional-light': 61928,
    'ph-train-simple-light': 61929,
    'ph-translate-light': 61930,
    'ph-trash-light': 61931,
    'ph-trash-simple-light': 61932,
    'ph-tray-light': 61933,
    'ph-tree-evergreen-light': 61934,
    'ph-tree-light': 61935,
    'ph-tree-structure-light': 61936,
    'ph-trend-down-light': 61937,
    'ph-trend-up-light': 61938,
    'ph-triangle-light': 61939,
    'ph-trophy-light': 61940,
    'ph-truck-light': 61941,
    'ph-twitch-logo-light': 61942,
    'ph-twitter-logo-light': 61943,
    'ph-umbrella-light': 61944,
    'ph-umbrella-simple-light': 61945,
    'ph-upload-light': 61946,
    'ph-upload-simple-light': 61947,
    'ph-user-circle-gear-light': 61948,
    'ph-user-circle-light': 61949,
    'ph-user-circle-minus-light': 61950,
    'ph-user-circle-plus-light': 61951,
    'ph-user-focus-light': 61952,
    'ph-user-gear-light': 61953,
    'ph-user-light': 61954,
    'ph-user-list-light': 61955,
    'ph-user-minus-light': 61956,
    'ph-user-plus-light': 61957,
    'ph-user-rectangle-light': 61958,
    'ph-user-square-light': 61959,
    'ph-user-switch-light': 61960,
    'ph-users-four-light': 61961,
    'ph-users-light': 61962,
    'ph-users-three-light': 61963,
    'ph-vault-light': 61964,
    'ph-vibrate-light': 61965,
    'ph-video-camera-light': 61966,
    'ph-video-camera-slash-light': 61967,
    'ph-vignette-light': 61968,
    'ph-voicemail-light': 61969,
    'ph-volleyball-light': 61970,
    'ph-wall-light': 61971,
    'ph-wallet-light': 61972,
    'ph-warning-circle-light': 61973,
    'ph-warning-light': 61974,
    'ph-warning-octagon-light': 61975,
    'ph-watch-light': 61976,
    'ph-wave-sawtooth-light': 61977,
    'ph-wave-sine-light': 61978,
    'ph-wave-square-light': 61979,
    'ph-wave-triangle-light': 61980,
    'ph-waves-light': 61981,
    'ph-webcam-light': 61982,
    'ph-whatsapp-logo-light': 61983,
    'ph-wheelchair-light': 61984,
    'ph-wifi-high-light': 61985,
    'ph-wifi-low-light': 61986,
    'ph-wifi-medium-light': 61987,
    'ph-wifi-none-light': 61988,
    'ph-wifi-slash-light': 61989,
    'ph-wifi-x-light': 61990,
    'ph-wind-light': 61991,
    'ph-windows-logo-light': 61992,
    'ph-wine-light': 61993,
    'ph-wrench-light': 61994,
    'ph-x-circle-light': 61995,
    'ph-x-light': 61996,
    'ph-x-square-light': 61997,
    'ph-yin-yang-light': 61998,
    'ph-youtube-logo-light': 61999,
    'ph-activity': 62000,
    'ph-address-book': 62001,
    'ph-airplane': 62002,
    'ph-airplane-in-flight': 62003,
    'ph-airplane-landing': 62004,
    'ph-airplane-takeoff': 62005,
    'ph-airplane-tilt': 62006,
    'ph-airplay': 62007,
    'ph-alarm': 62008,
    'ph-alien': 62009,
    'ph-align-bottom': 62010,
    'ph-align-bottom-simple': 62011,
    'ph-align-center-horizontal': 62012,
    'ph-align-center-horizontal-simple': 62013,
    'ph-align-center-vertical': 62014,
    'ph-align-center-vertical-simple': 62015,
    'ph-align-left': 62016,
    'ph-align-left-simple': 62017,
    'ph-align-right': 62018,
    'ph-align-right-simple': 62019,
    'ph-align-top': 62020,
    'ph-align-top-simple': 62021,
    'ph-anchor': 62022,
    'ph-anchor-simple': 62023,
    'ph-android-logo': 62024,
    'ph-angular-logo': 62025,
    'ph-aperture': 62026,
    'ph-app-store-logo': 62027,
    'ph-app-window': 62028,
    'ph-apple-logo': 62029,
    'ph-apple-podcasts-logo': 62030,
    'ph-archive': 62031,
    'ph-archive-box': 62032,
    'ph-archive-tray': 62033,
    'ph-armchair': 62034,
    'ph-arrow-arc-left': 62035,
    'ph-arrow-arc-right': 62036,
    'ph-arrow-bend-double-up-left': 62037,
    'ph-arrow-bend-double-up-right': 62038,
    'ph-arrow-bend-down-left': 62039,
    'ph-arrow-bend-down-right': 62040,
    'ph-arrow-bend-left-down': 62041,
    'ph-arrow-bend-left-up': 62042,
    'ph-arrow-bend-right-down': 62043,
    'ph-arrow-bend-right-up': 62044,
    'ph-arrow-bend-up-left': 62045,
    'ph-arrow-bend-up-right': 62046,
    'ph-arrow-circle-down': 62047,
    'ph-arrow-circle-down-left': 62048,
    'ph-arrow-circle-down-right': 62049,
    'ph-arrow-circle-left': 62050,
    'ph-arrow-circle-right': 62051,
    'ph-arrow-circle-up': 62052,
    'ph-arrow-circle-up-left': 62053,
    'ph-arrow-circle-up-right': 62054,
    'ph-arrow-clockwise': 62055,
    'ph-arrow-counter-clockwise': 62056,
    'ph-arrow-down': 62057,
    'ph-arrow-down-left': 62058,
    'ph-arrow-down-right': 62059,
    'ph-arrow-elbow-down-left': 62060,
    'ph-arrow-elbow-down-right': 62061,
    'ph-arrow-elbow-left': 62062,
    'ph-arrow-elbow-left-down': 62063,
    'ph-arrow-elbow-left-up': 62064,
    'ph-arrow-elbow-right': 62065,
    'ph-arrow-elbow-right-down': 62066,
    'ph-arrow-elbow-right-up': 62067,
    'ph-arrow-elbow-up-left': 62068,
    'ph-arrow-elbow-up-right': 62069,
    'ph-arrow-fat-down': 62070,
    'ph-arrow-fat-left': 62071,
    'ph-arrow-fat-line-down': 62072,
    'ph-arrow-fat-line-left': 62073,
    'ph-arrow-fat-line-right': 62074,
    'ph-arrow-fat-line-up': 62075,
    'ph-arrow-fat-lines-down': 62076,
    'ph-arrow-fat-lines-left': 62077,
    'ph-arrow-fat-lines-right': 62078,
    'ph-arrow-fat-lines-up': 62079,
    'ph-arrow-fat-right': 62080,
    'ph-arrow-fat-up': 62081,
    'ph-arrow-left': 62082,
    'ph-arrow-line-down': 62083,
    'ph-arrow-line-down-left': 62084,
    'ph-arrow-line-down-right': 62085,
    'ph-arrow-line-left': 62086,
    'ph-arrow-line-right': 62087,
    'ph-arrow-line-up': 62088,
    'ph-arrow-line-up-left': 62089,
    'ph-arrow-line-up-right': 62090,
    'ph-arrow-right': 62091,
    'ph-arrow-square-down': 62092,
    'ph-arrow-square-down-left': 62093,
    'ph-arrow-square-down-right': 62094,
    'ph-arrow-square-in': 62095,
    'ph-arrow-square-left': 62096,
    'ph-arrow-square-out': 62097,
    'ph-arrow-square-right': 62098,
    'ph-arrow-square-up': 62099,
    'ph-arrow-square-up-left': 62100,
    'ph-arrow-square-up-right': 62101,
    'ph-arrow-u-down-left': 62102,
    'ph-arrow-u-down-right': 62103,
    'ph-arrow-u-left-down': 62104,
    'ph-arrow-u-left-up': 62105,
    'ph-arrow-u-right-down': 62106,
    'ph-arrow-u-right-up': 62107,
    'ph-arrow-u-up-left': 62108,
    'ph-arrow-u-up-right': 62109,
    'ph-arrow-up': 62110,
    'ph-arrow-up-left': 62111,
    'ph-arrow-up-right': 62112,
    'ph-arrows-clockwise': 62113,
    'ph-arrows-counter-clockwise': 62114,
    'ph-arrows-down-up': 62115,
    'ph-arrows-horizontal': 62116,
    'ph-arrows-in': 62117,
    'ph-arrows-in-cardinal': 62118,
    'ph-arrows-in-line-horizontal': 62119,
    'ph-arrows-in-line-vertical': 62120,
    'ph-arrows-in-simple': 62121,
    'ph-arrows-left-right': 62122,
    'ph-arrows-out': 62123,
    'ph-arrows-out-cardinal': 62124,
    'ph-arrows-out-line-horizontal': 62125,
    'ph-arrows-out-line-vertical': 62126,
    'ph-arrows-out-simple': 62127,
    'ph-arrows-vertical': 62128,
    'ph-article': 62129,
    'ph-article-medium': 62130,
    'ph-article-ny-times': 62131,
    'ph-asterisk': 62132,
    'ph-asterisk-simple': 62133,
    'ph-at': 62134,
    'ph-atom': 62135,
    'ph-baby': 62136,
    'ph-backpack': 62137,
    'ph-backspace': 62138,
    'ph-bag': 62139,
    'ph-bag-simple': 62140,
    'ph-balloon': 62141,
    'ph-bandaids': 62142,
    'ph-bank': 62143,
    'ph-barbell': 62144,
    'ph-barcode': 62145,
    'ph-barricade': 62146,
    'ph-baseball': 62147,
    'ph-basketball': 62148,
    'ph-bathtub': 62149,
    'ph-battery-charging': 62150,
    'ph-battery-charging-vertical': 62151,
    'ph-battery-empty': 62152,
    'ph-battery-full': 62153,
    'ph-battery-high': 62154,
    'ph-battery-low': 62155,
    'ph-battery-medium': 62156,
    'ph-battery-plus': 62157,
    'ph-battery-warning': 62158,
    'ph-battery-warning-vertical': 62159,
    'ph-bed': 62160,
    'ph-beer-bottle': 62161,
    'ph-behance-logo': 62162,
    'ph-bell': 62163,
    'ph-bell-ringing': 62164,
    'ph-bell-simple': 62165,
    'ph-bell-simple-ringing': 62166,
    'ph-bell-simple-slash': 62167,
    'ph-bell-simple-z': 62168,
    'ph-bell-slash': 62169,
    'ph-bell-z': 62170,
    'ph-bezier-curve': 62171,
    'ph-bicycle': 62172,
    'ph-binoculars': 62173,
    'ph-bird': 62174,
    'ph-bluetooth': 62175,
    'ph-bluetooth-connected': 62176,
    'ph-bluetooth-slash': 62177,
    'ph-bluetooth-x': 62178,
    'ph-boat': 62179,
    'ph-book': 62180,
    'ph-book-bookmark': 62181,
    'ph-book-open': 62182,
    'ph-bookmark': 62183,
    'ph-bookmark-simple': 62184,
    'ph-bookmarks': 62185,
    'ph-bookmarks-simple': 62186,
    'ph-books': 62187,
    'ph-bounding-box': 62188,
    'ph-brackets-angle': 62189,
    'ph-brackets-curly': 62190,
    'ph-brackets-round': 62191,
    'ph-brackets-square': 62192,
    'ph-brain': 62193,
    'ph-brandy': 62194,
    'ph-briefcase': 62195,
    'ph-briefcase-metal': 62196,
    'ph-broadcast': 62197,
    'ph-browser': 62198,
    'ph-browsers': 62199,
    'ph-bug': 62200,
    'ph-bug-beetle': 62201,
    'ph-bug-droid': 62202,
    'ph-buildings': 62203,
    'ph-bus': 62204,
    'ph-butterfly': 62205,
    'ph-cactus': 62206,
    'ph-cake': 62207,
    'ph-calculator': 62208,
    'ph-calendar': 62209,
    'ph-calendar-blank': 62210,
    'ph-calendar-check': 62211,
    'ph-calendar-plus': 62212,
    'ph-calendar-x': 62213,
    'ph-camera': 62214,
    'ph-camera-rotate': 62215,
    'ph-camera-slash': 62216,
    'ph-campfire': 62217,
    'ph-car': 62218,
    'ph-car-simple': 62219,
    'ph-cardholder': 62220,
    'ph-cards': 62221,
    'ph-caret-circle-double-down': 62222,
    'ph-caret-circle-double-left': 62223,
    'ph-caret-circle-double-right': 62224,
    'ph-caret-circle-double-up': 62225,
    'ph-caret-circle-down': 62226,
    'ph-caret-circle-left': 62227,
    'ph-caret-circle-right': 62228,
    'ph-caret-circle-up': 62229,
    'ph-caret-double-down': 62230,
    'ph-caret-double-left': 62231,
    'ph-caret-double-right': 62232,
    'ph-caret-double-up': 62233,
    'ph-caret-down': 62234,
    'ph-caret-left': 62235,
    'ph-caret-right': 62236,
    'ph-caret-up': 62237,
    'ph-cat': 62238,
    'ph-cell-signal-full': 62239,
    'ph-cell-signal-high': 62240,
    'ph-cell-signal-low': 62241,
    'ph-cell-signal-medium': 62242,
    'ph-cell-signal-none': 62243,
    'ph-cell-signal-slash': 62244,
    'ph-cell-signal-x': 62245,
    'ph-chalkboard': 62246,
    'ph-chalkboard-simple': 62247,
    'ph-chalkboard-teacher': 62248,
    'ph-chart-bar': 62249,
    'ph-chart-bar-horizontal': 62250,
    'ph-chart-line': 62251,
    'ph-chart-line-up': 62252,
    'ph-chart-pie': 62253,
    'ph-chart-pie-slice': 62254,
    'ph-chat': 62255,
    'ph-chat-centered': 62256,
    'ph-chat-centered-dots': 62257,
    'ph-chat-centered-text': 62258,
    'ph-chat-circle': 62259,
    'ph-chat-circle-dots': 62260,
    'ph-chat-circle-text': 62261,
    'ph-chat-dots': 62262,
    'ph-chat-teardrop': 62263,
    'ph-chat-teardrop-dots': 62264,
    'ph-chat-teardrop-text': 62265,
    'ph-chat-text': 62266,
    'ph-chats': 62267,
    'ph-chats-circle': 62268,
    'ph-chats-teardrop': 62269,
    'ph-check': 62270,
    'ph-check-circle': 62271,
    'ph-check-square': 62272,
    'ph-check-square-offset': 62273,
    'ph-checks': 62274,
    'ph-circle': 62275,
    'ph-circle-dashed': 62276,
    'ph-circle-half': 62277,
    'ph-circle-half-tilt': 62278,
    'ph-circle-notch': 62279,
    'ph-circle-wavy': 62280,
    'ph-circle-wavy-check': 62281,
    'ph-circle-wavy-question': 62282,
    'ph-circle-wavy-warning': 62283,
    'ph-circles-four': 62284,
    'ph-circles-three': 62285,
    'ph-circles-three-plus': 62286,
    'ph-clipboard': 62287,
    'ph-clipboard-text': 62288,
    'ph-clock': 62289,
    'ph-clock-afternoon': 62290,
    'ph-clock-clockwise': 62291,
    'ph-clock-counter-clockwise': 62292,
    'ph-closed-captioning': 62293,
    'ph-cloud': 62294,
    'ph-cloud-arrow-down': 62295,
    'ph-cloud-arrow-up': 62296,
    'ph-cloud-check': 62297,
    'ph-cloud-fog': 62298,
    'ph-cloud-lightning': 62299,
    'ph-cloud-moon': 62300,
    'ph-cloud-rain': 62301,
    'ph-cloud-slash': 62302,
    'ph-cloud-snow': 62303,
    'ph-cloud-sun': 62304,
    'ph-club': 62305,
    'ph-coat-hanger': 62306,
    'ph-code': 62307,
    'ph-code-simple': 62308,
    'ph-codepen-logo': 62309,
    'ph-codesandbox-logo': 62310,
    'ph-coffee': 62311,
    'ph-coin': 62312,
    'ph-coin-vertical': 62313,
    'ph-coins': 62314,
    'ph-columns': 62315,
    'ph-command': 62316,
    'ph-compass': 62317,
    'ph-computer-tower': 62318,
    'ph-confetti': 62319,
    'ph-cookie': 62320,
    'ph-cooking-pot': 62321,
    'ph-copy': 62322,
    'ph-copy-simple': 62323,
    'ph-copyleft': 62324,
    'ph-copyright': 62325,
    'ph-corners-in': 62326,
    'ph-corners-out': 62327,
    'ph-cpu': 62328,
    'ph-credit-card': 62329,
    'ph-crop': 62330,
    'ph-crosshair': 62331,
    'ph-crosshair-simple': 62332,
    'ph-crown': 62333,
    'ph-crown-simple': 62334,
    'ph-cube': 62335,
    'ph-currency-btc': 62336,
    'ph-currency-circle-dollar': 62337,
    'ph-currency-cny': 62338,
    'ph-currency-dollar': 62339,
    'ph-currency-dollar-simple': 62340,
    'ph-currency-eth': 62341,
    'ph-currency-eur': 62342,
    'ph-currency-gbp': 62343,
    'ph-currency-inr': 62344,
    'ph-currency-jpy': 62345,
    'ph-currency-krw': 62346,
    'ph-currency-kzt': 62347,
    'ph-currency-ngn': 62348,
    'ph-currency-rub': 62349,
    'ph-cursor': 62350,
    'ph-cursor-text': 62351,
    'ph-cylinder': 62352,
    'ph-database': 62353,
    'ph-desktop': 62354,
    'ph-desktop-tower': 62355,
    'ph-detective': 62356,
    'ph-device-mobile': 62357,
    'ph-device-mobile-camera': 62358,
    'ph-device-mobile-speaker': 62359,
    'ph-device-tablet': 62360,
    'ph-device-tablet-camera': 62361,
    'ph-device-tablet-speaker': 62362,
    'ph-diamond': 62363,
    'ph-diamonds-four': 62364,
    'ph-dice-five': 62365,
    'ph-dice-four': 62366,
    'ph-dice-one': 62367,
    'ph-dice-six': 62368,
    'ph-dice-three': 62369,
    'ph-dice-two': 62370,
    'ph-disc': 62371,
    'ph-discord-logo': 62372,
    'ph-divide': 62373,
    'ph-dog': 62374,
    'ph-door': 62375,
    'ph-dots-nine': 62376,
    'ph-dots-six': 62377,
    'ph-dots-six-vertical': 62378,
    'ph-dots-three': 62379,
    'ph-dots-three-circle': 62380,
    'ph-dots-three-circle-vertical': 62381,
    'ph-dots-three-outline': 62382,
    'ph-dots-three-outline-vertical': 62383,
    'ph-dots-three-vertical': 62384,
    'ph-download': 62385,
    'ph-download-simple': 62386,
    'ph-dribbble-logo': 62387,
    'ph-drop': 62388,
    'ph-drop-half': 62389,
    'ph-drop-half-bottom': 62390,
    'ph-ear': 62391,
    'ph-ear-slash': 62392,
    'ph-egg': 62393,
    'ph-egg-crack': 62394,
    'ph-eject': 62395,
    'ph-eject-simple': 62396,
    'ph-envelope': 62397,
    'ph-envelope-open': 62398,
    'ph-envelope-simple': 62399,
    'ph-envelope-simple-open': 62400,
    'ph-equalizer': 62401,
    'ph-equals': 62402,
    'ph-eraser': 62403,
    'ph-exam': 62404,
    'ph-export': 62405,
    'ph-eye': 62406,
    'ph-eye-closed': 62407,
    'ph-eye-slash': 62408,
    'ph-eyedropper': 62409,
    'ph-eyedropper-sample': 62410,
    'ph-eyeglasses': 62411,
    'ph-face-mask': 62412,
    'ph-facebook-logo': 62413,
    'ph-factory': 62414,
    'ph-faders': 62415,
    'ph-faders-horizontal': 62416,
    'ph-fast-forward': 62417,
    'ph-fast-forward-circle': 62418,
    'ph-figma-logo': 62419,
    'ph-file': 62420,
    'ph-file-arrow-down': 62421,
    'ph-file-arrow-up': 62422,
    'ph-file-audio': 62423,
    'ph-file-cloud': 62424,
    'ph-file-code': 62425,
    'ph-file-css': 62426,
    'ph-file-csv': 62427,
    'ph-file-doc': 62428,
    'ph-file-dotted': 62429,
    'ph-file-html': 62430,
    'ph-file-image': 62431,
    'ph-file-jpg': 62432,
    'ph-file-js': 62433,
    'ph-file-jsx': 62434,
    'ph-file-lock': 62435,
    'ph-file-minus': 62436,
    'ph-file-pdf': 62437,
    'ph-file-plus': 62438,
    'ph-file-png': 62439,
    'ph-file-ppt': 62440,
    'ph-file-rs': 62441,
    'ph-file-search': 62442,
    'ph-file-text': 62443,
    'ph-file-ts': 62444,
    'ph-file-tsx': 62445,
    'ph-file-video': 62446,
    'ph-file-vue': 62447,
    'ph-file-x': 62448,
    'ph-file-xls': 62449,
    'ph-file-zip': 62450,
    'ph-files': 62451,
    'ph-film-script': 62452,
    'ph-film-slate': 62453,
    'ph-film-strip': 62454,
    'ph-fingerprint': 62455,
    'ph-fingerprint-simple': 62456,
    'ph-finn-the-human': 62457,
    'ph-fire': 62458,
    'ph-fire-simple': 62459,
    'ph-first-aid': 62460,
    'ph-first-aid-kit': 62461,
    'ph-fish': 62462,
    'ph-fish-simple': 62463,
    'ph-flag': 62464,
    'ph-flag-banner': 62465,
    'ph-flag-checkered': 62466,
    'ph-flame': 62467,
    'ph-flashlight': 62468,
    'ph-flask': 62469,
    'ph-floppy-disk': 62470,
    'ph-floppy-disk-back': 62471,
    'ph-flow-arrow': 62472,
    'ph-flower': 62473,
    'ph-flower-lotus': 62474,
    'ph-flying-saucer': 62475,
    'ph-folder': 62476,
    'ph-folder-dotted': 62477,
    'ph-folder-lock': 62478,
    'ph-folder-minus': 62479,
    'ph-folder-notch': 62480,
    'ph-folder-notch-minus': 62481,
    'ph-folder-notch-open': 62482,
    'ph-folder-notch-plus': 62483,
    'ph-folder-open': 62484,
    'ph-folder-plus': 62485,
    'ph-folder-simple': 62486,
    'ph-folder-simple-dotted': 62487,
    'ph-folder-simple-lock': 62488,
    'ph-folder-simple-minus': 62489,
    'ph-folder-simple-plus': 62490,
    'ph-folder-simple-star': 62491,
    'ph-folder-simple-user': 62492,
    'ph-folder-star': 62493,
    'ph-folder-user': 62494,
    'ph-folders': 62495,
    'ph-football': 62496,
    'ph-fork-knife': 62497,
    'ph-frame-corners': 62498,
    'ph-framer-logo': 62499,
    'ph-function': 62500,
    'ph-funnel': 62501,
    'ph-funnel-simple': 62502,
    'ph-game-controller': 62503,
    'ph-gas-pump': 62504,
    'ph-gauge': 62505,
    'ph-gear': 62506,
    'ph-gear-six': 62507,
    'ph-gender-female': 62508,
    'ph-gender-intersex': 62509,
    'ph-gender-male': 62510,
    'ph-gender-neuter': 62511,
    'ph-gender-nonbinary': 62512,
    'ph-gender-transgender': 62513,
    'ph-ghost': 62514,
    'ph-gif': 62515,
    'ph-gift': 62516,
    'ph-git-branch': 62517,
    'ph-git-commit': 62518,
    'ph-git-diff': 62519,
    'ph-git-fork': 62520,
    'ph-git-merge': 62521,
    'ph-git-pull-request': 62522,
    'ph-github-logo': 62523,
    'ph-gitlab-logo': 62524,
    'ph-gitlab-logo-simple': 62525,
    'ph-globe': 62526,
    'ph-globe-hemisphere-east': 62527,
    'ph-globe-hemisphere-west': 62528,
    'ph-globe-simple': 62529,
    'ph-globe-stand': 62530,
    'ph-google-chrome-logo': 62531,
    'ph-google-logo': 62532,
    'ph-google-photos-logo': 62533,
    'ph-google-play-logo': 62534,
    'ph-google-podcasts-logo': 62535,
    'ph-gradient': 62536,
    'ph-graduation-cap': 62537,
    'ph-graph': 62538,
    'ph-grid-four': 62539,
    'ph-hamburger': 62540,
    'ph-hand': 62541,
    'ph-hand-eye': 62542,
    'ph-hand-fist': 62543,
    'ph-hand-grabbing': 62544,
    'ph-hand-palm': 62545,
    'ph-hand-pointing': 62546,
    'ph-hand-soap': 62547,
    'ph-hand-waving': 62548,
    'ph-handbag': 62549,
    'ph-handbag-simple': 62550,
    'ph-hands-clapping': 62551,
    'ph-handshake': 62552,
    'ph-hard-drive': 62553,
    'ph-hard-drives': 62554,
    'ph-hash': 62555,
    'ph-hash-straight': 62556,
    'ph-headlights': 62557,
    'ph-headphones': 62558,
    'ph-headset': 62559,
    'ph-heart': 62560,
    'ph-heart-break': 62561,
    'ph-heart-straight': 62562,
    'ph-heart-straight-break': 62563,
    'ph-heartbeat': 62564,
    'ph-hexagon': 62565,
    'ph-highlighter-circle': 62566,
    'ph-horse': 62567,
    'ph-hourglass': 62568,
    'ph-hourglass-high': 62569,
    'ph-hourglass-low': 62570,
    'ph-hourglass-medium': 62571,
    'ph-hourglass-simple': 62572,
    'ph-hourglass-simple-high': 62573,
    'ph-hourglass-simple-low': 62574,
    'ph-hourglass-simple-medium': 62575,
    'ph-house': 62576,
    'ph-house-line': 62577,
    'ph-house-simple': 62578,
    'ph-identification-badge': 62579,
    'ph-identification-card': 62580,
    'ph-image': 62581,
    'ph-image-square': 62582,
    'ph-infinity': 62583,
    'ph-info': 62584,
    'ph-instagram-logo': 62585,
    'ph-intersect': 62586,
    'ph-jeep': 62587,
    'ph-kanban': 62588,
    'ph-key': 62589,
    'ph-key-return': 62590,
    'ph-keyboard': 62591,
    'ph-keyhole': 62592,
    'ph-knife': 62593,
    'ph-ladder': 62594,
    'ph-ladder-simple': 62595,
    'ph-lamp': 62596,
    'ph-laptop': 62597,
    'ph-layout': 62598,
    'ph-leaf': 62599,
    'ph-lifebuoy': 62600,
    'ph-lightbulb': 62601,
    'ph-lightbulb-filament': 62602,
    'ph-lightning': 62603,
    'ph-lightning-slash': 62604,
    'ph-line-segment': 62605,
    'ph-line-segments': 62606,
    'ph-link': 62607,
    'ph-link-break': 62608,
    'ph-link-simple': 62609,
    'ph-link-simple-break': 62610,
    'ph-link-simple-horizontal': 62611,
    'ph-link-simple-horizontal-break': 62612,
    'ph-linkedin-logo': 62613,
    'ph-linux-logo': 62614,
    'ph-list': 62615,
    'ph-list-bullets': 62616,
    'ph-list-checks': 62617,
    'ph-list-dashes': 62618,
    'ph-list-numbers': 62619,
    'ph-list-plus': 62620,
    'ph-lock': 62621,
    'ph-lock-key': 62622,
    'ph-lock-key-open': 62623,
    'ph-lock-laminated': 62624,
    'ph-lock-laminated-open': 62625,
    'ph-lock-open': 62626,
    'ph-lock-simple': 62627,
    'ph-lock-simple-open': 62628,
    'ph-magic-wand': 62629,
    'ph-magnet': 62630,
    'ph-magnet-straight': 62631,
    'ph-magnifying-glass': 62632,
    'ph-magnifying-glass-minus': 62633,
    'ph-magnifying-glass-plus': 62634,
    'ph-map-pin': 62635,
    'ph-map-pin-line': 62636,
    'ph-map-trifold': 62637,
    'ph-marker-circle': 62638,
    'ph-martini': 62639,
    'ph-mask-happy': 62640,
    'ph-mask-sad': 62641,
    'ph-math-operations': 62642,
    'ph-medal': 62643,
    'ph-medium-logo': 62644,
    'ph-megaphone': 62645,
    'ph-megaphone-simple': 62646,
    'ph-messenger-logo': 62647,
    'ph-microphone': 62648,
    'ph-microphone-slash': 62649,
    'ph-microphone-stage': 62650,
    'ph-microsoft-excel-logo': 62651,
    'ph-microsoft-powerpoint-logo': 62652,
    'ph-microsoft-teams-logo': 62653,
    'ph-microsoft-word-logo': 62654,
    'ph-minus': 62655,
    'ph-minus-circle': 62656,
    'ph-money': 62657,
    'ph-monitor': 62658,
    'ph-monitor-play': 62659,
    'ph-moon': 62660,
    'ph-moon-stars': 62661,
    'ph-mountains': 62662,
    'ph-mouse': 62663,
    'ph-mouse-simple': 62664,
    'ph-music-note': 62665,
    'ph-music-note-simple': 62666,
    'ph-music-notes': 62667,
    'ph-music-notes-plus': 62668,
    'ph-music-notes-simple': 62669,
    'ph-navigation-arrow': 62670,
    'ph-needle': 62671,
    'ph-newspaper': 62672,
    'ph-newspaper-clipping': 62673,
    'ph-note': 62674,
    'ph-note-blank': 62675,
    'ph-note-pencil': 62676,
    'ph-notebook': 62677,
    'ph-notepad': 62678,
    'ph-notification': 62679,
    'ph-number-circle-eight': 62680,
    'ph-number-circle-five': 62681,
    'ph-number-circle-four': 62682,
    'ph-number-circle-nine': 62683,
    'ph-number-circle-one': 62684,
    'ph-number-circle-seven': 62685,
    'ph-number-circle-six': 62686,
    'ph-number-circle-three': 62687,
    'ph-number-circle-two': 62688,
    'ph-number-circle-zero': 62689,
    'ph-number-eight': 62690,
    'ph-number-five': 62691,
    'ph-number-four': 62692,
    'ph-number-nine': 62693,
    'ph-number-one': 62694,
    'ph-number-seven': 62695,
    'ph-number-six': 62696,
    'ph-number-square-eight': 62697,
    'ph-number-square-five': 62698,
    'ph-number-square-four': 62699,
    'ph-number-square-nine': 62700,
    'ph-number-square-one': 62701,
    'ph-number-square-seven': 62702,
    'ph-number-square-six': 62703,
    'ph-number-square-three': 62704,
    'ph-number-square-two': 62705,
    'ph-number-square-zero': 62706,
    'ph-number-three': 62707,
    'ph-number-two': 62708,
    'ph-number-zero': 62709,
    'ph-nut': 62710,
    'ph-ny-times-logo': 62711,
    'ph-octagon': 62712,
    'ph-option': 62713,
    'ph-package': 62714,
    'ph-paint-brush': 62715,
    'ph-paint-brush-broad': 62716,
    'ph-paint-brush-household': 62717,
    'ph-paint-bucket': 62718,
    'ph-paint-roller': 62719,
    'ph-palette': 62720,
    'ph-paper-plane': 62721,
    'ph-paper-plane-right': 62722,
    'ph-paper-plane-tilt': 62723,
    'ph-paperclip': 62724,
    'ph-paperclip-horizontal': 62725,
    'ph-parachute': 62726,
    'ph-password': 62727,
    'ph-path': 62728,
    'ph-pause': 62729,
    'ph-pause-circle': 62730,
    'ph-paw-print': 62731,
    'ph-peace': 62732,
    'ph-pen': 62733,
    'ph-pen-nib': 62734,
    'ph-pen-nib-straight': 62735,
    'ph-pencil': 62736,
    'ph-pencil-circle': 62737,
    'ph-pencil-line': 62738,
    'ph-pencil-simple': 62739,
    'ph-pencil-simple-line': 62740,
    'ph-percent': 62741,
    'ph-person': 62742,
    'ph-person-simple': 62743,
    'ph-person-simple-run': 62744,
    'ph-person-simple-walk': 62745,
    'ph-perspective': 62746,
    'ph-phone': 62747,
    'ph-phone-call': 62748,
    'ph-phone-disconnect': 62749,
    'ph-phone-incoming': 62750,
    'ph-phone-outgoing': 62751,
    'ph-phone-slash': 62752,
    'ph-phone-x': 62753,
    'ph-phosphor-logo': 62754,
    'ph-piano-keys': 62755,
    'ph-picture-in-picture': 62756,
    'ph-pill': 62757,
    'ph-pinterest-logo': 62758,
    'ph-pinwheel': 62759,
    'ph-pizza': 62760,
    'ph-placeholder': 62761,
    'ph-planet': 62762,
    'ph-play': 62763,
    'ph-play-circle': 62764,
    'ph-playlist': 62765,
    'ph-plug': 62766,
    'ph-plugs': 62767,
    'ph-plugs-connected': 62768,
    'ph-plus': 62769,
    'ph-plus-circle': 62770,
    'ph-plus-minus': 62771,
    'ph-poker-chip': 62772,
    'ph-police-car': 62773,
    'ph-polygon': 62774,
    'ph-popcorn': 62775,
    'ph-power': 62776,
    'ph-prescription': 62777,
    'ph-presentation': 62778,
    'ph-presentation-chart': 62779,
    'ph-printer': 62780,
    'ph-prohibit': 62781,
    'ph-prohibit-inset': 62782,
    'ph-projector-screen': 62783,
    'ph-projector-screen-chart': 62784,
    'ph-push-pin': 62785,
    'ph-push-pin-simple': 62786,
    'ph-push-pin-simple-slash': 62787,
    'ph-push-pin-slash': 62788,
    'ph-puzzle-piece': 62789,
    'ph-qr-code': 62790,
    'ph-question': 62791,
    'ph-queue': 62792,
    'ph-quotes': 62793,
    'ph-radical': 62794,
    'ph-radio': 62795,
    'ph-radio-button': 62796,
    'ph-rainbow': 62797,
    'ph-rainbow-cloud': 62798,
    'ph-receipt': 62799,
    'ph-record': 62800,
    'ph-rectangle': 62801,
    'ph-recycle': 62802,
    'ph-reddit-logo': 62803,
    'ph-repeat': 62804,
    'ph-repeat-once': 62805,
    'ph-rewind': 62806,
    'ph-rewind-circle': 62807,
    'ph-robot': 62808,
    'ph-rocket': 62809,
    'ph-rocket-launch': 62810,
    'ph-rows': 62811,
    'ph-rss': 62812,
    'ph-rss-simple': 62813,
    'ph-rug': 62814,
    'ph-ruler': 62815,
    'ph-scales': 62816,
    'ph-scan': 62817,
    'ph-scissors': 62818,
    'ph-screencast': 62819,
    'ph-scribble-loop': 62820,
    'ph-scroll': 62821,
    'ph-selection': 62822,
    'ph-selection-all': 62823,
    'ph-selection-background': 62824,
    'ph-selection-foreground': 62825,
    'ph-selection-inverse': 62826,
    'ph-selection-plus': 62827,
    'ph-selection-slash': 62828,
    'ph-share': 62829,
    'ph-share-network': 62830,
    'ph-shield': 62831,
    'ph-shield-check': 62832,
    'ph-shield-checkered': 62833,
    'ph-shield-chevron': 62834,
    'ph-shield-plus': 62835,
    'ph-shield-slash': 62836,
    'ph-shield-star': 62837,
    'ph-shield-warning': 62838,
    'ph-shopping-bag': 62839,
    'ph-shopping-bag-open': 62840,
    'ph-shopping-cart': 62841,
    'ph-shopping-cart-simple': 62842,
    'ph-shower': 62843,
    'ph-shuffle': 62844,
    'ph-shuffle-angular': 62845,
    'ph-shuffle-simple': 62846,
    'ph-sidebar': 62847,
    'ph-sidebar-simple': 62848,
    'ph-sign-in': 62849,
    'ph-sign-out': 62850,
    'ph-signpost': 62851,
    'ph-sim-card': 62852,
    'ph-sketch-logo': 62853,
    'ph-skip-back': 62854,
    'ph-skip-back-circle': 62855,
    'ph-skip-forward': 62856,
    'ph-skip-forward-circle': 62857,
    'ph-skull': 62858,
    'ph-slack-logo': 62859,
    'ph-sliders': 62860,
    'ph-sliders-horizontal': 62861,
    'ph-smiley': 62862,
    'ph-smiley-blank': 62863,
    'ph-smiley-meh': 62864,
    'ph-smiley-nervous': 62865,
    'ph-smiley-sad': 62866,
    'ph-smiley-sticker': 62867,
    'ph-smiley-wink': 62868,
    'ph-smiley-x-eyes': 62869,
    'ph-snapchat-logo': 62870,
    'ph-snowflake': 62871,
    'ph-soccer-ball': 62872,
    'ph-sort-ascending': 62873,
    'ph-sort-descending': 62874,
    'ph-spade': 62875,
    'ph-sparkle': 62876,
    'ph-speaker-high': 62877,
    'ph-speaker-low': 62878,
    'ph-speaker-none': 62879,
    'ph-speaker-simple-high': 62880,
    'ph-speaker-simple-low': 62881,
    'ph-speaker-simple-none': 62882,
    'ph-speaker-simple-slash': 62883,
    'ph-speaker-simple-x': 62884,
    'ph-speaker-slash': 62885,
    'ph-speaker-x': 62886,
    'ph-spinner': 62887,
    'ph-spinner-gap': 62888,
    'ph-spiral': 62889,
    'ph-spotify-logo': 62890,
    'ph-square': 62891,
    'ph-square-half': 62892,
    'ph-square-half-bottom': 62893,
    'ph-square-logo': 62894,
    'ph-squares-four': 62895,
    'ph-stack': 62896,
    'ph-stack-overflow-logo': 62897,
    'ph-stack-simple': 62898,
    'ph-stamp': 62899,
    'ph-star': 62900,
    'ph-star-four': 62901,
    'ph-star-half': 62902,
    'ph-sticker': 62903,
    'ph-stop': 62904,
    'ph-stop-circle': 62905,
    'ph-storefront': 62906,
    'ph-strategy': 62907,
    'ph-stripe-logo': 62908,
    'ph-student': 62909,
    'ph-suitcase': 62910,
    'ph-suitcase-simple': 62911,
    'ph-sun': 62912,
    'ph-sun-dim': 62913,
    'ph-sun-horizon': 62914,
    'ph-sunglasses': 62915,
    'ph-swap': 62916,
    'ph-swatches': 62917,
    'ph-sword': 62918,
    'ph-syringe': 62919,
    'ph-t-shirt': 62920,
    'ph-table': 62921,
    'ph-tabs': 62922,
    'ph-tag': 62923,
    'ph-tag-chevron': 62924,
    'ph-tag-simple': 62925,
    'ph-target': 62926,
    'ph-taxi': 62927,
    'ph-telegram-logo': 62928,
    'ph-television': 62929,
    'ph-television-simple': 62930,
    'ph-tennis-ball': 62931,
    'ph-terminal': 62932,
    'ph-terminal-window': 62933,
    'ph-test-tube': 62934,
    'ph-text-aa': 62935,
    'ph-text-align-center': 62936,
    'ph-text-align-justify': 62937,
    'ph-text-align-left': 62938,
    'ph-text-align-right': 62939,
    'ph-text-bolder': 62940,
    'ph-text-h': 62941,
    'ph-text-h-five': 62942,
    'ph-text-h-four': 62943,
    'ph-text-h-one': 62944,
    'ph-text-h-six': 62945,
    'ph-text-h-three': 62946,
    'ph-text-h-two': 62947,
    'ph-text-indent': 62948,
    'ph-text-italic': 62949,
    'ph-text-outdent': 62950,
    'ph-text-strikethrough': 62951,
    'ph-text-t': 62952,
    'ph-text-underline': 62953,
    'ph-textbox': 62954,
    'ph-thermometer': 62955,
    'ph-thermometer-cold': 62956,
    'ph-thermometer-hot': 62957,
    'ph-thermometer-simple': 62958,
    'ph-thumbs-down': 62959,
    'ph-thumbs-up': 62960,
    'ph-ticket': 62961,
    'ph-tiktok-logo': 62962,
    'ph-timer': 62963,
    'ph-toggle-left': 62964,
    'ph-toggle-right': 62965,
    'ph-toilet': 62966,
    'ph-toilet-paper': 62967,
    'ph-tote': 62968,
    'ph-tote-simple': 62969,
    'ph-trademark-registered': 62970,
    'ph-traffic-cone': 62971,
    'ph-traffic-sign': 62972,
    'ph-traffic-signal': 62973,
    'ph-train': 62974,
    'ph-train-regional': 62975,
    'ph-train-simple': 62976,
    'ph-translate': 62977,
    'ph-trash': 62978,
    'ph-trash-simple': 62979,
    'ph-tray': 62980,
    'ph-tree': 62981,
    'ph-tree-evergreen': 62982,
    'ph-tree-structure': 62983,
    'ph-trend-down': 62984,
    'ph-trend-up': 62985,
    'ph-triangle': 62986,
    'ph-trophy': 62987,
    'ph-truck': 62988,
    'ph-twitch-logo': 62989,
    'ph-twitter-logo': 62990,
    'ph-umbrella': 62991,
    'ph-umbrella-simple': 62992,
    'ph-upload': 62993,
    'ph-upload-simple': 62994,
    'ph-user': 62995,
    'ph-user-circle': 62996,
    'ph-user-circle-gear': 62997,
    'ph-user-circle-minus': 62998,
    'ph-user-circle-plus': 62999,
    'ph-user-focus': 63000,
    'ph-user-gear': 63001,
    'ph-user-list': 63002,
    'ph-user-minus': 63003,
    'ph-user-plus': 63004,
    'ph-user-rectangle': 63005,
    'ph-user-square': 63006,
    'ph-user-switch': 63007,
    'ph-users': 63008,
    'ph-users-four': 63009,
    'ph-users-three': 63010,
    'ph-vault': 63011,
    'ph-vibrate': 63012,
    'ph-video-camera': 63013,
    'ph-video-camera-slash': 63014,
    'ph-vignette': 63015,
    'ph-voicemail': 63016,
    'ph-volleyball': 63017,
    'ph-wall': 63018,
    'ph-wallet': 63019,
    'ph-warning': 63020,
    'ph-warning-circle': 63021,
    'ph-warning-octagon': 63022,
    'ph-watch': 63023,
    'ph-wave-sawtooth': 63024,
    'ph-wave-sine': 63025,
    'ph-wave-square': 63026,
    'ph-wave-triangle': 63027,
    'ph-waves': 63028,
    'ph-webcam': 63029,
    'ph-whatsapp-logo': 63030,
    'ph-wheelchair': 63031,
    'ph-wifi-high': 63032,
    'ph-wifi-low': 63033,
    'ph-wifi-medium': 63034,
    'ph-wifi-none': 63035,
    'ph-wifi-slash': 63036,
    'ph-wifi-x': 63037,
    'ph-wind': 63038,
    'ph-windows-logo': 63039,
    'ph-wine': 63040,
    'ph-wrench': 63041,
    'ph-x': 63042,
    'ph-x-circle': 63043,
    'ph-x-square': 63044,
    'ph-yin-yang': 63045,
    'ph-youtube-logo': 63046,
    'ph-activity-bold': 63047,
    'ph-address-book-bold': 63048,
    'ph-airplane-bold': 63049,
    'ph-airplane-in-flight-bold': 63050,
    'ph-airplane-landing-bold': 63051,
    'ph-airplane-takeoff-bold': 63052,
    'ph-airplane-tilt-bold': 63053,
    'ph-airplay-bold': 63054,
    'ph-alarm-bold': 63055,
    'ph-alien-bold': 63056,
    'ph-align-bottom-bold': 63057,
    'ph-align-bottom-simple-bold': 63058,
    'ph-align-center-horizontal-bold': 63059,
    'ph-align-center-horizontal-simple-bold': 63060,
    'ph-align-center-vertical-bold': 63061,
    'ph-align-center-vertical-simple-bold': 63062,
    'ph-align-left-bold': 63063,
    'ph-align-left-simple-bold': 63064,
    'ph-align-right-bold': 63065,
    'ph-align-right-simple-bold': 63066,
    'ph-align-top-bold': 63067,
    'ph-align-top-simple-bold': 63068,
    'ph-anchor-bold': 63069,
    'ph-anchor-simple-bold': 63070,
    'ph-android-logo-bold': 63071,
    'ph-angular-logo-bold': 63072,
    'ph-aperture-bold': 63073,
    'ph-app-store-logo-bold': 63074,
    'ph-app-window-bold': 63075,
    'ph-apple-logo-bold': 63076,
    'ph-apple-podcasts-logo-bold': 63077,
    'ph-archive-bold': 63078,
    'ph-archive-box-bold': 63079,
    'ph-archive-tray-bold': 63080,
    'ph-armchair-bold': 63081,
    'ph-arrow-arc-left-bold': 63082,
    'ph-arrow-arc-right-bold': 63083,
    'ph-arrow-bend-double-up-left-bold': 63084,
    'ph-arrow-bend-double-up-right-bold': 63085,
    'ph-arrow-bend-down-left-bold': 63086,
    'ph-arrow-bend-down-right-bold': 63087,
    'ph-arrow-bend-left-down-bold': 63088,
    'ph-arrow-bend-left-up-bold': 63089,
    'ph-arrow-bend-right-down-bold': 63090,
    'ph-arrow-bend-right-up-bold': 63091,
    'ph-arrow-bend-up-left-bold': 63092,
    'ph-arrow-bend-up-right-bold': 63093,
    'ph-arrow-circle-down-bold': 63094,
    'ph-arrow-circle-down-left-bold': 63095,
    'ph-arrow-circle-down-right-bold': 63096,
    'ph-arrow-circle-left-bold': 63097,
    'ph-arrow-circle-right-bold': 63098,
    'ph-arrow-circle-up-bold': 63099,
    'ph-arrow-circle-up-left-bold': 63100,
    'ph-arrow-circle-up-right-bold': 63101,
    'ph-arrow-clockwise-bold': 63102,
    'ph-arrow-counter-clockwise-bold': 63103,
    'ph-arrow-down-bold': 63104,
    'ph-arrow-down-left-bold': 63105,
    'ph-arrow-down-right-bold': 63106,
    'ph-arrow-elbow-down-left-bold': 63107,
    'ph-arrow-elbow-down-right-bold': 63108,
    'ph-arrow-elbow-left-bold': 63109,
    'ph-arrow-elbow-left-down-bold': 63110,
    'ph-arrow-elbow-left-up-bold': 63111,
    'ph-arrow-elbow-right-bold': 63112,
    'ph-arrow-elbow-right-down-bold': 63113,
    'ph-arrow-elbow-right-up-bold': 63114,
    'ph-arrow-elbow-up-left-bold': 63115,
    'ph-arrow-elbow-up-right-bold': 63116,
    'ph-arrow-fat-down-bold': 63117,
    'ph-arrow-fat-left-bold': 63118,
    'ph-arrow-fat-line-down-bold': 63119,
    'ph-arrow-fat-line-left-bold': 63120,
    'ph-arrow-fat-line-right-bold': 63121,
    'ph-arrow-fat-line-up-bold': 63122,
    'ph-arrow-fat-lines-down-bold': 63123,
    'ph-arrow-fat-lines-left-bold': 63124,
    'ph-arrow-fat-lines-right-bold': 63125,
    'ph-arrow-fat-lines-up-bold': 63126,
    'ph-arrow-fat-right-bold': 63127,
    'ph-arrow-fat-up-bold': 63128,
    'ph-arrow-left-bold': 63129,
    'ph-arrow-line-down-bold': 63130,
    'ph-arrow-line-down-left-bold': 63131,
    'ph-arrow-line-down-right-bold': 63132,
    'ph-arrow-line-left-bold': 63133,
    'ph-arrow-line-right-bold': 63134,
    'ph-arrow-line-up-bold': 63135,
    'ph-arrow-line-up-left-bold': 63136,
    'ph-arrow-line-up-right-bold': 63137,
    'ph-arrow-right-bold': 63138,
    'ph-arrow-square-down-bold': 63139,
    'ph-arrow-square-down-left-bold': 63140,
    'ph-arrow-square-down-right-bold': 63141,
    'ph-arrow-square-in-bold': 63142,
    'ph-arrow-square-left-bold': 63143,
    'ph-arrow-square-out-bold': 63144,
    'ph-arrow-square-right-bold': 63145,
    'ph-arrow-square-up-bold': 63146,
    'ph-arrow-square-up-left-bold': 63147,
    'ph-arrow-square-up-right-bold': 63148,
    'ph-arrow-u-down-left-bold': 63149,
    'ph-arrow-u-down-right-bold': 63150,
    'ph-arrow-u-left-down-bold': 63151,
    'ph-arrow-u-left-up-bold': 63152,
    'ph-arrow-u-right-down-bold': 63153,
    'ph-arrow-u-right-up-bold': 63154,
    'ph-arrow-u-up-left-bold': 63155,
    'ph-arrow-u-up-right-bold': 63156,
    'ph-arrow-up-bold': 63157,
    'ph-arrow-up-left-bold': 63158,
    'ph-arrow-up-right-bold': 63159,
    'ph-arrows-clockwise-bold': 63160,
    'ph-arrows-counter-clockwise-bold': 63161,
    'ph-arrows-down-up-bold': 63162,
    'ph-arrows-horizontal-bold': 63163,
    'ph-arrows-in-bold': 63164,
    'ph-arrows-in-cardinal-bold': 63165,
    'ph-arrows-in-line-horizontal-bold': 63166,
    'ph-arrows-in-line-vertical-bold': 63167,
    'ph-arrows-in-simple-bold': 63168,
    'ph-arrows-left-right-bold': 63169,
    'ph-arrows-out-bold': 63170,
    'ph-arrows-out-cardinal-bold': 63171,
    'ph-arrows-out-line-horizontal-bold': 63172,
    'ph-arrows-out-line-vertical-bold': 63173,
    'ph-arrows-out-simple-bold': 63174,
    'ph-arrows-vertical-bold': 63175,
    'ph-article-bold': 63176,
    'ph-article-medium-bold': 63177,
    'ph-article-ny-times-bold': 63178,
    'ph-asterisk-bold': 63179,
    'ph-asterisk-simple-bold': 63180,
    'ph-at-bold': 63181,
    'ph-atom-bold': 63182,
    'ph-baby-bold': 63183,
    'ph-backpack-bold': 63184,
    'ph-backspace-bold': 63185,
    'ph-bag-bold': 63186,
    'ph-bag-simple-bold': 63187,
    'ph-balloon-bold': 63188,
    'ph-bandaids-bold': 63189,
    'ph-bank-bold': 63190,
    'ph-barbell-bold': 63191,
    'ph-barcode-bold': 63192,
    'ph-barricade-bold': 63193,
    'ph-baseball-bold': 63194,
    'ph-basketball-bold': 63195,
    'ph-bathtub-bold': 63196,
    'ph-battery-charging-bold': 63197,
    'ph-battery-charging-vertical-bold': 63198,
    'ph-battery-empty-bold': 63199,
    'ph-battery-full-bold': 63200,
    'ph-battery-high-bold': 63201,
    'ph-battery-low-bold': 63202,
    'ph-battery-medium-bold': 63203,
    'ph-battery-plus-bold': 63204,
    'ph-battery-warning-bold': 63205,
    'ph-battery-warning-vertical-bold': 63206,
    'ph-bed-bold': 63207,
    'ph-beer-bottle-bold': 63208,
    'ph-behance-logo-bold': 63209,
    'ph-bell-bold': 63210,
    'ph-bell-ringing-bold': 63211,
    'ph-bell-simple-bold': 63212,
    'ph-bell-simple-ringing-bold': 63213,
    'ph-bell-simple-slash-bold': 63214,
    'ph-bell-simple-z-bold': 63215,
    'ph-bell-slash-bold': 63216,
    'ph-bell-z-bold': 63217,
    'ph-bezier-curve-bold': 63218,
    'ph-bicycle-bold': 63219,
    'ph-binoculars-bold': 63220,
    'ph-bird-bold': 63221,
    'ph-bluetooth-bold': 63222,
    'ph-bluetooth-connected-bold': 63223,
    'ph-bluetooth-slash-bold': 63224,
    'ph-bluetooth-x-bold': 63225,
    'ph-boat-bold': 63226,
    'ph-book-bold': 63227,
    'ph-book-bookmark-bold': 63228,
    'ph-book-open-bold': 63229,
    'ph-bookmark-bold': 63230,
    'ph-bookmark-simple-bold': 63231,
    'ph-bookmarks-bold': 63232,
    'ph-bookmarks-simple-bold': 63233,
    'ph-books-bold': 63234,
    'ph-bounding-box-bold': 63235,
    'ph-brackets-angle-bold': 63236,
    'ph-brackets-curly-bold': 63237,
    'ph-brackets-round-bold': 63238,
    'ph-brackets-square-bold': 63239,
    'ph-brain-bold': 63240,
    'ph-brandy-bold': 63241,
    'ph-briefcase-bold': 63242,
    'ph-briefcase-metal-bold': 63243,
    'ph-broadcast-bold': 63244,
    'ph-browser-bold': 63245,
    'ph-browsers-bold': 63246,
    'ph-bug-beetle-bold': 63247,
    'ph-bug-bold': 63248,
    'ph-bug-droid-bold': 63249,
    'ph-buildings-bold': 63250,
    'ph-bus-bold': 63251,
    'ph-butterfly-bold': 63252,
    'ph-cactus-bold': 63253,
    'ph-cake-bold': 63254,
    'ph-calculator-bold': 63255,
    'ph-calendar-blank-bold': 63256,
    'ph-calendar-bold': 63257,
    'ph-calendar-check-bold': 63258,
    'ph-calendar-plus-bold': 63259,
    'ph-calendar-x-bold': 63260,
    'ph-camera-bold': 63261,
    'ph-camera-rotate-bold': 63262,
    'ph-camera-slash-bold': 63263,
    'ph-campfire-bold': 63264,
    'ph-car-bold': 63265,
    'ph-car-simple-bold': 63266,
    'ph-cardholder-bold': 63267,
    'ph-cards-bold': 63268,
    'ph-caret-circle-double-down-bold': 63269,
    'ph-caret-circle-double-left-bold': 63270,
    'ph-caret-circle-double-right-bold': 63271,
    'ph-caret-circle-double-up-bold': 63272,
    'ph-caret-circle-down-bold': 63273,
    'ph-caret-circle-left-bold': 63274,
    'ph-caret-circle-right-bold': 63275,
    'ph-caret-circle-up-bold': 63276,
    'ph-caret-double-down-bold': 63277,
    'ph-caret-double-left-bold': 63278,
    'ph-caret-double-right-bold': 63279,
    'ph-caret-double-up-bold': 63280,
    'ph-caret-down-bold': 63281,
    'ph-caret-left-bold': 63282,
    'ph-caret-right-bold': 63283,
    'ph-caret-up-bold': 63284,
    'ph-cat-bold': 63285,
    'ph-cell-signal-full-bold': 63286,
    'ph-cell-signal-high-bold': 63287,
    'ph-cell-signal-low-bold': 63288,
    'ph-cell-signal-medium-bold': 63289,
    'ph-cell-signal-none-bold': 63290,
    'ph-cell-signal-slash-bold': 63291,
    'ph-cell-signal-x-bold': 63292,
    'ph-chalkboard-bold': 63293,
    'ph-chalkboard-simple-bold': 63294,
    'ph-chalkboard-teacher-bold': 63295,
    'ph-chart-bar-bold': 63296,
    'ph-chart-bar-horizontal-bold': 63297,
    'ph-chart-line-bold': 63298,
    'ph-chart-line-up-bold': 63299,
    'ph-chart-pie-bold': 63300,
    'ph-chart-pie-slice-bold': 63301,
    'ph-chat-bold': 63302,
    'ph-chat-centered-bold': 63303,
    'ph-chat-centered-dots-bold': 63304,
    'ph-chat-centered-text-bold': 63305,
    'ph-chat-circle-bold': 63306,
    'ph-chat-circle-dots-bold': 63307,
    'ph-chat-circle-text-bold': 63308,
    'ph-chat-dots-bold': 63309,
    'ph-chat-teardrop-bold': 63310,
    'ph-chat-teardrop-dots-bold': 63311,
    'ph-chat-teardrop-text-bold': 63312,
    'ph-chat-text-bold': 63313,
    'ph-chats-bold': 63314,
    'ph-chats-circle-bold': 63315,
    'ph-chats-teardrop-bold': 63316,
    'ph-check-bold': 63317,
    'ph-check-circle-bold': 63318,
    'ph-check-square-bold': 63319,
    'ph-check-square-offset-bold': 63320,
    'ph-checks-bold': 63321,
    'ph-circle-bold': 63322,
    'ph-circle-dashed-bold': 63323,
    'ph-circle-half-bold': 63324,
    'ph-circle-half-tilt-bold': 63325,
    'ph-circle-notch-bold': 63326,
    'ph-circle-wavy-bold': 63327,
    'ph-circle-wavy-check-bold': 63328,
    'ph-circle-wavy-question-bold': 63329,
    'ph-circle-wavy-warning-bold': 63330,
    'ph-circles-four-bold': 63331,
    'ph-circles-three-bold': 63332,
    'ph-circles-three-plus-bold': 63333,
    'ph-clipboard-bold': 63334,
    'ph-clipboard-text-bold': 63335,
    'ph-clock-afternoon-bold': 63336,
    'ph-clock-bold': 63337,
    'ph-clock-clockwise-bold': 63338,
    'ph-clock-counter-clockwise-bold': 63339,
    'ph-closed-captioning-bold': 63340,
    'ph-cloud-arrow-down-bold': 63341,
    'ph-cloud-arrow-up-bold': 63342,
    'ph-cloud-bold': 63343,
    'ph-cloud-check-bold': 63344,
    'ph-cloud-fog-bold': 63345,
    'ph-cloud-lightning-bold': 63346,
    'ph-cloud-moon-bold': 63347,
    'ph-cloud-rain-bold': 63348,
    'ph-cloud-slash-bold': 63349,
    'ph-cloud-snow-bold': 63350,
    'ph-cloud-sun-bold': 63351,
    'ph-club-bold': 63352,
    'ph-coat-hanger-bold': 63353,
    'ph-code-bold': 63354,
    'ph-code-simple-bold': 63355,
    'ph-codepen-logo-bold': 63356,
    'ph-codesandbox-logo-bold': 63357,
    'ph-coffee-bold': 63358,
    'ph-coin-bold': 63359,
    'ph-coin-vertical-bold': 63360,
    'ph-coins-bold': 63361,
    'ph-columns-bold': 63362,
    'ph-command-bold': 63363,
    'ph-compass-bold': 63364,
    'ph-computer-tower-bold': 63365,
    'ph-confetti-bold': 63366,
    'ph-cookie-bold': 63367,
    'ph-cooking-pot-bold': 63368,
    'ph-copy-bold': 63369,
    'ph-copy-simple-bold': 63370,
    'ph-copyleft-bold': 63371,
    'ph-copyright-bold': 63372,
    'ph-corners-in-bold': 63373,
    'ph-corners-out-bold': 63374,
    'ph-cpu-bold': 63375,
    'ph-credit-card-bold': 63376,
    'ph-crop-bold': 63377,
    'ph-crosshair-bold': 63378,
    'ph-crosshair-simple-bold': 63379,
    'ph-crown-bold': 63380,
    'ph-crown-simple-bold': 63381,
    'ph-cube-bold': 63382,
    'ph-currency-btc-bold': 63383,
    'ph-currency-circle-dollar-bold': 63384,
    'ph-currency-cny-bold': 63385,
    'ph-currency-dollar-bold': 63386,
    'ph-currency-dollar-simple-bold': 63387,
    'ph-currency-eth-bold': 63388,
    'ph-currency-eur-bold': 63389,
    'ph-currency-gbp-bold': 63390,
    'ph-currency-inr-bold': 63391,
    'ph-currency-jpy-bold': 63392,
    'ph-currency-krw-bold': 63393,
    'ph-currency-kzt-bold': 63394,
    'ph-currency-ngn-bold': 63395,
    'ph-currency-rub-bold': 63396,
    'ph-cursor-bold': 63397,
    'ph-cursor-text-bold': 63398,
    'ph-cylinder-bold': 63399,
    'ph-database-bold': 63400,
    'ph-desktop-bold': 63401,
    'ph-desktop-tower-bold': 63402,
    'ph-detective-bold': 63403,
    'ph-device-mobile-bold': 63404,
    'ph-device-mobile-camera-bold': 63405,
    'ph-device-mobile-speaker-bold': 63406,
    'ph-device-tablet-bold': 63407,
    'ph-device-tablet-camera-bold': 63408,
    'ph-device-tablet-speaker-bold': 63409,
    'ph-diamond-bold': 63410,
    'ph-diamonds-four-bold': 63411,
    'ph-dice-five-bold': 63412,
    'ph-dice-four-bold': 63413,
    'ph-dice-one-bold': 63414,
    'ph-dice-six-bold': 63415,
    'ph-dice-three-bold': 63416,
    'ph-dice-two-bold': 63417,
    'ph-disc-bold': 63418,
    'ph-discord-logo-bold': 63419,
    'ph-divide-bold': 63420,
    'ph-dog-bold': 63421,
    'ph-door-bold': 63422,
    'ph-dots-nine-bold': 63423,
    'ph-dots-six-bold': 63424,
    'ph-dots-six-vertical-bold': 63425,
    'ph-dots-three-bold': 63426,
    'ph-dots-three-circle-bold': 63427,
    'ph-dots-three-circle-vertical-bold': 63428,
    'ph-dots-three-outline-bold': 63429,
    'ph-dots-three-outline-vertical-bold': 63430,
    'ph-dots-three-vertical-bold': 63431,
    'ph-download-bold': 63432,
    'ph-download-simple-bold': 63433,
    'ph-dribbble-logo-bold': 63434,
    'ph-drop-bold': 63435,
    'ph-drop-half-bold': 63436,
    'ph-drop-half-bottom-bold': 63437,
    'ph-ear-bold': 63438,
    'ph-ear-slash-bold': 63439,
    'ph-egg-bold': 63440,
    'ph-egg-crack-bold': 63441,
    'ph-eject-bold': 63442,
    'ph-eject-simple-bold': 63443,
    'ph-envelope-bold': 63444,
    'ph-envelope-open-bold': 63445,
    'ph-envelope-simple-bold': 63446,
    'ph-envelope-simple-open-bold': 63447,
    'ph-equalizer-bold': 63448,
    'ph-equals-bold': 63449,
    'ph-eraser-bold': 63450,
    'ph-exam-bold': 63451,
    'ph-export-bold': 63452,
    'ph-eye-bold': 63453,
    'ph-eye-closed-bold': 63454,
    'ph-eye-slash-bold': 63455,
    'ph-eyedropper-bold': 63456,
    'ph-eyedropper-sample-bold': 63457,
    'ph-eyeglasses-bold': 63458,
    'ph-face-mask-bold': 63459,
    'ph-facebook-logo-bold': 63460,
    'ph-factory-bold': 63461,
    'ph-faders-bold': 63462,
    'ph-faders-horizontal-bold': 63463,
    'ph-fast-forward-bold': 63464,
    'ph-fast-forward-circle-bold': 63465,
    'ph-figma-logo-bold': 63466,
    'ph-file-arrow-down-bold': 63467,
    'ph-file-arrow-up-bold': 63468,
    'ph-file-audio-bold': 63469,
    'ph-file-bold': 63470,
    'ph-file-cloud-bold': 63471,
    'ph-file-code-bold': 63472,
    'ph-file-css-bold': 63473,
    'ph-file-csv-bold': 63474,
    'ph-file-doc-bold': 63475,
    'ph-file-dotted-bold': 63476,
    'ph-file-html-bold': 63477,
    'ph-file-image-bold': 63478,
    'ph-file-jpg-bold': 63479,
    'ph-file-js-bold': 63480,
    'ph-file-jsx-bold': 63481,
    'ph-file-lock-bold': 63482,
    'ph-file-minus-bold': 63483,
    'ph-file-pdf-bold': 63484,
    'ph-file-plus-bold': 63485,
    'ph-file-png-bold': 63486,
    'ph-file-ppt-bold': 63487,
    'ph-file-rs-bold': 63488,
    'ph-file-search-bold': 63489,
    'ph-file-text-bold': 63490,
    'ph-file-ts-bold': 63491,
    'ph-file-tsx-bold': 63492,
    'ph-file-video-bold': 63493,
    'ph-file-vue-bold': 63494,
    'ph-file-x-bold': 63495,
    'ph-file-xls-bold': 63496,
    'ph-file-zip-bold': 63497,
    'ph-files-bold': 63498,
    'ph-film-script-bold': 63499,
    'ph-film-slate-bold': 63500,
    'ph-film-strip-bold': 63501,
    'ph-fingerprint-bold': 63502,
    'ph-fingerprint-simple-bold': 63503,
    'ph-finn-the-human-bold': 63504,
    'ph-fire-bold': 63505,
    'ph-fire-simple-bold': 63506,
    'ph-first-aid-bold': 63507,
    'ph-first-aid-kit-bold': 63508,
    'ph-fish-bold': 63509,
    'ph-fish-simple-bold': 63510,
    'ph-flag-banner-bold': 63511,
    'ph-flag-bold': 63512,
    'ph-flag-checkered-bold': 63513,
    'ph-flame-bold': 63514,
    'ph-flashlight-bold': 63515,
    'ph-flask-bold': 63516,
    'ph-floppy-disk-back-bold': 63517,
    'ph-floppy-disk-bold': 63518,
    'ph-flow-arrow-bold': 63519,
    'ph-flower-bold': 63520,
    'ph-flower-lotus-bold': 63521,
    'ph-flying-saucer-bold': 63522,
    'ph-folder-bold': 63523,
    'ph-folder-dotted-bold': 63524,
    'ph-folder-lock-bold': 63525,
    'ph-folder-minus-bold': 63526,
    'ph-folder-notch-bold': 63527,
    'ph-folder-notch-minus-bold': 63528,
    'ph-folder-notch-open-bold': 63529,
    'ph-folder-notch-plus-bold': 63530,
    'ph-folder-open-bold': 63531,
    'ph-folder-plus-bold': 63532,
    'ph-folder-simple-bold': 63533,
    'ph-folder-simple-dotted-bold': 63534,
    'ph-folder-simple-lock-bold': 63535,
    'ph-folder-simple-minus-bold': 63536,
    'ph-folder-simple-plus-bold': 63537,
    'ph-folder-simple-star-bold': 63538,
    'ph-folder-simple-user-bold': 63539,
    'ph-folder-star-bold': 63540,
    'ph-folder-user-bold': 63541,
    'ph-folders-bold': 63542,
    'ph-football-bold': 63543,
    'ph-fork-knife-bold': 63544,
    'ph-frame-corners-bold': 63545,
    'ph-framer-logo-bold': 63546,
    'ph-function-bold': 63547,
    'ph-funnel-bold': 63548,
    'ph-funnel-simple-bold': 63549,
    'ph-game-controller-bold': 63550,
    'ph-gas-pump-bold': 63551,
    'ph-gauge-bold': 63552,
    'ph-gear-bold': 63553,
    'ph-gear-six-bold': 63554,
    'ph-gender-female-bold': 63555,
    'ph-gender-intersex-bold': 63556,
    'ph-gender-male-bold': 63557,
    'ph-gender-neuter-bold': 63558,
    'ph-gender-nonbinary-bold': 63559,
    'ph-gender-transgender-bold': 63560,
    'ph-ghost-bold': 63561,
    'ph-gif-bold': 63562,
    'ph-gift-bold': 63563,
    'ph-git-branch-bold': 63564,
    'ph-git-commit-bold': 63565,
    'ph-git-diff-bold': 63566,
    'ph-git-fork-bold': 63567,
    'ph-git-merge-bold': 63568,
    'ph-git-pull-request-bold': 63569,
    'ph-github-logo-bold': 63570,
    'ph-gitlab-logo-bold': 63571,
    'ph-gitlab-logo-simple-bold': 63572,
    'ph-globe-bold': 63573,
    'ph-globe-hemisphere-east-bold': 63574,
    'ph-globe-hemisphere-west-bold': 63575,
    'ph-globe-simple-bold': 63576,
    'ph-globe-stand-bold': 63577,
    'ph-google-chrome-logo-bold': 63578,
    'ph-google-logo-bold': 63579,
    'ph-google-photos-logo-bold': 63580,
    'ph-google-play-logo-bold': 63581,
    'ph-google-podcasts-logo-bold': 63582,
    'ph-gradient-bold': 63583,
    'ph-graduation-cap-bold': 63584,
    'ph-graph-bold': 63585,
    'ph-grid-four-bold': 63586,
    'ph-hamburger-bold': 63587,
    'ph-hand-bold': 63588,
    'ph-hand-eye-bold': 63589,
    'ph-hand-fist-bold': 63590,
    'ph-hand-grabbing-bold': 63591,
    'ph-hand-palm-bold': 63592,
    'ph-hand-pointing-bold': 63593,
    'ph-hand-soap-bold': 63594,
    'ph-hand-waving-bold': 63595,
    'ph-handbag-bold': 63596,
    'ph-handbag-simple-bold': 63597,
    'ph-hands-clapping-bold': 63598,
    'ph-handshake-bold': 63599,
    'ph-hard-drive-bold': 63600,
    'ph-hard-drives-bold': 63601,
    'ph-hash-bold': 63602,
    'ph-hash-straight-bold': 63603,
    'ph-headlights-bold': 63604,
    'ph-headphones-bold': 63605,
    'ph-headset-bold': 63606,
    'ph-heart-bold': 63607,
    'ph-heart-break-bold': 63608,
    'ph-heart-straight-bold': 63609,
    'ph-heart-straight-break-bold': 63610,
    'ph-heartbeat-bold': 63611,
    'ph-hexagon-bold': 63612,
    'ph-highlighter-circle-bold': 63613,
    'ph-horse-bold': 63614,
    'ph-hourglass-bold': 63615,
    'ph-hourglass-high-bold': 63616,
    'ph-hourglass-low-bold': 63617,
    'ph-hourglass-medium-bold': 63618,
    'ph-hourglass-simple-bold': 63619,
    'ph-hourglass-simple-high-bold': 63620,
    'ph-hourglass-simple-low-bold': 63621,
    'ph-hourglass-simple-medium-bold': 63622,
    'ph-house-bold': 63623,
    'ph-house-line-bold': 63624,
    'ph-house-simple-bold': 63625,
    'ph-identification-badge-bold': 63626,
    'ph-identification-card-bold': 63627,
    'ph-image-bold': 63628,
    'ph-image-square-bold': 63629,
    'ph-infinity-bold': 63630,
    'ph-info-bold': 63631,
    'ph-instagram-logo-bold': 63632,
    'ph-intersect-bold': 63633,
    'ph-jeep-bold': 63634,
    'ph-kanban-bold': 63635,
    'ph-key-bold': 63636,
    'ph-key-return-bold': 63637,
    'ph-keyboard-bold': 63638,
    'ph-keyhole-bold': 63639,
    'ph-knife-bold': 63640,
    'ph-ladder-bold': 63641,
    'ph-ladder-simple-bold': 63642,
    'ph-lamp-bold': 63643,
    'ph-laptop-bold': 63644,
    'ph-layout-bold': 63645,
    'ph-leaf-bold': 63646,
    'ph-lifebuoy-bold': 63647,
    'ph-lightbulb-bold': 63648,
    'ph-lightbulb-filament-bold': 63649,
    'ph-lightning-bold': 63650,
    'ph-lightning-slash-bold': 63651,
    'ph-line-segment-bold': 63652,
    'ph-line-segments-bold': 63653,
    'ph-link-bold': 63654,
    'ph-link-break-bold': 63655,
    'ph-link-simple-bold': 63656,
    'ph-link-simple-break-bold': 63657,
    'ph-link-simple-horizontal-bold': 63658,
    'ph-link-simple-horizontal-break-bold': 63659,
    'ph-linkedin-logo-bold': 63660,
    'ph-linux-logo-bold': 63661,
    'ph-list-bold': 63662,
    'ph-list-bullets-bold': 63663,
    'ph-list-checks-bold': 63664,
    'ph-list-dashes-bold': 63665,
    'ph-list-numbers-bold': 63666,
    'ph-list-plus-bold': 63667,
    'ph-lock-bold': 63668,
    'ph-lock-key-bold': 63669,
    'ph-lock-key-open-bold': 63670,
    'ph-lock-laminated-bold': 63671,
    'ph-lock-laminated-open-bold': 63672,
    'ph-lock-open-bold': 63673,
    'ph-lock-simple-bold': 63674,
    'ph-lock-simple-open-bold': 63675,
    'ph-magic-wand-bold': 63676,
    'ph-magnet-bold': 63677,
    'ph-magnet-straight-bold': 63678,
    'ph-magnifying-glass-bold': 63679,
    'ph-magnifying-glass-minus-bold': 63680,
    'ph-magnifying-glass-plus-bold': 63681,
    'ph-map-pin-bold': 63682,
    'ph-map-pin-line-bold': 63683,
    'ph-map-trifold-bold': 63684,
    'ph-marker-circle-bold': 63685,
    'ph-martini-bold': 63686,
    'ph-mask-happy-bold': 63687,
    'ph-mask-sad-bold': 63688,
    'ph-math-operations-bold': 63689,
    'ph-medal-bold': 63690,
    'ph-medium-logo-bold': 63691,
    'ph-megaphone-bold': 63692,
    'ph-megaphone-simple-bold': 63693,
    'ph-messenger-logo-bold': 63694,
    'ph-microphone-bold': 63695,
    'ph-microphone-slash-bold': 63696,
    'ph-microphone-stage-bold': 63697,
    'ph-microsoft-excel-logo-bold': 63698,
    'ph-microsoft-powerpoint-logo-bold': 63699,
    'ph-microsoft-teams-logo-bold': 63700,
    'ph-microsoft-word-logo-bold': 63701,
    'ph-minus-bold': 63702,
    'ph-minus-circle-bold': 63703,
    'ph-money-bold': 63704,
    'ph-monitor-bold': 63705,
    'ph-monitor-play-bold': 63706,
    'ph-moon-bold': 63707,
    'ph-moon-stars-bold': 63708,
    'ph-mountains-bold': 63709,
    'ph-mouse-bold': 63710,
    'ph-mouse-simple-bold': 63711,
    'ph-music-note-bold': 63712,
    'ph-music-note-simple-bold': 63713,
    'ph-music-notes-bold': 63714,
    'ph-music-notes-plus-bold': 63715,
    'ph-music-notes-simple-bold': 63716,
    'ph-navigation-arrow-bold': 63717,
    'ph-needle-bold': 63718,
    'ph-newspaper-bold': 63719,
    'ph-newspaper-clipping-bold': 63720,
    'ph-note-blank-bold': 63721,
    'ph-note-bold': 63722,
    'ph-note-pencil-bold': 63723,
    'ph-notebook-bold': 63724,
    'ph-notepad-bold': 63725,
    'ph-notification-bold': 63726,
    'ph-number-circle-eight-bold': 63727,
    'ph-number-circle-five-bold': 63728,
    'ph-number-circle-four-bold': 63729,
    'ph-number-circle-nine-bold': 63730,
    'ph-number-circle-one-bold': 63731,
    'ph-number-circle-seven-bold': 63732,
    'ph-number-circle-six-bold': 63733,
    'ph-number-circle-three-bold': 63734,
    'ph-number-circle-two-bold': 63735,
    'ph-number-circle-zero-bold': 63736,
    'ph-number-eight-bold': 63737,
    'ph-number-five-bold': 63738,
    'ph-number-four-bold': 63739,
    'ph-number-nine-bold': 63740,
    'ph-number-one-bold': 63741,
    'ph-number-seven-bold': 63742,
    'ph-number-six-bold': 63743,
    'ph-number-square-eight-bold': 63744,
    'ph-number-square-five-bold': 63745,
    'ph-number-square-four-bold': 63746,
    'ph-number-square-nine-bold': 63747,
    'ph-number-square-one-bold': 63748,
    'ph-number-square-seven-bold': 63749,
    'ph-number-square-six-bold': 63750,
    'ph-number-square-three-bold': 63751,
    'ph-number-square-two-bold': 63752,
    'ph-number-square-zero-bold': 63753,
    'ph-number-three-bold': 63754,
    'ph-number-two-bold': 63755,
    'ph-number-zero-bold': 63756,
    'ph-nut-bold': 63757,
    'ph-ny-times-logo-bold': 63758,
    'ph-octagon-bold': 63759,
    'ph-option-bold': 63760,
    'ph-package-bold': 63761,
    'ph-paint-brush-bold': 63762,
    'ph-paint-brush-broad-bold': 63763,
    'ph-paint-brush-household-bold': 63764,
    'ph-paint-bucket-bold': 63765,
    'ph-paint-roller-bold': 63766,
    'ph-palette-bold': 63767,
    'ph-paper-plane-bold': 63768,
    'ph-paper-plane-right-bold': 63769,
    'ph-paper-plane-tilt-bold': 63770,
    'ph-paperclip-bold': 63771,
    'ph-paperclip-horizontal-bold': 63772,
    'ph-parachute-bold': 63773,
    'ph-password-bold': 63774,
    'ph-path-bold': 63775,
    'ph-pause-bold': 63776,
    'ph-pause-circle-bold': 63777,
    'ph-paw-print-bold': 63778,
    'ph-peace-bold': 63779,
    'ph-pen-bold': 63780,
    'ph-pen-nib-bold': 63781,
    'ph-pen-nib-straight-bold': 63782,
    'ph-pencil-bold': 63783,
    'ph-pencil-circle-bold': 63784,
    'ph-pencil-line-bold': 63785,
    'ph-pencil-simple-bold': 63786,
    'ph-pencil-simple-line-bold': 63787,
    'ph-percent-bold': 63788,
    'ph-person-bold': 63789,
    'ph-person-simple-bold': 63790,
    'ph-person-simple-run-bold': 63791,
    'ph-person-simple-walk-bold': 63792,
    'ph-perspective-bold': 63793,
    'ph-phone-bold': 63794,
    'ph-phone-call-bold': 63795,
    'ph-phone-disconnect-bold': 63796,
    'ph-phone-incoming-bold': 63797,
    'ph-phone-outgoing-bold': 63798,
    'ph-phone-slash-bold': 63799,
    'ph-phone-x-bold': 63800,
    'ph-phosphor-logo-bold': 63801,
    'ph-piano-keys-bold': 63802,
    'ph-picture-in-picture-bold': 63803,
    'ph-pill-bold': 63804,
    'ph-pinterest-logo-bold': 63805,
    'ph-pinwheel-bold': 63806,
    'ph-pizza-bold': 63807,
    'ph-placeholder-bold': 63808,
    'ph-planet-bold': 63809,
    'ph-play-bold': 63810,
    'ph-play-circle-bold': 63811,
    'ph-playlist-bold': 63812,
    'ph-plug-bold': 63813,
    'ph-plugs-bold': 63814,
    'ph-plugs-connected-bold': 63815,
    'ph-plus-bold': 63816,
    'ph-plus-circle-bold': 63817,
    'ph-plus-minus-bold': 63818,
    'ph-poker-chip-bold': 63819,
    'ph-police-car-bold': 63820,
    'ph-polygon-bold': 63821,
    'ph-popcorn-bold': 63822,
    'ph-power-bold': 63823,
    'ph-prescription-bold': 63824,
    'ph-presentation-bold': 63825,
    'ph-presentation-chart-bold': 63826,
    'ph-printer-bold': 63827,
    'ph-prohibit-bold': 63828,
    'ph-prohibit-inset-bold': 63829,
    'ph-projector-screen-bold': 63830,
    'ph-projector-screen-chart-bold': 63831,
    'ph-push-pin-bold': 63832,
    'ph-push-pin-simple-bold': 63833,
    'ph-push-pin-simple-slash-bold': 63834,
    'ph-push-pin-slash-bold': 63835,
    'ph-puzzle-piece-bold': 63836,
    'ph-qr-code-bold': 63837,
    'ph-question-bold': 63838,
    'ph-queue-bold': 63839,
    'ph-quotes-bold': 63840,
    'ph-radical-bold': 63841,
    'ph-radio-bold': 63842,
    'ph-radio-button-bold': 63843,
    'ph-rainbow-bold': 63844,
    'ph-rainbow-cloud-bold': 63845,
    'ph-receipt-bold': 63846,
    'ph-record-bold': 63847,
    'ph-rectangle-bold': 63848,
    'ph-recycle-bold': 63849,
    'ph-reddit-logo-bold': 63850,
    'ph-repeat-bold': 63851,
    'ph-repeat-once-bold': 63852,
    'ph-rewind-bold': 63853,
    'ph-rewind-circle-bold': 63854,
    'ph-robot-bold': 63855,
    'ph-rocket-bold': 63856,
    'ph-rocket-launch-bold': 63857,
    'ph-rows-bold': 63858,
    'ph-rss-bold': 63859,
    'ph-rss-simple-bold': 63860,
    'ph-rug-bold': 63861,
    'ph-ruler-bold': 63862,
    'ph-scales-bold': 63863,
    'ph-scan-bold': 63864,
    'ph-scissors-bold': 63865,
    'ph-screencast-bold': 63866,
    'ph-scribble-loop-bold': 63867,
    'ph-scroll-bold': 63868,
    'ph-selection-all-bold': 63869,
    'ph-selection-background-bold': 63870,
    'ph-selection-bold': 63871,
    'ph-selection-foreground-bold': 63872,
    'ph-selection-inverse-bold': 63873,
    'ph-selection-plus-bold': 63874,
    'ph-selection-slash-bold': 63875,
    'ph-share-bold': 63876,
    'ph-share-network-bold': 63877,
    'ph-shield-bold': 63878,
    'ph-shield-check-bold': 63879,
    'ph-shield-checkered-bold': 63880,
    'ph-shield-chevron-bold': 63881,
    'ph-shield-plus-bold': 63882,
    'ph-shield-slash-bold': 63883,
    'ph-shield-star-bold': 63884,
    'ph-shield-warning-bold': 63885,
    'ph-shopping-bag-bold': 63886,
    'ph-shopping-bag-open-bold': 63887,
    'ph-shopping-cart-bold': 63888,
    'ph-shopping-cart-simple-bold': 63889,
    'ph-shower-bold': 63890,
    'ph-shuffle-angular-bold': 63891,
    'ph-shuffle-bold': 63892,
    'ph-shuffle-simple-bold': 63893,
    'ph-sidebar-bold': 63894,
    'ph-sidebar-simple-bold': 63895,
    'ph-sign-in-bold': 63896,
    'ph-sign-out-bold': 63897,
    'ph-signpost-bold': 63898,
    'ph-sim-card-bold': 63899,
    'ph-sketch-logo-bold': 63900,
    'ph-skip-back-bold': 63901,
    'ph-skip-back-circle-bold': 63902,
    'ph-skip-forward-bold': 63903,
    'ph-skip-forward-circle-bold': 63904,
    'ph-skull-bold': 63905,
    'ph-slack-logo-bold': 63906,
    'ph-sliders-bold': 63907,
    'ph-sliders-horizontal-bold': 63908,
    'ph-smiley-blank-bold': 63909,
    'ph-smiley-bold': 63910,
    'ph-smiley-meh-bold': 63911,
    'ph-smiley-nervous-bold': 63912,
    'ph-smiley-sad-bold': 63913,
    'ph-smiley-sticker-bold': 63914,
    'ph-smiley-wink-bold': 63915,
    'ph-smiley-x-eyes-bold': 63916,
    'ph-snapchat-logo-bold': 63917,
    'ph-snowflake-bold': 63918,
    'ph-soccer-ball-bold': 63919,
    'ph-sort-ascending-bold': 63920,
    'ph-sort-descending-bold': 63921,
    'ph-spade-bold': 63922,
    'ph-sparkle-bold': 63923,
    'ph-speaker-high-bold': 63924,
    'ph-speaker-low-bold': 63925,
    'ph-speaker-none-bold': 63926,
    'ph-speaker-simple-high-bold': 63927,
    'ph-speaker-simple-low-bold': 63928,
    'ph-speaker-simple-none-bold': 63929,
    'ph-speaker-simple-slash-bold': 63930,
    'ph-speaker-simple-x-bold': 63931,
    'ph-speaker-slash-bold': 63932,
    'ph-speaker-x-bold': 63933,
    'ph-spinner-bold': 63934,
    'ph-spinner-gap-bold': 63935,
    'ph-spiral-bold': 63936,
    'ph-spotify-logo-bold': 63937,
    'ph-square-bold': 63938,
    'ph-square-half-bold': 63939,
    'ph-square-half-bottom-bold': 63940,
    'ph-square-logo-bold': 63941,
    'ph-squares-four-bold': 63942,
    'ph-stack-bold': 63943,
    'ph-stack-overflow-logo-bold': 63944,
    'ph-stack-simple-bold': 63945,
    'ph-stamp-bold': 63946,
    'ph-star-bold': 63947,
    'ph-star-four-bold': 63948,
    'ph-star-half-bold': 63949,
    'ph-sticker-bold': 63950,
    'ph-stop-bold': 63951,
    'ph-stop-circle-bold': 63952,
    'ph-storefront-bold': 63953,
    'ph-strategy-bold': 63954,
    'ph-stripe-logo-bold': 63955,
    'ph-student-bold': 63956,
    'ph-suitcase-bold': 63957,
    'ph-suitcase-simple-bold': 63958,
    'ph-sun-bold': 63959,
    'ph-sun-dim-bold': 63960,
    'ph-sun-horizon-bold': 63961,
    'ph-sunglasses-bold': 63962,
    'ph-swap-bold': 63963,
    'ph-swatches-bold': 63964,
    'ph-sword-bold': 63965,
    'ph-syringe-bold': 63966,
    'ph-t-shirt-bold': 63967,
    'ph-table-bold': 63968,
    'ph-tabs-bold': 63969,
    'ph-tag-bold': 63970,
    'ph-tag-chevron-bold': 63971,
    'ph-tag-simple-bold': 63972,
    'ph-target-bold': 63973,
    'ph-taxi-bold': 63974,
    'ph-telegram-logo-bold': 63975,
    'ph-television-bold': 63976,
    'ph-television-simple-bold': 63977,
    'ph-tennis-ball-bold': 63978,
    'ph-terminal-bold': 63979,
    'ph-terminal-window-bold': 63980,
    'ph-test-tube-bold': 63981,
    'ph-text-aa-bold': 63982,
    'ph-text-align-center-bold': 63983,
    'ph-text-align-justify-bold': 63984,
    'ph-text-align-left-bold': 63985,
    'ph-text-align-right-bold': 63986,
    'ph-text-bolder-bold': 63987,
    'ph-text-h-bold': 63988,
    'ph-text-h-five-bold': 63989,
    'ph-text-h-four-bold': 63990,
    'ph-text-h-one-bold': 63991,
    'ph-text-h-six-bold': 63992,
    'ph-text-h-three-bold': 63993,
    'ph-text-h-two-bold': 63994,
    'ph-text-indent-bold': 63995,
    'ph-text-italic-bold': 63996,
    'ph-text-outdent-bold': 63997,
    'ph-text-strikethrough-bold': 63998,
    'ph-text-t-bold': 63999,
    'ph-text-underline-bold': 64000,
    'ph-textbox-bold': 64001,
    'ph-thermometer-bold': 64002,
    'ph-thermometer-cold-bold': 64003,
    'ph-thermometer-hot-bold': 64004,
    'ph-thermometer-simple-bold': 64005,
    'ph-thumbs-down-bold': 64006,
    'ph-thumbs-up-bold': 64007,
    'ph-ticket-bold': 64008,
    'ph-tiktok-logo-bold': 64009,
    'ph-timer-bold': 64010,
    'ph-toggle-left-bold': 64011,
    'ph-toggle-right-bold': 64012,
    'ph-toilet-bold': 64013,
    'ph-toilet-paper-bold': 64014,
    'ph-tote-bold': 64015,
    'ph-tote-simple-bold': 64016,
    'ph-trademark-registered-bold': 64017,
    'ph-traffic-cone-bold': 64018,
    'ph-traffic-sign-bold': 64019,
    'ph-traffic-signal-bold': 64020,
    'ph-train-bold': 64021,
    'ph-train-regional-bold': 64022,
    'ph-train-simple-bold': 64023,
    'ph-translate-bold': 64024,
    'ph-trash-bold': 64025,
    'ph-trash-simple-bold': 64026,
    'ph-tray-bold': 64027,
    'ph-tree-bold': 64028,
    'ph-tree-evergreen-bold': 64029,
    'ph-tree-structure-bold': 64030,
    'ph-trend-down-bold': 64031,
    'ph-trend-up-bold': 64032,
    'ph-triangle-bold': 64033,
    'ph-trophy-bold': 64034,
    'ph-truck-bold': 64035,
    'ph-twitch-logo-bold': 64036,
    'ph-twitter-logo-bold': 64037,
    'ph-umbrella-bold': 64038,
    'ph-umbrella-simple-bold': 64039,
    'ph-upload-bold': 64040,
    'ph-upload-simple-bold': 64041,
    'ph-user-bold': 64042,
    'ph-user-circle-bold': 64043,
    'ph-user-circle-gear-bold': 64044,
    'ph-user-circle-minus-bold': 64045,
    'ph-user-circle-plus-bold': 64046,
    'ph-user-focus-bold': 64047,
    'ph-user-gear-bold': 64048,
    'ph-user-list-bold': 64049,
    'ph-user-minus-bold': 64050,
    'ph-user-plus-bold': 64051,
    'ph-user-rectangle-bold': 64052,
    'ph-user-square-bold': 64053,
    'ph-user-switch-bold': 64054,
    'ph-users-bold': 64055,
    'ph-users-four-bold': 64056,
    'ph-users-three-bold': 64057,
    'ph-vault-bold': 64058,
    'ph-vibrate-bold': 64059,
    'ph-video-camera-bold': 64060,
    'ph-video-camera-slash-bold': 64061,
    'ph-vignette-bold': 64062,
    'ph-voicemail-bold': 64063,
    'ph-volleyball-bold': 64064,
    'ph-wall-bold': 64065,
    'ph-wallet-bold': 64066,
    'ph-warning-bold': 64067,
    'ph-warning-circle-bold': 64068,
    'ph-warning-octagon-bold': 64069,
    'ph-watch-bold': 64070,
    'ph-wave-sawtooth-bold': 64071,
    'ph-wave-sine-bold': 64072,
    'ph-wave-square-bold': 64073,
    'ph-wave-triangle-bold': 64074,
    'ph-waves-bold': 64075,
    'ph-webcam-bold': 64076,
    'ph-whatsapp-logo-bold': 64077,
    'ph-wheelchair-bold': 64078,
    'ph-wifi-high-bold': 64079,
    'ph-wifi-low-bold': 64080,
    'ph-wifi-medium-bold': 64081,
    'ph-wifi-none-bold': 64082,
    'ph-wifi-slash-bold': 64083,
    'ph-wifi-x-bold': 64084,
    'ph-wind-bold': 64085,
    'ph-windows-logo-bold': 64086,
    'ph-wine-bold': 64087,
    'ph-wrench-bold': 64088,
    'ph-x-bold': 64089,
    'ph-x-circle-bold': 64090,
    'ph-x-square-bold': 64091,
    'ph-yin-yang-bold': 64092,
    'ph-youtube-logo-bold': 64093,
    'ph-activity-fill': 64094,
    'ph-address-book-fill': 64095,
    'ph-airplane-fill': 64096,
    'ph-airplane-in-flight-fill': 64097,
    'ph-airplane-landing-fill': 64098,
    'ph-airplane-takeoff-fill': 64099,
    'ph-airplane-tilt-fill': 64100,
    'ph-airplay-fill': 64101,
    'ph-alarm-fill': 64102,
    'ph-alien-fill': 64103,
    'ph-align-bottom-fill': 64104,
    'ph-align-bottom-simple-fill': 64105,
    'ph-align-center-horizontal-fill': 64106,
    'ph-align-center-horizontal-simple-fill': 64107,
    'ph-align-center-vertical-fill': 64108,
    'ph-align-center-vertical-simple-fill': 64109,
    'ph-align-left-fill': 64110,
    'ph-align-left-simple-fill': 64111,
    'ph-align-right-fill': 64112,
    'ph-align-right-simple-fill': 64113,
    'ph-align-top-fill': 64114,
    'ph-align-top-simple-fill': 64115,
    'ph-anchor-fill': 64116,
    'ph-anchor-simple-fill': 64117,
    'ph-android-logo-fill': 64118,
    'ph-angular-logo-fill': 64119,
    'ph-aperture-fill': 64120,
    'ph-app-store-logo-fill': 64121,
    'ph-app-window-fill': 64122,
    'ph-apple-logo-fill': 64123,
    'ph-apple-podcasts-logo-fill': 64124,
    'ph-archive-box-fill': 64125,
    'ph-archive-fill': 64126,
    'ph-archive-tray-fill': 64127,
    'ph-armchair-fill': 64128,
    'ph-arrow-arc-left-fill': 64129,
    'ph-arrow-arc-right-fill': 64130,
    'ph-arrow-bend-double-up-left-fill': 64131,
    'ph-arrow-bend-double-up-right-fill': 64132,
    'ph-arrow-bend-down-left-fill': 64133,
    'ph-arrow-bend-down-right-fill': 64134,
    'ph-arrow-bend-left-down-fill': 64135,
    'ph-arrow-bend-left-up-fill': 64136,
    'ph-arrow-bend-right-down-fill': 64137,
    'ph-arrow-bend-right-up-fill': 64138,
    'ph-arrow-bend-up-left-fill': 64139,
    'ph-arrow-bend-up-right-fill': 64140,
    'ph-arrow-circle-down-fill': 64141,
    'ph-arrow-circle-down-left-fill': 64142,
    'ph-arrow-circle-down-right-fill': 64143,
    'ph-arrow-circle-left-fill': 64144,
    'ph-arrow-circle-right-fill': 64145,
    'ph-arrow-circle-up-fill': 64146,
    'ph-arrow-circle-up-left-fill': 64147,
    'ph-arrow-circle-up-right-fill': 64148,
    'ph-arrow-clockwise-fill': 64149,
    'ph-arrow-counter-clockwise-fill': 64150,
    'ph-arrow-down-fill': 64151,
    'ph-arrow-down-left-fill': 64152,
    'ph-arrow-down-right-fill': 64153,
    'ph-arrow-elbow-down-left-fill': 64154,
    'ph-arrow-elbow-down-right-fill': 64155,
    'ph-arrow-elbow-left-down-fill': 64156,
    'ph-arrow-elbow-left-fill': 64157,
    'ph-arrow-elbow-left-up-fill': 64158,
    'ph-arrow-elbow-right-down-fill': 64159,
    'ph-arrow-elbow-right-fill': 64160,
    'ph-arrow-elbow-right-up-fill': 64161,
    'ph-arrow-elbow-up-left-fill': 64162,
    'ph-arrow-elbow-up-right-fill': 64163,
    'ph-arrow-fat-down-fill': 64164,
    'ph-arrow-fat-left-fill': 64165,
    'ph-arrow-fat-line-down-fill': 64166,
    'ph-arrow-fat-line-left-fill': 64167,
    'ph-arrow-fat-line-right-fill': 64168,
    'ph-arrow-fat-line-up-fill': 64169,
    'ph-arrow-fat-lines-down-fill': 64170,
    'ph-arrow-fat-lines-left-fill': 64171,
    'ph-arrow-fat-lines-right-fill': 64172,
    'ph-arrow-fat-lines-up-fill': 64173,
    'ph-arrow-fat-right-fill': 64174,
    'ph-arrow-fat-up-fill': 64175,
    'ph-arrow-left-fill': 64176,
    'ph-arrow-line-down-fill': 64177,
    'ph-arrow-line-down-left-fill': 64178,
    'ph-arrow-line-down-right-fill': 64179,
    'ph-arrow-line-left-fill': 64180,
    'ph-arrow-line-right-fill': 64181,
    'ph-arrow-line-up-fill': 64182,
    'ph-arrow-line-up-left-fill': 64183,
    'ph-arrow-line-up-right-fill': 64184,
    'ph-arrow-right-fill': 64185,
    'ph-arrow-square-down-fill': 64186,
    'ph-arrow-square-down-left-fill': 64187,
    'ph-arrow-square-down-right-fill': 64188,
    'ph-arrow-square-in-fill': 64189,
    'ph-arrow-square-left-fill': 64190,
    'ph-arrow-square-out-fill': 64191,
    'ph-arrow-square-right-fill': 64192,
    'ph-arrow-square-up-fill': 64193,
    'ph-arrow-square-up-left-fill': 64194,
    'ph-arrow-square-up-right-fill': 64195,
    'ph-arrow-u-down-left-fill': 64196,
    'ph-arrow-u-down-right-fill': 64197,
    'ph-arrow-u-left-down-fill': 64198,
    'ph-arrow-u-left-up-fill': 64199,
    'ph-arrow-u-right-down-fill': 64200,
    'ph-arrow-u-right-up-fill': 64201,
    'ph-arrow-u-up-left-fill': 64202,
    'ph-arrow-u-up-right-fill': 64203,
    'ph-arrow-up-fill': 64204,
    'ph-arrow-up-left-fill': 64205,
    'ph-arrow-up-right-fill': 64206,
    'ph-arrows-clockwise-fill': 64207,
    'ph-arrows-counter-clockwise-fill': 64208,
    'ph-arrows-down-up-fill': 64209,
    'ph-arrows-horizontal-fill': 64210,
    'ph-arrows-in-cardinal-fill': 64211,
    'ph-arrows-in-fill': 64212,
    'ph-arrows-in-line-horizontal-fill': 64213,
    'ph-arrows-in-line-vertical-fill': 64214,
    'ph-arrows-in-simple-fill': 64215,
    'ph-arrows-left-right-fill': 64216,
    'ph-arrows-out-cardinal-fill': 64217,
    'ph-arrows-out-fill': 64218,
    'ph-arrows-out-line-horizontal-fill': 64219,
    'ph-arrows-out-line-vertical-fill': 64220,
    'ph-arrows-out-simple-fill': 64221,
    'ph-arrows-vertical-fill': 64222,
    'ph-article-fill': 64223,
    'ph-article-medium-fill': 64224,
    'ph-article-ny-times-fill': 64225,
    'ph-asterisk-fill': 64226,
    'ph-asterisk-simple-fill': 64227,
    'ph-at-fill': 64228,
    'ph-atom-fill': 64229,
    'ph-baby-fill': 64230,
    'ph-backpack-fill': 64231,
    'ph-backspace-fill': 64232,
    'ph-bag-fill': 64233,
    'ph-bag-simple-fill': 64234,
    'ph-balloon-fill': 64235,
    'ph-bandaids-fill': 64236,
    'ph-bank-fill': 64237,
    'ph-barbell-fill': 64238,
    'ph-barcode-fill': 64239,
    'ph-barricade-fill': 64240,
    'ph-baseball-fill': 64241,
    'ph-basketball-fill': 64242,
    'ph-bathtub-fill': 64243,
    'ph-battery-charging-fill': 64244,
    'ph-battery-charging-vertical-fill': 64245,
    'ph-battery-empty-fill': 64246,
    'ph-battery-full-fill': 64247,
    'ph-battery-high-fill': 64248,
    'ph-battery-low-fill': 64249,
    'ph-battery-medium-fill': 64250,
    'ph-battery-plus-fill': 64251,
    'ph-battery-warning-fill': 64252,
    'ph-battery-warning-vertical-fill': 64253,
    'ph-bed-fill': 64254,
    'ph-beer-bottle-fill': 64255,
    'ph-behance-logo-fill': 64256,
    'ph-bell-fill': 64257,
    'ph-bell-ringing-fill': 64258,
    'ph-bell-simple-fill': 64259,
    'ph-bell-simple-ringing-fill': 64260,
    'ph-bell-simple-slash-fill': 64261,
    'ph-bell-simple-z-fill': 64262,
    'ph-bell-slash-fill': 64263,
    'ph-bell-z-fill': 64264,
    'ph-bezier-curve-fill': 64265,
    'ph-bicycle-fill': 64266,
    'ph-binoculars-fill': 64267,
    'ph-bird-fill': 64268,
    'ph-bluetooth-connected-fill': 64269,
    'ph-bluetooth-fill': 64270,
    'ph-bluetooth-slash-fill': 64271,
    'ph-bluetooth-x-fill': 64272,
    'ph-boat-fill': 64273,
    'ph-book-bookmark-fill': 64274,
    'ph-book-fill': 64275,
    'ph-book-open-fill': 64276,
    'ph-bookmark-fill': 64277,
    'ph-bookmark-simple-fill': 64278,
    'ph-bookmarks-fill': 64279,
    'ph-bookmarks-simple-fill': 64280,
    'ph-books-fill': 64281,
    'ph-bounding-box-fill': 64282,
    'ph-brackets-angle-fill': 64283,
    'ph-brackets-curly-fill': 64284,
    'ph-brackets-round-fill': 64285,
    'ph-brackets-square-fill': 64286,
    'ph-brain-fill': 64287,
    'ph-brandy-fill': 64288,
    'ph-briefcase-fill': 64289,
    'ph-briefcase-metal-fill': 64290,
    'ph-broadcast-fill': 64291,
    'ph-browser-fill': 64292,
    'ph-browsers-fill': 64293,
    'ph-bug-beetle-fill': 64294,
    'ph-bug-droid-fill': 64295,
    'ph-bug-fill': 64296,
    'ph-buildings-fill': 64297,
    'ph-bus-fill': 64298,
    'ph-butterfly-fill': 64299,
    'ph-cactus-fill': 64300,
    'ph-cake-fill': 64301,
    'ph-calculator-fill': 64302,
    'ph-calendar-blank-fill': 64303,
    'ph-calendar-check-fill': 64304,
    'ph-calendar-fill': 64305,
    'ph-calendar-plus-fill': 64306,
    'ph-calendar-x-fill': 64307,
    'ph-camera-fill': 64308,
    'ph-camera-rotate-fill': 64309,
    'ph-camera-slash-fill': 64310,
    'ph-campfire-fill': 64311,
    'ph-car-fill': 64312,
    'ph-car-simple-fill': 64313,
    'ph-cardholder-fill': 64314,
    'ph-cards-fill': 64315,
    'ph-caret-circle-double-down-fill': 64316,
    'ph-caret-circle-double-left-fill': 64317,
    'ph-caret-circle-double-right-fill': 64318,
    'ph-caret-circle-double-up-fill': 64319,
    'ph-caret-circle-down-fill': 64320,
    'ph-caret-circle-left-fill': 64321,
    'ph-caret-circle-right-fill': 64322,
    'ph-caret-circle-up-fill': 64323,
    'ph-caret-double-down-fill': 64324,
    'ph-caret-double-left-fill': 64325,
    'ph-caret-double-right-fill': 64326,
    'ph-caret-double-up-fill': 64327,
    'ph-caret-down-fill': 64328,
    'ph-caret-left-fill': 64329,
    'ph-caret-right-fill': 64330,
    'ph-caret-up-fill': 64331,
    'ph-cat-fill': 64332,
    'ph-cell-signal-full-fill': 64333,
    'ph-cell-signal-high-fill': 64334,
    'ph-cell-signal-low-fill': 64335,
    'ph-cell-signal-medium-fill': 64336,
    'ph-cell-signal-none-fill': 64337,
    'ph-cell-signal-slash-fill': 64338,
    'ph-cell-signal-x-fill': 64339,
    'ph-chalkboard-fill': 64340,
    'ph-chalkboard-simple-fill': 64341,
    'ph-chalkboard-teacher-fill': 64342,
    'ph-chart-bar-fill': 64343,
    'ph-chart-bar-horizontal-fill': 64344,
    'ph-chart-line-fill': 64345,
    'ph-chart-line-up-fill': 64346,
    'ph-chart-pie-fill': 64347,
    'ph-chart-pie-slice-fill': 64348,
    'ph-chat-centered-dots-fill': 64349,
    'ph-chat-centered-fill': 64350,
    'ph-chat-centered-text-fill': 64351,
    'ph-chat-circle-dots-fill': 64352,
    'ph-chat-circle-fill': 64353,
    'ph-chat-circle-text-fill': 64354,
    'ph-chat-dots-fill': 64355,
    'ph-chat-fill': 64356,
    'ph-chat-teardrop-dots-fill': 64357,
    'ph-chat-teardrop-fill': 64358,
    'ph-chat-teardrop-text-fill': 64359,
    'ph-chat-text-fill': 64360,
    'ph-chats-circle-fill': 64361,
    'ph-chats-fill': 64362,
    'ph-chats-teardrop-fill': 64363,
    'ph-check-circle-fill': 64364,
    'ph-check-fill': 64365,
    'ph-check-square-fill': 64366,
    'ph-check-square-offset-fill': 64367,
    'ph-checks-fill': 64368,
    'ph-circle-dashed-fill': 64369,
    'ph-circle-fill': 64370,
    'ph-circle-half-fill': 64371,
    'ph-circle-half-tilt-fill': 64372,
    'ph-circle-notch-fill': 64373,
    'ph-circle-wavy-check-fill': 64374,
    'ph-circle-wavy-fill': 64375,
    'ph-circle-wavy-question-fill': 64376,
    'ph-circle-wavy-warning-fill': 64377,
    'ph-circles-four-fill': 64378,
    'ph-circles-three-fill': 64379,
    'ph-circles-three-plus-fill': 64380,
    'ph-clipboard-fill': 64381,
    'ph-clipboard-text-fill': 64382,
    'ph-clock-afternoon-fill': 64383,
    'ph-clock-clockwise-fill': 64384,
    'ph-clock-counter-clockwise-fill': 64385,
    'ph-clock-fill': 64386,
    'ph-closed-captioning-fill': 64387,
    'ph-cloud-arrow-down-fill': 64388,
    'ph-cloud-arrow-up-fill': 64389,
    'ph-cloud-check-fill': 64390,
    'ph-cloud-fill': 64391,
    'ph-cloud-fog-fill': 64392,
    'ph-cloud-lightning-fill': 64393,
    'ph-cloud-moon-fill': 64394,
    'ph-cloud-rain-fill': 64395,
    'ph-cloud-slash-fill': 64396,
    'ph-cloud-snow-fill': 64397,
    'ph-cloud-sun-fill': 64398,
    'ph-club-fill': 64399,
    'ph-coat-hanger-fill': 64400,
    'ph-code-fill': 64401,
    'ph-code-simple-fill': 64402,
    'ph-codepen-logo-fill': 64403,
    'ph-codesandbox-logo-fill': 64404,
    'ph-coffee-fill': 64405,
    'ph-coin-fill': 64406,
    'ph-coin-vertical-fill': 64407,
    'ph-coins-fill': 64408,
    'ph-columns-fill': 64409,
    'ph-command-fill': 64410,
    'ph-compass-fill': 64411,
    'ph-computer-tower-fill': 64412,
    'ph-confetti-fill': 64413,
    'ph-cookie-fill': 64414,
    'ph-cooking-pot-fill': 64415,
    'ph-copy-fill': 64416,
    'ph-copy-simple-fill': 64417,
    'ph-copyleft-fill': 64418,
    'ph-copyright-fill': 64419,
    'ph-corners-in-fill': 64420,
    'ph-corners-out-fill': 64421,
    'ph-cpu-fill': 64422,
    'ph-credit-card-fill': 64423,
    'ph-crop-fill': 64424,
    'ph-crosshair-fill': 64425,
    'ph-crosshair-simple-fill': 64426,
    'ph-crown-fill': 64427,
    'ph-crown-simple-fill': 64428,
    'ph-cube-fill': 64429,
    'ph-currency-btc-fill': 64430,
    'ph-currency-circle-dollar-fill': 64431,
    'ph-currency-cny-fill': 64432,
    'ph-currency-dollar-fill': 64433,
    'ph-currency-dollar-simple-fill': 64434,
    'ph-currency-eth-fill': 64435,
    'ph-currency-eur-fill': 64436,
    'ph-currency-gbp-fill': 64437,
    'ph-currency-inr-fill': 64438,
    'ph-currency-jpy-fill': 64439,
    'ph-currency-krw-fill': 64440,
    'ph-currency-kzt-fill': 64441,
    'ph-currency-ngn-fill': 64442,
    'ph-currency-rub-fill': 64443,
    'ph-cursor-fill': 64444,
    'ph-cursor-text-fill': 64445,
    'ph-cylinder-fill': 64446,
    'ph-database-fill': 64447,
    'ph-desktop-fill': 64448,
    'ph-desktop-tower-fill': 64449,
    'ph-detective-fill': 64450,
    'ph-device-mobile-camera-fill': 64451,
    'ph-device-mobile-fill': 64452,
    'ph-device-mobile-speaker-fill': 64453,
    'ph-device-tablet-camera-fill': 64454,
    'ph-device-tablet-fill': 64455,
    'ph-device-tablet-speaker-fill': 64456,
    'ph-diamond-fill': 64457,
    'ph-diamonds-four-fill': 64458,
    'ph-dice-five-fill': 64459,
    'ph-dice-four-fill': 64460,
    'ph-dice-one-fill': 64461,
    'ph-dice-six-fill': 64462,
    'ph-dice-three-fill': 64463,
    'ph-dice-two-fill': 64464,
    'ph-disc-fill': 64465,
    'ph-discord-logo-fill': 64466,
    'ph-divide-fill': 64467,
    'ph-dog-fill': 64468,
    'ph-door-fill': 64469,
    'ph-dots-nine-fill': 64470,
    'ph-dots-six-fill': 64471,
    'ph-dots-six-vertical-fill': 64472,
    'ph-dots-three-circle-fill': 64473,
    'ph-dots-three-circle-vertical-fill': 64474,
    'ph-dots-three-fill': 64475,
    'ph-dots-three-outline-fill': 64476,
    'ph-dots-three-outline-vertical-fill': 64477,
    'ph-dots-three-vertical-fill': 64478,
    'ph-download-fill': 64479,
    'ph-download-simple-fill': 64480,
    'ph-dribbble-logo-fill': 64481,
    'ph-drop-fill': 64482,
    'ph-drop-half-bottom-fill': 64483,
    'ph-drop-half-fill': 64484,
    'ph-ear-fill': 64485,
    'ph-ear-slash-fill': 64486,
    'ph-egg-crack-fill': 64487,
    'ph-egg-fill': 64488,
    'ph-eject-fill': 64489,
    'ph-eject-simple-fill': 64490,
    'ph-envelope-fill': 64491,
    'ph-envelope-open-fill': 64492,
    'ph-envelope-simple-fill': 64493,
    'ph-envelope-simple-open-fill': 64494,
    'ph-equalizer-fill': 64495,
    'ph-equals-fill': 64496,
    'ph-eraser-fill': 64497,
    'ph-exam-fill': 64498,
    'ph-export-fill': 64499,
    'ph-eye-closed-fill': 64500,
    'ph-eye-fill': 64501,
    'ph-eye-slash-fill': 64502,
    'ph-eyedropper-fill': 64503,
    'ph-eyedropper-sample-fill': 64504,
    'ph-eyeglasses-fill': 64505,
    'ph-face-mask-fill': 64506,
    'ph-facebook-logo-fill': 64507,
    'ph-factory-fill': 64508,
    'ph-faders-fill': 64509,
    'ph-faders-horizontal-fill': 64510,
    'ph-fast-forward-circle-fill': 64511,
    'ph-fast-forward-fill': 64512,
    'ph-figma-logo-fill': 64513,
    'ph-file-arrow-down-fill': 64514,
    'ph-file-arrow-up-fill': 64515,
    'ph-file-audio-fill': 64516,
    'ph-file-cloud-fill': 64517,
    'ph-file-code-fill': 64518,
    'ph-file-css-fill': 64519,
    'ph-file-csv-fill': 64520,
    'ph-file-doc-fill': 64521,
    'ph-file-dotted-fill': 64522,
    'ph-file-fill': 64523,
    'ph-file-html-fill': 64524,
    'ph-file-image-fill': 64525,
    'ph-file-jpg-fill': 64526,
    'ph-file-js-fill': 64527,
    'ph-file-jsx-fill': 64528,
    'ph-file-lock-fill': 64529,
    'ph-file-minus-fill': 64530,
    'ph-file-pdf-fill': 64531,
    'ph-file-plus-fill': 64532,
    'ph-file-png-fill': 64533,
    'ph-file-ppt-fill': 64534,
    'ph-file-rs-fill': 64535,
    'ph-file-search-fill': 64536,
    'ph-file-text-fill': 64537,
    'ph-file-ts-fill': 64538,
    'ph-file-tsx-fill': 64539,
    'ph-file-video-fill': 64540,
    'ph-file-vue-fill': 64541,
    'ph-file-x-fill': 64542,
    'ph-file-xls-fill': 64543,
    'ph-file-zip-fill': 64544,
    'ph-files-fill': 64545,
    'ph-film-script-fill': 64546,
    'ph-film-slate-fill': 64547,
    'ph-film-strip-fill': 64548,
    'ph-fingerprint-fill': 64549,
    'ph-fingerprint-simple-fill': 64550,
    'ph-finn-the-human-fill': 64551,
    'ph-fire-fill': 64552,
    'ph-fire-simple-fill': 64553,
    'ph-first-aid-fill': 64554,
    'ph-first-aid-kit-fill': 64555,
    'ph-fish-fill': 64556,
    'ph-fish-simple-fill': 64557,
    'ph-flag-banner-fill': 64558,
    'ph-flag-checkered-fill': 64559,
    'ph-flag-fill': 64560,
    'ph-flame-fill': 64561,
    'ph-flashlight-fill': 64562,
    'ph-flask-fill': 64563,
    'ph-floppy-disk-back-fill': 64564,
    'ph-floppy-disk-fill': 64565,
    'ph-flow-arrow-fill': 64566,
    'ph-flower-fill': 64567,
    'ph-flower-lotus-fill': 64568,
    'ph-flying-saucer-fill': 64569,
    'ph-folder-dotted-fill': 64570,
    'ph-folder-fill': 64571,
    'ph-folder-lock-fill': 64572,
    'ph-folder-minus-fill': 64573,
    'ph-folder-notch-fill': 64574,
    'ph-folder-notch-minus-fill': 64575,
    'ph-folder-notch-open-fill': 64576,
    'ph-folder-notch-plus-fill': 64577,
    'ph-folder-open-fill': 64578,
    'ph-folder-plus-fill': 64579,
    'ph-folder-simple-dotted-fill': 64580,
    'ph-folder-simple-fill': 64581,
    'ph-folder-simple-lock-fill': 64582,
    'ph-folder-simple-minus-fill': 64583,
    'ph-folder-simple-plus-fill': 64584,
    'ph-folder-simple-star-fill': 64585,
    'ph-folder-simple-user-fill': 64586,
    'ph-folder-star-fill': 64587,
    'ph-folder-user-fill': 64588,
    'ph-folders-fill': 64589,
    'ph-football-fill': 64590,
    'ph-fork-knife-fill': 64591,
    'ph-frame-corners-fill': 64592,
    'ph-framer-logo-fill': 64593,
    'ph-function-fill': 64594,
    'ph-funnel-fill': 64595,
    'ph-funnel-simple-fill': 64596,
    'ph-game-controller-fill': 64597,
    'ph-gas-pump-fill': 64598,
    'ph-gauge-fill': 64599,
    'ph-gear-fill': 64600,
    'ph-gear-six-fill': 64601,
    'ph-gender-female-fill': 64602,
    'ph-gender-intersex-fill': 64603,
    'ph-gender-male-fill': 64604,
    'ph-gender-neuter-fill': 64605,
    'ph-gender-nonbinary-fill': 64606,
    'ph-gender-transgender-fill': 64607,
    'ph-ghost-fill': 64608,
    'ph-gif-fill': 64609,
    'ph-gift-fill': 64610,
    'ph-git-branch-fill': 64611,
    'ph-git-commit-fill': 64612,
    'ph-git-diff-fill': 64613,
    'ph-git-fork-fill': 64614,
    'ph-git-merge-fill': 64615,
    'ph-git-pull-request-fill': 64616,
    'ph-github-logo-fill': 64617,
    'ph-gitlab-logo-fill': 64618,
    'ph-gitlab-logo-simple-fill': 64619,
    'ph-globe-fill': 64620,
    'ph-globe-hemisphere-east-fill': 64621,
    'ph-globe-hemisphere-west-fill': 64622,
    'ph-globe-simple-fill': 64623,
    'ph-globe-stand-fill': 64624,
    'ph-google-chrome-logo-fill': 64625,
    'ph-google-logo-fill': 64626,
    'ph-google-photos-logo-fill': 64627,
    'ph-google-play-logo-fill': 64628,
    'ph-google-podcasts-logo-fill': 64629,
    'ph-gradient-fill': 64630,
    'ph-graduation-cap-fill': 64631,
    'ph-graph-fill': 64632,
    'ph-grid-four-fill': 64633,
    'ph-hamburger-fill': 64634,
    'ph-hand-eye-fill': 64635,
    'ph-hand-fill': 64636,
    'ph-hand-fist-fill': 64637,
    'ph-hand-grabbing-fill': 64638,
    'ph-hand-palm-fill': 64639,
    'ph-hand-pointing-fill': 64640,
    'ph-hand-soap-fill': 64641,
    'ph-hand-waving-fill': 64642,
    'ph-handbag-fill': 64643,
    'ph-handbag-simple-fill': 64644,
    'ph-hands-clapping-fill': 64645,
    'ph-handshake-fill': 64646,
    'ph-hard-drive-fill': 64647,
    'ph-hard-drives-fill': 64648,
    'ph-hash-fill': 64649,
    'ph-hash-straight-fill': 64650,
    'ph-headlights-fill': 64651,
    'ph-headphones-fill': 64652,
    'ph-headset-fill': 64653,
    'ph-heart-break-fill': 64654,
    'ph-heart-fill': 64655,
    'ph-heart-straight-break-fill': 64656,
    'ph-heart-straight-fill': 64657,
    'ph-heartbeat-fill': 64658,
    'ph-hexagon-fill': 64659,
    'ph-highlighter-circle-fill': 64660,
    'ph-horse-fill': 64661,
    'ph-hourglass-fill': 64662,
    'ph-hourglass-high-fill': 64663,
    'ph-hourglass-low-fill': 64664,
    'ph-hourglass-medium-fill': 64665,
    'ph-hourglass-simple-fill': 64666,
    'ph-hourglass-simple-high-fill': 64667,
    'ph-hourglass-simple-low-fill': 64668,
    'ph-hourglass-simple-medium-fill': 64669,
    'ph-house-fill': 64670,
    'ph-house-line-fill': 64671,
    'ph-house-simple-fill': 64672,
    'ph-identification-badge-fill': 64673,
    'ph-identification-card-fill': 64674,
    'ph-image-fill': 64675,
    'ph-image-square-fill': 64676,
    'ph-infinity-fill': 64677,
    'ph-info-fill': 64678,
    'ph-instagram-logo-fill': 64679,
    'ph-intersect-fill': 64680,
    'ph-jeep-fill': 64681,
    'ph-kanban-fill': 64682,
    'ph-key-fill': 64683,
    'ph-key-return-fill': 64684,
    'ph-keyboard-fill': 64685,
    'ph-keyhole-fill': 64686,
    'ph-knife-fill': 64687,
    'ph-ladder-fill': 64688,
    'ph-ladder-simple-fill': 64689,
    'ph-lamp-fill': 64690,
    'ph-laptop-fill': 64691,
    'ph-layout-fill': 64692,
    'ph-leaf-fill': 64693,
    'ph-lifebuoy-fill': 64694,
    'ph-lightbulb-filament-fill': 64695,
    'ph-lightbulb-fill': 64696,
    'ph-lightning-fill': 64697,
    'ph-lightning-slash-fill': 64698,
    'ph-line-segment-fill': 64699,
    'ph-line-segments-fill': 64700,
    'ph-link-break-fill': 64701,
    'ph-link-fill': 64702,
    'ph-link-simple-break-fill': 64703,
    'ph-link-simple-fill': 64704,
    'ph-link-simple-horizontal-break-fill': 64705,
    'ph-link-simple-horizontal-fill': 64706,
    'ph-linkedin-logo-fill': 64707,
    'ph-linux-logo-fill': 64708,
    'ph-list-bullets-fill': 64709,
    'ph-list-checks-fill': 64710,
    'ph-list-dashes-fill': 64711,
    'ph-list-fill': 64712,
    'ph-list-numbers-fill': 64713,
    'ph-list-plus-fill': 64714,
    'ph-lock-fill': 64715,
    'ph-lock-key-fill': 64716,
    'ph-lock-key-open-fill': 64717,
    'ph-lock-laminated-fill': 64718,
    'ph-lock-laminated-open-fill': 64719,
    'ph-lock-open-fill': 64720,
    'ph-lock-simple-fill': 64721,
    'ph-lock-simple-open-fill': 64722,
    'ph-magic-wand-fill': 64723,
    'ph-magnet-fill': 64724,
    'ph-magnet-straight-fill': 64725,
    'ph-magnifying-glass-fill': 64726,
    'ph-magnifying-glass-minus-fill': 64727,
    'ph-magnifying-glass-plus-fill': 64728,
    'ph-map-pin-fill': 64729,
    'ph-map-pin-line-fill': 64730,
    'ph-map-trifold-fill': 64731,
    'ph-marker-circle-fill': 64732,
    'ph-martini-fill': 64733,
    'ph-mask-happy-fill': 64734,
    'ph-mask-sad-fill': 64735,
    'ph-math-operations-fill': 64736,
    'ph-medal-fill': 64737,
    'ph-medium-logo-fill': 64738,
    'ph-megaphone-fill': 64739,
    'ph-megaphone-simple-fill': 64740,
    'ph-messenger-logo-fill': 64741,
    'ph-microphone-fill': 64742,
    'ph-microphone-slash-fill': 64743,
    'ph-microphone-stage-fill': 64744,
    'ph-microsoft-excel-logo-fill': 64745,
    'ph-microsoft-powerpoint-logo-fill': 64746,
    'ph-microsoft-teams-logo-fill': 64747,
    'ph-microsoft-word-logo-fill': 64748,
    'ph-minus-circle-fill': 64749,
    'ph-minus-fill': 64750,
    'ph-money-fill': 64751,
    'ph-monitor-fill': 64752,
    'ph-monitor-play-fill': 64753,
    'ph-moon-fill': 64754,
    'ph-moon-stars-fill': 64755,
    'ph-mountains-fill': 64756,
    'ph-mouse-fill': 64757,
    'ph-mouse-simple-fill': 64758,
    'ph-music-note-fill': 64759,
    'ph-music-note-simple-fill': 64760,
    'ph-music-notes-fill': 64761,
    'ph-music-notes-plus-fill': 64762,
    'ph-music-notes-simple-fill': 64763,
    'ph-navigation-arrow-fill': 64764,
    'ph-needle-fill': 64765,
    'ph-newspaper-clipping-fill': 64766,
    'ph-newspaper-fill': 64767,
    'ph-note-blank-fill': 64768,
    'ph-note-fill': 64769,
    'ph-note-pencil-fill': 64770,
    'ph-notebook-fill': 64771,
    'ph-notepad-fill': 64772,
    'ph-notification-fill': 64773,
    'ph-number-circle-eight-fill': 64774,
    'ph-number-circle-five-fill': 64775,
    'ph-number-circle-four-fill': 64776,
    'ph-number-circle-nine-fill': 64777,
    'ph-number-circle-one-fill': 64778,
    'ph-number-circle-seven-fill': 64779,
    'ph-number-circle-six-fill': 64780,
    'ph-number-circle-three-fill': 64781,
    'ph-number-circle-two-fill': 64782,
    'ph-number-circle-zero-fill': 64783,
    'ph-number-eight-fill': 64784,
    'ph-number-five-fill': 64785,
    'ph-number-four-fill': 64786,
    'ph-number-nine-fill': 64787,
    'ph-number-one-fill': 64788,
    'ph-number-seven-fill': 64789,
    'ph-number-six-fill': 64790,
    'ph-number-square-eight-fill': 64791,
    'ph-number-square-five-fill': 64792,
    'ph-number-square-four-fill': 64793,
    'ph-number-square-nine-fill': 64794,
    'ph-number-square-one-fill': 64795,
    'ph-number-square-seven-fill': 64796,
    'ph-number-square-six-fill': 64797,
    'ph-number-square-three-fill': 64798,
    'ph-number-square-two-fill': 64799,
    'ph-number-square-zero-fill': 64800,
    'ph-number-three-fill': 64801,
    'ph-number-two-fill': 64802,
    'ph-number-zero-fill': 64803,
    'ph-nut-fill': 64804,
    'ph-ny-times-logo-fill': 64805,
    'ph-octagon-fill': 64806,
    'ph-option-fill': 64807,
    'ph-package-fill': 64808,
    'ph-paint-brush-broad-fill': 64809,
    'ph-paint-brush-fill': 64810,
    'ph-paint-brush-household-fill': 64811,
    'ph-paint-bucket-fill': 64812,
    'ph-paint-roller-fill': 64813,
    'ph-palette-fill': 64814,
    'ph-paper-plane-fill': 64815,
    'ph-paper-plane-right-fill': 64816,
    'ph-paper-plane-tilt-fill': 64817,
    'ph-paperclip-fill': 64818,
    'ph-paperclip-horizontal-fill': 64819,
    'ph-parachute-fill': 64820,
    'ph-password-fill': 64821,
    'ph-path-fill': 64822,
    'ph-pause-circle-fill': 64823,
    'ph-pause-fill': 64824,
    'ph-paw-print-fill': 64825,
    'ph-peace-fill': 64826,
    'ph-pen-fill': 64827,
    'ph-pen-nib-fill': 64828,
    'ph-pen-nib-straight-fill': 64829,
    'ph-pencil-circle-fill': 64830,
    'ph-pencil-fill': 64831,
    'ph-pencil-line-fill': 64832,
    'ph-pencil-simple-fill': 64833,
    'ph-pencil-simple-line-fill': 64834,
    'ph-percent-fill': 64835,
    'ph-person-fill': 64836,
    'ph-person-simple-fill': 64837,
    'ph-person-simple-run-fill': 64838,
    'ph-person-simple-walk-fill': 64839,
    'ph-perspective-fill': 64840,
    'ph-phone-call-fill': 64841,
    'ph-phone-disconnect-fill': 64842,
    'ph-phone-fill': 64843,
    'ph-phone-incoming-fill': 64844,
    'ph-phone-outgoing-fill': 64845,
    'ph-phone-slash-fill': 64846,
    'ph-phone-x-fill': 64847,
    'ph-phosphor-logo-fill': 64848,
    'ph-piano-keys-fill': 64849,
    'ph-picture-in-picture-fill': 64850,
    'ph-pill-fill': 64851,
    'ph-pinterest-logo-fill': 64852,
    'ph-pinwheel-fill': 64853,
    'ph-pizza-fill': 64854,
    'ph-placeholder-fill': 64855,
    'ph-planet-fill': 64856,
    'ph-play-circle-fill': 64857,
    'ph-play-fill': 64858,
    'ph-playlist-fill': 64859,
    'ph-plug-fill': 64860,
    'ph-plugs-connected-fill': 64861,
    'ph-plugs-fill': 64862,
    'ph-plus-circle-fill': 64863,
    'ph-plus-fill': 64864,
    'ph-plus-minus-fill': 64865,
    'ph-poker-chip-fill': 64866,
    'ph-police-car-fill': 64867,
    'ph-polygon-fill': 64868,
    'ph-popcorn-fill': 64869,
    'ph-power-fill': 64870,
    'ph-prescription-fill': 64871,
    'ph-presentation-chart-fill': 64872,
    'ph-presentation-fill': 64873,
    'ph-printer-fill': 64874,
    'ph-prohibit-fill': 64875,
    'ph-prohibit-inset-fill': 64876,
    'ph-projector-screen-chart-fill': 64877,
    'ph-projector-screen-fill': 64878,
    'ph-push-pin-fill': 64879,
    'ph-push-pin-simple-fill': 64880,
    'ph-push-pin-simple-slash-fill': 64881,
    'ph-push-pin-slash-fill': 64882,
    'ph-puzzle-piece-fill': 64883,
    'ph-qr-code-fill': 64884,
    'ph-question-fill': 64885,
    'ph-queue-fill': 64886,
    'ph-quotes-fill': 64887,
    'ph-radical-fill': 64888,
    'ph-radio-button-fill': 64889,
    'ph-radio-fill': 64890,
    'ph-rainbow-cloud-fill': 64891,
    'ph-rainbow-fill': 64892,
    'ph-receipt-fill': 64893,
    'ph-record-fill': 64894,
    'ph-rectangle-fill': 64895,
    'ph-recycle-fill': 64896,
    'ph-reddit-logo-fill': 64897,
    'ph-repeat-fill': 64898,
    'ph-repeat-once-fill': 64899,
    'ph-rewind-circle-fill': 64900,
    'ph-rewind-fill': 64901,
    'ph-robot-fill': 64902,
    'ph-rocket-fill': 64903,
    'ph-rocket-launch-fill': 64904,
    'ph-rows-fill': 64905,
    'ph-rss-fill': 64906,
    'ph-rss-simple-fill': 64907,
    'ph-rug-fill': 64908,
    'ph-ruler-fill': 64909,
    'ph-scales-fill': 64910,
    'ph-scan-fill': 64911,
    'ph-scissors-fill': 64912,
    'ph-screencast-fill': 64913,
    'ph-scribble-loop-fill': 64914,
    'ph-scroll-fill': 64915,
    'ph-selection-all-fill': 64916,
    'ph-selection-background-fill': 64917,
    'ph-selection-fill': 64918,
    'ph-selection-foreground-fill': 64919,
    'ph-selection-inverse-fill': 64920,
    'ph-selection-plus-fill': 64921,
    'ph-selection-slash-fill': 64922,
    'ph-share-fill': 64923,
    'ph-share-network-fill': 64924,
    'ph-shield-check-fill': 64925,
    'ph-shield-checkered-fill': 64926,
    'ph-shield-chevron-fill': 64927,
    'ph-shield-fill': 64928,
    'ph-shield-plus-fill': 64929,
    'ph-shield-slash-fill': 64930,
    'ph-shield-star-fill': 64931,
    'ph-shield-warning-fill': 64932,
    'ph-shopping-bag-fill': 64933,
    'ph-shopping-bag-open-fill': 64934,
    'ph-shopping-cart-fill': 64935,
    'ph-shopping-cart-simple-fill': 64936,
    'ph-shower-fill': 64937,
    'ph-shuffle-angular-fill': 64938,
    'ph-shuffle-fill': 64939,
    'ph-shuffle-simple-fill': 64940,
    'ph-sidebar-fill': 64941,
    'ph-sidebar-simple-fill': 64942,
    'ph-sign-in-fill': 64943,
    'ph-sign-out-fill': 64944,
    'ph-signpost-fill': 64945,
    'ph-sim-card-fill': 64946,
    'ph-sketch-logo-fill': 64947,
    'ph-skip-back-circle-fill': 64948,
    'ph-skip-back-fill': 64949,
    'ph-skip-forward-circle-fill': 64950,
    'ph-skip-forward-fill': 64951,
    'ph-skull-fill': 64952,
    'ph-slack-logo-fill': 64953,
    'ph-sliders-fill': 64954,
    'ph-sliders-horizontal-fill': 64955,
    'ph-smiley-blank-fill': 64956,
    'ph-smiley-fill': 64957,
    'ph-smiley-meh-fill': 64958,
    'ph-smiley-nervous-fill': 64959,
    'ph-smiley-sad-fill': 64960,
    'ph-smiley-sticker-fill': 64961,
    'ph-smiley-wink-fill': 64962,
    'ph-smiley-x-eyes-fill': 64963,
    'ph-snapchat-logo-fill': 64964,
    'ph-snowflake-fill': 64965,
    'ph-soccer-ball-fill': 64966,
    'ph-sort-ascending-fill': 64967,
    'ph-sort-descending-fill': 64968,
    'ph-spade-fill': 64969,
    'ph-sparkle-fill': 64970,
    'ph-speaker-high-fill': 64971,
    'ph-speaker-low-fill': 64972,
    'ph-speaker-none-fill': 64973,
    'ph-speaker-simple-high-fill': 64974,
    'ph-speaker-simple-low-fill': 64975,
    'ph-speaker-simple-none-fill': 64976,
    'ph-speaker-simple-slash-fill': 64977,
    'ph-speaker-simple-x-fill': 64978,
    'ph-speaker-slash-fill': 64979,
    'ph-speaker-x-fill': 64980,
    'ph-spinner-fill': 64981,
    'ph-spinner-gap-fill': 64982,
    'ph-spiral-fill': 64983,
    'ph-spotify-logo-fill': 64984,
    'ph-square-fill': 64985,
    'ph-square-half-bottom-fill': 64986,
    'ph-square-half-fill': 64987,
    'ph-square-logo-fill': 64988,
    'ph-squares-four-fill': 64989,
    'ph-stack-fill': 64990,
    'ph-stack-overflow-logo-fill': 64991,
    'ph-stack-simple-fill': 64992,
    'ph-stamp-fill': 64993,
    'ph-star-fill': 64994,
    'ph-star-four-fill': 64995,
    'ph-star-half-fill': 64996,
    'ph-sticker-fill': 64997,
    'ph-stop-circle-fill': 64998,
    'ph-stop-fill': 64999,
    'ph-storefront-fill': 65000,
    'ph-strategy-fill': 65001,
    'ph-stripe-logo-fill': 65002,
    'ph-student-fill': 65003,
    'ph-suitcase-fill': 65004,
    'ph-suitcase-simple-fill': 65005,
    'ph-sun-dim-fill': 65006,
    'ph-sun-fill': 65007,
    'ph-sun-horizon-fill': 65008,
    'ph-sunglasses-fill': 65009,
    'ph-swap-fill': 65010,
    'ph-swatches-fill': 65011,
    'ph-sword-fill': 65012,
    'ph-syringe-fill': 65013,
    'ph-t-shirt-fill': 65014,
    'ph-table-fill': 65015,
    'ph-tabs-fill': 65016,
    'ph-tag-chevron-fill': 65017,
    'ph-tag-fill': 65018,
    'ph-tag-simple-fill': 65019,
    'ph-target-fill': 65020,
    'ph-taxi-fill': 65021,
    'ph-telegram-logo-fill': 65022,
    'ph-television-fill': 65023,
    'ph-television-simple-fill': 65024,
    'ph-tennis-ball-fill': 65025,
    'ph-terminal-fill': 65026,
    'ph-terminal-window-fill': 65027,
    'ph-test-tube-fill': 65028,
    'ph-text-aa-fill': 65029,
    'ph-text-align-center-fill': 65030,
    'ph-text-align-justify-fill': 65031,
    'ph-text-align-left-fill': 65032,
    'ph-text-align-right-fill': 65033,
    'ph-text-bolder-fill': 65034,
    'ph-text-h-fill': 65035,
    'ph-text-h-five-fill': 65036,
    'ph-text-h-four-fill': 65037,
    'ph-text-h-one-fill': 65038,
    'ph-text-h-six-fill': 65039,
    'ph-text-h-three-fill': 65040,
    'ph-text-h-two-fill': 65041,
    'ph-text-indent-fill': 65042,
    'ph-text-italic-fill': 65043,
    'ph-text-outdent-fill': 65044,
    'ph-text-strikethrough-fill': 65045,
    'ph-text-t-fill': 65046,
    'ph-text-underline-fill': 65047,
    'ph-textbox-fill': 65048,
    'ph-thermometer-cold-fill': 65049,
    'ph-thermometer-fill': 65050,
    'ph-thermometer-hot-fill': 65051,
    'ph-thermometer-simple-fill': 65052,
    'ph-thumbs-down-fill': 65053,
    'ph-thumbs-up-fill': 65054,
    'ph-ticket-fill': 65055,
    'ph-tiktok-logo-fill': 65056,
    'ph-timer-fill': 65057,
    'ph-toggle-left-fill': 65058,
    'ph-toggle-right-fill': 65059,
    'ph-toilet-fill': 65060,
    'ph-toilet-paper-fill': 65061,
    'ph-tote-fill': 65062,
    'ph-tote-simple-fill': 65063,
    'ph-trademark-registered-fill': 65064,
    'ph-traffic-cone-fill': 65065,
    'ph-traffic-sign-fill': 65066,
    'ph-traffic-signal-fill': 65067,
    'ph-train-fill': 65068,
    'ph-train-regional-fill': 65069,
    'ph-train-simple-fill': 65070,
    'ph-translate-fill': 65071,
    'ph-trash-fill': 65072,
    'ph-trash-simple-fill': 65073,
    'ph-tray-fill': 65074,
    'ph-tree-evergreen-fill': 65075,
    'ph-tree-fill': 65076,
    'ph-tree-structure-fill': 65077,
    'ph-trend-down-fill': 65078,
    'ph-trend-up-fill': 65079,
    'ph-triangle-fill': 65080,
    'ph-trophy-fill': 65081,
    'ph-truck-fill': 65082,
    'ph-twitch-logo-fill': 65083,
    'ph-twitter-logo-fill': 65084,
    'ph-umbrella-fill': 65085,
    'ph-umbrella-simple-fill': 65086,
    'ph-upload-fill': 65087,
    'ph-upload-simple-fill': 65088,
    'ph-user-circle-fill': 65089,
    'ph-user-circle-gear-fill': 65090,
    'ph-user-circle-minus-fill': 65091,
    'ph-user-circle-plus-fill': 65092,
    'ph-user-fill': 65093,
    'ph-user-focus-fill': 65094,
    'ph-user-gear-fill': 65095,
    'ph-user-list-fill': 65096,
    'ph-user-minus-fill': 65097,
    'ph-user-plus-fill': 65098,
    'ph-user-rectangle-fill': 65099,
    'ph-user-square-fill': 65100,
    'ph-user-switch-fill': 65101,
    'ph-users-fill': 65102,
    'ph-users-four-fill': 65103,
    'ph-users-three-fill': 65104,
    'ph-vault-fill': 65105,
    'ph-vibrate-fill': 65106,
    'ph-video-camera-fill': 65107,
    'ph-video-camera-slash-fill': 65108,
    'ph-vignette-fill': 65109,
    'ph-voicemail-fill': 65110,
    'ph-volleyball-fill': 65111,
    'ph-wall-fill': 65112,
    'ph-wallet-fill': 65113,
    'ph-warning-circle-fill': 65114,
    'ph-warning-fill': 65115,
    'ph-warning-octagon-fill': 65116,
    'ph-watch-fill': 65117,
    'ph-wave-sawtooth-fill': 65118,
    'ph-wave-sine-fill': 65119,
    'ph-wave-square-fill': 65120,
    'ph-wave-triangle-fill': 65121,
    'ph-waves-fill': 65122,
    'ph-webcam-fill': 65123,
    'ph-whatsapp-logo-fill': 65124,
    'ph-wheelchair-fill': 65125,
    'ph-wifi-high-fill': 65126,
    'ph-wifi-low-fill': 65127,
    'ph-wifi-medium-fill': 65128,
    'ph-wifi-none-fill': 65129,
    'ph-wifi-slash-fill': 65130,
    'ph-wifi-x-fill': 65131,
    'ph-wind-fill': 65132,
    'ph-windows-logo-fill': 65133,
    'ph-wine-fill': 65134,
    'ph-wrench-fill': 65135,
    'ph-x-circle-fill': 65136,
    'ph-x-fill': 65137,
    'ph-x-square-fill': 65138,
    'ph-yin-yang-fill': 65139,
    'ph-youtube-logo-fill': 65140,
}

export { phosphoricons }
