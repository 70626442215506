import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Company } from '../models/company.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    private apiUrl = `${environment.apiUrl}/companies`;

    constructor(private http: HttpClient) { }

    // Cria uma nova empresa
    createCompany(companyData: FormData): Observable<Company> {
        return this.http.post<Company>(this.apiUrl, companyData);
    }

    // Obtém todas as empresas
    getAllCompanies(): Observable<Company[]> {
        return this.http.get<Company[]>(this.apiUrl);
    }

    // Obtém uma empresa pelo ID
    getCompanyById(id: string): Observable<Company> {
        return this.http.get<Company>(`${this.apiUrl}/${id}`);
    }

    // Obtém uma empresa pelo domínio
    getCompanyByDomain(domain: string): Observable<Company> {
        return this.http.get<Company>(`${this.apiUrl}/domain/${domain}`);
    }

    // Atualiza uma empresa pelo ID
    updateCompany(id: string, companyData: FormData): Observable<Company> {
        return this.http.put<Company>(`${this.apiUrl}/${id}`, companyData);
    }

    // Deleta uma empresa pelo ID
    deleteCompany(id: string): Observable<void> {
        return this.http.delete<void>(`${this.apiUrl}/${id}`);
    }
}
