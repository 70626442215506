import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map, of, throwError } from 'rxjs';
import { User } from '../models/user.model';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private currentUserSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
    public currentUser: Observable<User | null> = this.currentUserSubject.asObservable();
    private isUserLoaded = false; // Variável para rastrear se o usuário já foi carregado

    constructor(private http: HttpClient, private router: Router) { }

    updateCurrentUser(user: User | null): void {
        this.currentUserSubject.next(user);
        this.isUserLoaded = !!user; // Atualiza o status do carregamento do usuário
    }

    register(userPayload: any): Observable<any> {
        console.log('User payload before sending to API:', userPayload);
        return this.http.post(`${environment.apiUrl}/users/register`, userPayload);
    }

    login(credentials: { login: string; password: string }): Observable<any> {
        return this.http.post<User>(`${environment.apiUrl}/users/login`, credentials, { withCredentials: true }).pipe(
            map(user => {
                this.updateCurrentUser(user);
                return user;
            }),
            catchError(error => {
                console.error('Login failed:', error);
                return throwError(() => new Error('Failed to login, please check credentials'));
            })
        );
    }

    logout(): void {
        this.http.get(`${environment.apiUrl}/users/logout`, { withCredentials: true }).subscribe({
            next: () => {
                this.updateCurrentUser(null);
                this.router.navigate(['/auth/login']);
            },
            error: error => console.error('Logout failed:', error)
        });
    }

    verifySession(): Observable<boolean> {
        return this.http.get<{ isAuthenticated: boolean }>(`${environment.apiUrl}/users/verify-session`, { withCredentials: true }).pipe(
            map(response => {
                if (response.isAuthenticated && !this.isUserLoaded) {
                    this.loadCurrentUser(); // Carrega o usuário apenas se ainda não foi carregado
                } else if (!response.isAuthenticated) {
                    this.updateCurrentUser(null);
                }
                return response.isAuthenticated;
            }),
            catchError(() => of(false))
        );
    }

    isAuthenticated(): Observable<boolean> {
        return this.verifySession();
    }

    loadCurrentUser(): void {
        if (this.isUserLoaded) {
            return; // Evita carregar o usuário novamente se já foi carregado
        }
        this.http.get<User>(`${environment.apiUrl}/users/me`, { withCredentials: true }).subscribe({
            next: user => this.updateCurrentUser(user),
            error: error => {
                console.error('Error loading user data:', error);
                this.updateCurrentUser(null);
            }
        });
    }

    getAllUsers(): Observable<User[]> {
        return this.http.get<User[]>(`${environment.apiUrl}/users`, { withCredentials: true }).pipe(
            catchError(error => {
                console.error('Error fetching users:', error);
                return throwError(() => new Error('Failed to fetch users'));
            })
        );
    }
}
