<header id="page-topbar" (window:scroll)="windowScroll()">
    <div class="layout-width">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box horizontal-logo">
                    <a routerLink="/" class="logo logo-dark">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-dark.png" alt="" height="22">
                        </span>
                    </a>

                    <a routerLink="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="22">
                        </span>
                    </a>
                </div>

                <button type="button"
                    class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none"
                    id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>

                <!-- Comentado: Seção de Busca -->
                <!--
                <form class="app-search d-none d-md-inline-flex">
                    <div class="position-relative">
                        <input type="text" class="form-control border-0" placeholder="Pesquisar por qualquer coisa..."
                            autocomplete="off" id="search-options" value="" (keyup)="Search()">
                        <span class="ti ti-search search-widget-icon"></span>
                        <span class="ti ti-x search-widget-icon search-widget-icon-close d-none"
                            id="search-close-options" (click)="closeBtn()"></span>
                    </div>
                    <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
                        <ngx-simplebar style="max-height: 320px;">
                            <div class="dropdown-header">
                                <h6 class="text-overflow fs-sm text-muted mb-0 text-uppercase">Pesquisas Recentes</h6>
                            </div>

                            <div class="dropdown-item bg-transparent text-wrap">
                                <a routerLink="/" class="btn btn-subtle-secondary btn-sm btn-rounded me-1">como
                                    configurar <i class="ti ti-search ms-1"></i></a>
                                <a routerLink="/" class="btn btn-subtle-secondary btn-sm btn-rounded">botões <i
                                        class="ti ti-search ms-1"></i></a>
                            </div>
                            <div class="dropdown-header mt-2">
                                <h6 class="text-overflow fs-sm text-muted mb-1 text-uppercase">Páginas</h6>
                            </div>

                            <a href="javascript:void(0);" class="dropdown-item notify-item">
                                <i class="ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>
                                <span>Painel de Análises</span>
                            </a>

                            <a href="javascript:void(0);" class="dropdown-item notify-item">
                                <i class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
                                <span>Central de Ajuda</span>
                            </a>

                            <a href="javascript:void(0);" class="dropdown-item notify-item">
                                <i class="ri-user-settings-line align-middle fs-18 text-muted me-2"></i>
                                <span>Configurações da Minha Conta</span>
                            </a>

                            <div class="dropdown-header mt-2">
                                <h6 class="text-overflow fs-sm text-muted mb-2 text-uppercase">Membros</h6>
                            </div>

                            <div class="notification-list">
                                <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                                    <div class="d-flex">
                                        <img src="assets/images/users/avatar-2.jpg"
                                            class="me-3 rounded-circle avatar-xs flex-shrink-0" alt="foto-usuario">
                                        <div class="flex-grow-1">
                                            <h6 class="fs-md m-0">Angela Bernier</h6>
                                            <span class="fs-sm mb-0 text-muted">Gerente</span>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                                    <div class="d-flex">
                                        <img src="assets/images/users/avatar-3.jpg"
                                            class="me-3 rounded-circle avatar-xs flex-shrink-0" alt="foto-usuario">
                                        <div class="flex-grow-1">
                                            <h6 class="fs-md m-0">David Grasso</h6>
                                            <span class="fs-sm mb-0 text-muted">Designer Web</span>
                                        </div>
                                    </div>
                                </a>
                                <a href="javascript:void(0);" class="dropdown-item notify-item py-2">
                                    <div class="d-flex">
                                        <img src="assets/images/users/avatar-5.jpg"
                                            class="me-3 rounded-circle avatar-xs" alt="foto-usuario">
                                        <div class="flex-grow-1">
                                            <h6 class="fs-md m-0">Mike Bunch</h6>
                                            <span class="fs-sm mb-0 text-muted">Desenvolvedor React</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </ngx-simplebar>

                        <div class="text-center pt-3 pb-1">
                            <a href="javascript:void(0);" class="btn btn-primary btn-sm">Ver Todos os Resultados <i
                                    class="ri-arrow-right-line ms-1"></i></a>
                        </div>
                    </div>
                </form>
                -->

            </div>

            <div class="d-flex align-items-center">

                <div dropdown class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button dropdownToggle type="button"
                        class="btn btn-icon btn-topbar btn-ghost-light rounded-circle user-name-text">
                        <i class='ti ti-category-2 fs-3xl'></i>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
                        <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fw-semibold fs-base"> Navegar por Apps </h6>
                                </div>
                                <div class="col-auto">
                                    <a href="javascript:void(0);" class="btn btn-sm btn-subtle-info"> Ver Todos os Apps
                                        <i class="ri-arrow-right-s-line align-middle"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="p-2">
                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/github.png" alt="Github">
                                        <span>GitHub</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                                        <span>Bitbucket</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/dribbble.png" alt="dribbble">
                                        <span>Dribbble</span>
                                    </a>
                                </div>
                            </div>

                            <div class="row g-0">
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/dropbox.png" alt="dropbox">
                                        <span>Dropbox</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                                        <span>Mail Chimp</span>
                                    </a>
                                </div>
                                <div class="col">
                                    <a class="dropdown-icon-item" href="javascript:void(0);">
                                        <img src="assets/images/brands/slack.png" alt="slack">
                                        <span>Slack</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div dropdown class="dropdown ms-1 topbar-head-dropdown header-item">
                    <button dropdownToggle type="button"
                        class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                        @if(flagvalue == undefined){
                        <img id="header-lang-img" src="{{valueset}}" alt="Header Language" height="20" class="rounded">
                        }@else{
                        <img id="header-lang-img" src="{{flagvalue}}" alt="Header Language" height="20" class="rounded">
                        }
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-end">
                        @for (item of listLang; track $index) {
                        <a href="javascript:void(0);" class="dropdown-item notify-item language py-2"
                            (click)="setLanguage(item.text, item.lang, item.flag)">
                            <img src="{{item.flag}}" alt="imagem-usuario" class="me-2 rounded" height="18">
                            <span class="align-middle">{{item.text}}</span>
                        </a>
                        }
                    </div>
                </div>

                <!-- Comentado: Seção do Carrinho de Compras -->
                <!--
                <div dropdown [insideClick]="true" class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button dropdownToggle type="button"
                        class="btn btn-icon btn-topbar btn-ghost-light rounded-circle user-name-text"
                        id="page-header-cart-dropdown">
                        <i class='ti ti-shopping-cart fs-3xl'></i>
                        <span
                            class="position-absolute topbar-badge cartitem-badge fs-3xs translate-middle badge rounded-pill bg-info">{{cartData?.length}}</span>
                    </button>
                    <div *bsDropdownMenu class="dropdown-menu dropdown-menu-xl dropdown-menu-end p-0 product-list">
                        <div class="p-3 border-bottom">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h6 class="m-0 fs-lg fw-semibold"> Meu Carrinho <span
                                            class="badge bg-secondary fs-sm cartitem-badge ms-1">{{cartData?.length}}</span>
                                    </h6>
                                </div>
                                <div class="col-auto">
                                    <a href="javascript:void(0);">Ver Todos</a>
                                </div>
                            </div>
                        </div>
                        <ngx-simplebar data-simplebar-track="light" style="max-height: 300px;">
                            <div class="p-3">
                                @if(cartData?.length == 0){
                                <div class="text-center empty-cart" id="empty-cart">
                                    <div class="avatar-md mx-auto my-3">
                                        <div class="avatar-title bg-info-subtle text-info fs-2 rounded-circle">
                                            <i class='bx bx-cart'></i>
                                        </div>
                                    </div>
                                    <h6 class="mb-3">Seu Carrinho Está Vazio!</h6>
                                    <a href="javascript:void(0);" class="btn btn-success w-md mb-3">Comprar Agora</a>
                                </div>
                                }
                                @for (data of cartData; track $index) {
                                <div class="d-block dropdown-item product text-wrap p-2">
                                    <div class="d-flex">
                                        <div class="avatar-sm me-3 flex-shrink-0">
                                            <div class="avatar-title bg-light rounded">
                                                <img src="{{data.img}}" class="avatar-xs" alt="foto-usuario">
                                            </div>
                                        </div>
                                        <div class="flex-grow-1">
                                            <p class="mb-1 fs-sm text-muted">{{data.category}}</p>
                                            <h6 class="mt-0 mb-3 fs-md">
                                                <a href="javascript:void(0);" class="text-reset">{{data.title}}</a>
                                            </h6>
                                            <div class="text-muted fw-medium d-none">R$<span
                                                    class="product-price">{{data.price}}</span></div>
                                            <div class="input-step">
                                                <button type="button" class="minus"
                                                    (click)="increment(data.qty,$index,'0')">–</button>
                                                <input type="number" class="product-quantity" [(ngModel)]="data.qty"
                                                    min="0" max="100" readonly>
                                                <button type="button" class="plus"
                                                    (click)="increment(data.qty,$index,'1')">+</button>
                                            </div>
                                        </div>
                                        <div class="ps-2 d-flex flex-column justify-content-between align-items-end">
                                            <button type="button"
                                                class="btn btn-icon btn-sm btn-ghost-primary remove-cart-btn"
                                                (click)="removeCart($index)"><i
                                                    class="ri-close-fill fs-lg"></i></button>
                                            <h6 class="mb-0">R$ <span class="product-line-price">{{data.total}}</span>
                                            </h6>
                                        </div>
                                    </div>
                                </div>}
                                @if(cartData?.length != 0){
                                <div id="count-table">
                                    <table class="table table-borderless mb-0  fw-semibold">
                                        <tbody>
                                            <tr>
                                                <td>Sub Total :</td>
                                                <td class="text-end cart-subtotal">R${{subtotal}}</td>
                                            </tr>
                                            <tr>
                                                <td>Desconto <span class="text-muted">(VIXON30)</span>:</td>
                                                <td class="text-end cart-discount">- R${{discount}}</td>
                                            </tr>
                                            <tr>
                                                <td>Taxa de Entrega :</td>
                                                <td class="text-end cart-shipping">R${{shippingRate}}</td>
                                            </tr>
                                            <tr>
                                                <td>Imposto Estimado (12.5%) : </td>
                                                <td class="text-end cart-tax">R${{tax}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                }

                            </div>
                        </ngx-simplebar>
                        @if(cartData?.length != 0){
                        <div class="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border"
                            id="checkout-elem">
                            <div class="d-flex justify-content-between align-items-center pb-3">
                                <h6 class="m-0 text-muted">Total:</h6>
                                <div class="px-2">
                                    <h6 class="m-0 cart-total">R${{totalsum}}</h6>
                                </div>
                            </div>

                            <a href="javascript:void(0);" class="btn btn-info text-center w-100">
                                Finalizar Compra
                            </a>
                        </div>
                        }
                    </div>
                </div>
                -->

                <div class="ms-1 header-item d-none d-sm-flex">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-light rounded-circle user-name-text"
                        data-toggle="fullscreen" (click)="fullscreen()">
                        <i class='ti ti-arrows-maximize fs-3xl'></i>
                    </button>
                </div>

                <div dropdown class="dropdown topbar-head-dropdown ms-1 header-item">
                    <button dropdownToggle type="button"
                        class="btn btn-icon btn-topbar btn-ghost-light rounded-circle user-name-text mode-layout"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ti ti-sun align-middle fs-3xl"></i>
                    </button>
                    <div *bsDropdownMenu class="dropdown-menu p-2 dropdown-menu-end" id="light-dark-mode">
                        <a href="javascript:void(0);" class="dropdown-item" (click)="changeMode('light')"><i
                                class="bi bi-sun align-middle me-2"></i> Padrão (modo claro)</a>
                        <a href="javascript:void(0);" class="dropdown-item" (click)="changeMode('dark')"><i
                                class="bi bi-moon align-middle me-2"></i> Escuro</a>
                        <a href="javascript:void(0);" class="dropdown-item" (click)="changeMode('auto')"><i
                                class="bi bi-moon-stars align-middle me-2"></i> Automático (padrão do sistema)</a>
                    </div>
                </div>

                <div dropdown [insideClick]="true" class="dropdown topbar-head-dropdown ms-1 header-item"
                    id="notificationDropdown">
                    <button dropdownToggle type="button"
                        class="btn btn-icon btn-topbar btn-ghost-light rounded-circle user-name-text"
                        id="page-header-notifications-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                        aria-haspopup="true" aria-expanded="false">
                        <i class='ti ti-bell-ringing fs-3xl'></i>
                        <span
                            class="position-absolute topbar-badge fs-3xs translate-middle badge rounded-pill bg-danger"><span
                                class="notification-badge">{{totalNotify}}</span><span class="visually-hidden">mensagens
                                não lidas</span></span>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 "
                        aria-labelledby="page-header-notifications-dropdown">

                        <div class="dropdown-head rounded-top">
                            <div class="p-3 border-bottom border-bottom-dashed">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h6 class="mb-0 fs-lg fw-semibold"> Notificações <span
                                                class="badge bg-danger-subtle text-danger fs-sm notification-badge">
                                                {{totalNotify}}</span></h6>
                                        <p class="fs-md text-muted mt-1 mb-0">Você tem <span
                                                class="fw-semibold notification-unread">{{newNotify}}</span> mensagens
                                            não lidas</p>
                                    </div>
                                    <div dropdown [container]="'body'" class="col-auto dropdown">
                                        <a dropdownToggle href="javascript:void(0);" data-bs-toggle="dropdown"
                                            class="link-secondary fs-md"><i class="bi bi-three-dots-vertical"></i></a>
                                        <ul *dropdownMenu class="dropdown-menu">
                                            <li><a class="dropdown-item" href="javascript:void(0);">Limpar Tudo</a></li>
                                            <li><a class="dropdown-item" href="javascript:void(0);">Marcar todas como
                                                    lidas</a></li>
                                            <li><a class="dropdown-item" href="javascript:void(0);">Arquivar Tudo</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="py-2 ps-2" id="notificationItemsTabContent">
                            <ngx-simplebar style="max-height: 300px;" class="pe-2">
                                @for (data of notificationList; track $index) {
                                <div>
                                    @if(data.items.length > 0){
                                    <h6 class="text-overflow text-muted fs-sm my-2 text-uppercase notification-title">
                                        {{data.title}}</h6>}
                                    @for (item of data.items; track $index) {
                                    <div class="text-reset notification-item d-block dropdown-item position-relative"
                                        [ngClass]="data.title == 'Novo'?'unread-message':''">
                                        <div class="d-flex">

                                            @if(item.avatar){
                                            <div class="position-relative me-3 flex-shrink-0">
                                                <img src="{{item.avatar}}" class="rounded-circle avatar-xs"
                                                    alt="foto-usuario">
                                                <span
                                                    class="active-badge position-absolute start-100 translate-middle p-1 bg-success rounded-circle">
                                                    <span class="visually-hidden">{{item.activeBadge}}</span>
                                                </span>
                                            </div>
                                            }@else {
                                            <div class="avatar-xs me-3 flex-shrink-0">
                                                <span class="avatar-title {{item.background}} rounded-circle fs-lg">
                                                    <i class='{{item.icon}}'></i>
                                                </span>
                                            </div>
                                            }
                                            <div class="flex-grow-1">
                                                <a href="javascript:void(0);" class="stretched-link">
                                                    @if(item.name){
                                                    <a href="javascript:void(0);" class="stretched-link">
                                                        <h6 class="mt-0 mb-1 fs-md fw-semibold">{{item.name}}</h6>
                                                    </a>}@else{
                                                    <h6 class="mt-0 fs-md mb-2 lh-base">{{item.text}}</h6>
                                                    }
                                                </a>
                                                @if(item.name){
                                                <div class="fs-sm text-muted">
                                                    <p class="mb-1">{{item.text}}</p>
                                                </div>
                                                }
                                                <p class="mb-0 fs-xs fw-medium text-uppercase text-muted">
                                                    <span><i class="ti ti-clock-hour-4"></i> {{item.timestamp}}</span>
                                                </p>
                                            </div>
                                            <div class="px-2 fs-base">
                                                <div class="form-check notification-check">
                                                    <input class="form-check-input" type="checkbox" value="{{item.id}}"
                                                        [(ngModel)]="item.state"
                                                        (change)="onCheckboxChange($event,$index)"
                                                        id="{{item.checkboxId}}">
                                                    <label class="form-check-label" for="{{item.checkboxId}}"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                                }
                            </ngx-simplebar>
                            <div class="notification-actions" id="notification-actions" style="display: none;">
                                <div class="d-flex text-muted justify-content-center align-items-center">
                                    Selecionar <div id="select-content" class="text-body fw-semibold px-1">
                                        {{checkedValGet.length}}</div> Resultado <button type="button"
                                        class="btn btn-link link-danger p-0 ms-2"
                                        (click)="removeNotificationModal.show()">Remover</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div dropdown class="dropdown ms-sm-3 topbar-head-dropdown dropdown-hover-end header-item topbar-user">
                    <button dropdownToggle type="button" class="btn shadow-none btn-icon"
                        id="page-header-user-dropdown">
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                                alt="Avatar do Cabeçalho">
                        </span>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-end">
                        <h6 class="dropdown-header">Bem-vinda, Alexandra!</h6>
                        <h6 class="dropdown-header">Bem-vindo, {{ userData?.contacts[0]?.value || 'Usuário' }}!</h6>
                        <a class="dropdown-item fs-sm" routerLink="/pages/profile"><i
                                class="bi bi-person-circle text-muted align-middle me-1"></i> <span
                                class="align-middle"> Perfil</span></a>
                        <a class="dropdown-item fs-sm" routerLink="/apps/calendar"><i
                                class="bi bi-cart4 text-muted align-middle me-1"></i> <span class="align-middle">
                                Acompanhar Pedido</span></a>
                        <a class="dropdown-item fs-sm" routerLink="/apps/chat"><i
                                class="bi bi-box-seam text-muted align-middle me-1"></i> <span class="align-middle">
                                Chat</span></a>
                        <a class="dropdown-item fs-sm" routerLink="/apps/to-do"><span
                                class="badge bg-success-subtle text-success float-end ms-2">Novo</span><i
                                class="bi bi-cassette text-muted align-middle me-1"></i> <span class="align-middle">
                                Tarefas</span></a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item fs-sm" routerLink="/pages/profile-settings"><i
                                class="bi bi-gear text-muted align-middle me-1"></i> <span class="align-middle">
                                Configurações</span></a>
                        <a class="dropdown-item fs-sm" routerLink="/auth/logout"><i
                                class="bi bi-box-arrow-right text-muted align-middle me-1"></i> <span
                                class="align-middle" data-key="t-logout"> Sair</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
<div class="wrapper"></div>

<!-- removeNotificationModal -->
<div bsModal #removeNotificationModal="bs-modal" id="removeNotificationModal" class="modal fade zoomIn" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" (click)="removeNotificationModal.hide()"></button>
            </div>
            <div class="modal-body p-md-5">
                <div class="text-center">
                    <div class="text-danger">
                        <i class="bi bi-trash display-4"></i>
                    </div>
                    <div class="mt-4 fs-base">
                        <h4 class="mb-1">Você tem certeza?</h4>
                        <p class="text-muted mx-4 mb-0">Você tem certeza que deseja remover esta notificação?</p>
                    </div>
                </div>
                <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button type="button" class="btn w-sm btn-light"
                        (click)="removeNotificationModal.hide()">Fechar</button>
                    <button type="button" class="btn w-sm btn-danger" id="delete-notification"
                        (click)="notificationDelete()">Sim, Remover!</button>
                </div>
            </div>

        </div>
    </div>
</div>

<!-- removeCartModal -->
<div bsModal #removeCartModal="bs-modal" id="removeCartModal" class="modal fade zoomIn" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    id="close-cartmodal"></button>
            </div>
            <div class="modal-body p-md-5">
                <div class="text-center">
                    <div class="text-danger">
                        <i class="bi bi-trash display-5"></i>
                    </div>
                    <div class="mt-4">
                        <h4>Você tem certeza?</h4>
                        <p class="text-muted mx-4 mb-0">Você tem certeza que deseja remover este produto?</p>
                    </div>
                </div>
                <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button type="button" class="btn w-sm btn-light" (click)="removeCartModal.hide()">Fechar</button>
                    <button type="button" class="btn w-sm btn-danger" id="remove-cartproduct"
                        (click)="confirmDelete()">Sim, Remover!</button>
                </div>
            </div>
        </div>
    </div>
</div>