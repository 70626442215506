var bootstrapicons = {
    "123": 63103,
    "alarm-fill": 61697,
    "alarm": 61698,
    "align-bottom": 61699,
    "align-center": 61700,
    "align-end": 61701,
    "align-middle": 61702,
    "align-start": 61703,
    "align-top": 61704,
    "alt": 61705,
    "app-indicator": 61706,
    "app": 61707,
    "archive-fill": 61708,
    "archive": 61709,
    "arrow-90deg-down": 61710,
    "arrow-90deg-left": 61711,
    "arrow-90deg-right": 61712,
    "arrow-90deg-up": 61713,
    "arrow-bar-down": 61714,
    "arrow-bar-left": 61715,
    "arrow-bar-right": 61716,
    "arrow-bar-up": 61717,
    "arrow-clockwise": 61718,
    "arrow-counterclockwise": 61719,
    "arrow-down-circle-fill": 61720,
    "arrow-down-circle": 61721,
    "arrow-down-left-circle-fill": 61722,
    "arrow-down-left-circle": 61723,
    "arrow-down-left-square-fill": 61724,
    "arrow-down-left-square": 61725,
    "arrow-down-left": 61726,
    "arrow-down-right-circle-fill": 61727,
    "arrow-down-right-circle": 61728,
    "arrow-down-right-square-fill": 61729,
    "arrow-down-right-square": 61730,
    "arrow-down-right": 61731,
    "arrow-down-short": 61732,
    "arrow-down-square-fill": 61733,
    "arrow-down-square": 61734,
    "arrow-down-up": 61735,
    "arrow-down": 61736,
    "arrow-left-circle-fill": 61737,
    "arrow-left-circle": 61738,
    "arrow-left-right": 61739,
    "arrow-left-short": 61740,
    "arrow-left-square-fill": 61741,
    "arrow-left-square": 61742,
    "arrow-left": 61743,
    "arrow-repeat": 61744,
    "arrow-return-left": 61745,
    "arrow-return-right": 61746,
    "arrow-right-circle-fill": 61747,
    "arrow-right-circle": 61748,
    "arrow-right-short": 61749,
    "arrow-right-square-fill": 61750,
    "arrow-right-square": 61751,
    "arrow-right": 61752,
    "arrow-up-circle-fill": 61753,
    "arrow-up-circle": 61754,
    "arrow-up-left-circle-fill": 61755,
    "arrow-up-left-circle": 61756,
    "arrow-up-left-square-fill": 61757,
    "arrow-up-left-square": 61758,
    "arrow-up-left": 61759,
    "arrow-up-right-circle-fill": 61760,
    "arrow-up-right-circle": 61761,
    "arrow-up-right-square-fill": 61762,
    "arrow-up-right-square": 61763,
    "arrow-up-right": 61764,
    "arrow-up-short": 61765,
    "arrow-up-square-fill": 61766,
    "arrow-up-square": 61767,
    "arrow-up": 61768,
    "arrows-angle-contract": 61769,
    "arrows-angle-expand": 61770,
    "arrows-collapse": 61771,
    "arrows-expand": 61772,
    "arrows-fullscreen": 61773,
    "arrows-move": 61774,
    "aspect-ratio-fill": 61775,
    "aspect-ratio": 61776,
    "asterisk": 61777,
    "at": 61778,
    "award-fill": 61779,
    "award": 61780,
    "back": 61781,
    "backspace-fill": 61782,
    "backspace-reverse-fill": 61783,
    "backspace-reverse": 61784,
    "backspace": 61785,
    "badge-3d-fill": 61786,
    "badge-3d": 61787,
    "badge-4k-fill": 61788,
    "badge-4k": 61789,
    "badge-8k-fill": 61790,
    "badge-8k": 61791,
    "badge-ad-fill": 61792,
    "badge-ad": 61793,
    "badge-ar-fill": 61794,
    "badge-ar": 61795,
    "badge-cc-fill": 61796,
    "badge-cc": 61797,
    "badge-hd-fill": 61798,
    "badge-hd": 61799,
    "badge-tm-fill": 61800,
    "badge-tm": 61801,
    "badge-vo-fill": 61802,
    "badge-vo": 61803,
    "badge-vr-fill": 61804,
    "badge-vr": 61805,
    "badge-wc-fill": 61806,
    "badge-wc": 61807,
    "bag-check-fill": 61808,
    "bag-check": 61809,
    "bag-dash-fill": 61810,
    "bag-dash": 61811,
    "bag-fill": 61812,
    "bag-plus-fill": 61813,
    "bag-plus": 61814,
    "bag-x-fill": 61815,
    "bag-x": 61816,
    "bag": 61817,
    "bar-chart-fill": 61818,
    "bar-chart-line-fill": 61819,
    "bar-chart-line": 61820,
    "bar-chart-steps": 61821,
    "bar-chart": 61822,
    "basket-fill": 61823,
    "basket": 61824,
    "basket2-fill": 61825,
    "basket2": 61826,
    "basket3-fill": 61827,
    "basket3": 61828,
    "battery-charging": 61829,
    "battery-full": 61830,
    "battery-half": 61831,
    "battery": 61832,
    "bell-fill": 61833,
    "bell": 61834,
    "bezier": 61835,
    "bezier2": 61836,
    "bicycle": 61837,
    "binoculars-fill": 61838,
    "binoculars": 61839,
    "blockquote-left": 61840,
    "blockquote-right": 61841,
    "book-fill": 61842,
    "book-half": 61843,
    "book": 61844,
    "bookmark-check-fill": 61845,
    "bookmark-check": 61846,
    "bookmark-dash-fill": 61847,
    "bookmark-dash": 61848,
    "bookmark-fill": 61849,
    "bookmark-heart-fill": 61850,
    "bookmark-heart": 61851,
    "bookmark-plus-fill": 61852,
    "bookmark-plus": 61853,
    "bookmark-star-fill": 61854,
    "bookmark-star": 61855,
    "bookmark-x-fill": 61856,
    "bookmark-x": 61857,
    "bookmark": 61858,
    "bookmarks-fill": 61859,
    "bookmarks": 61860,
    "bookshelf": 61861,
    "bootstrap-fill": 61862,
    "bootstrap-reboot": 61863,
    "bootstrap": 61864,
    "border-all": 61865,
    "border-bottom": 61866,
    "border-center": 61867,
    "border-inner": 61868,
    "border-left": 61869,
    "border-middle": 61870,
    "border-outer": 61871,
    "border-right": 61872,
    "border-style": 61873,
    "border-top": 61874,
    "border-width": 61875,
    "border": 61876,
    "bounding-box-circles": 61877,
    "bounding-box": 61878,
    "box-arrow-down-left": 61879,
    "box-arrow-down-right": 61880,
    "box-arrow-down": 61881,
    "box-arrow-in-down-left": 61882,
    "box-arrow-in-down-right": 61883,
    "box-arrow-in-down": 61884,
    "box-arrow-in-left": 61885,
    "box-arrow-in-right": 61886,
    "box-arrow-in-up-left": 61887,
    "box-arrow-in-up-right": 61888,
    "box-arrow-in-up": 61889,
    "box-arrow-left": 61890,
    "box-arrow-right": 61891,
    "box-arrow-up-left": 61892,
    "box-arrow-up-right": 61893,
    "box-arrow-up": 61894,
    "box-seam": 61895,
    "box": 61896,
    "braces": 61897,
    "bricks": 61898,
    "briefcase-fill": 61899,
    "briefcase": 61900,
    "brightness-alt-high-fill": 61901,
    "brightness-alt-high": 61902,
    "brightness-alt-low-fill": 61903,
    "brightness-alt-low": 61904,
    "brightness-high-fill": 61905,
    "brightness-high": 61906,
    "brightness-low-fill": 61907,
    "brightness-low": 61908,
    "broadcast-pin": 61909,
    "broadcast": 61910,
    "brush-fill": 61911,
    "brush": 61912,
    "bucket-fill": 61913,
    "bucket": 61914,
    "bug-fill": 61915,
    "bug": 61916,
    "building": 61917,
    "bullseye": 61918,
    "calculator-fill": 61919,
    "calculator": 61920,
    "calendar-check-fill": 61921,
    "calendar-check": 61922,
    "calendar-date-fill": 61923,
    "calendar-date": 61924,
    "calendar-day-fill": 61925,
    "calendar-day": 61926,
    "calendar-event-fill": 61927,
    "calendar-event": 61928,
    "calendar-fill": 61929,
    "calendar-minus-fill": 61930,
    "calendar-minus": 61931,
    "calendar-month-fill": 61932,
    "calendar-month": 61933,
    "calendar-plus-fill": 61934,
    "calendar-plus": 61935,
    "calendar-range-fill": 61936,
    "calendar-range": 61937,
    "calendar-week-fill": 61938,
    "calendar-week": 61939,
    "calendar-x-fill": 61940,
    "calendar-x": 61941,
    "calendar": 61942,
    "calendar2-check-fill": 61943,
    "calendar2-check": 61944,
    "calendar2-date-fill": 61945,
    "calendar2-date": 61946,
    "calendar2-day-fill": 61947,
    "calendar2-day": 61948,
    "calendar2-event-fill": 61949,
    "calendar2-event": 61950,
    "calendar2-fill": 61951,
    "calendar2-minus-fill": 61952,
    "calendar2-minus": 61953,
    "calendar2-month-fill": 61954,
    "calendar2-month": 61955,
    "calendar2-plus-fill": 61956,
    "calendar2-plus": 61957,
    "calendar2-range-fill": 61958,
    "calendar2-range": 61959,
    "calendar2-week-fill": 61960,
    "calendar2-week": 61961,
    "calendar2-x-fill": 61962,
    "calendar2-x": 61963,
    "calendar2": 61964,
    "calendar3-event-fill": 61965,
    "calendar3-event": 61966,
    "calendar3-fill": 61967,
    "calendar3-range-fill": 61968,
    "calendar3-range": 61969,
    "calendar3-week-fill": 61970,
    "calendar3-week": 61971,
    "calendar3": 61972,
    "calendar4-event": 61973,
    "calendar4-range": 61974,
    "calendar4-week": 61975,
    "calendar4": 61976,
    "camera-fill": 61977,
    "camera-reels-fill": 61978,
    "camera-reels": 61979,
    "camera-video-fill": 61980,
    "camera-video-off-fill": 61981,
    "camera-video-off": 61982,
    "camera-video": 61983,
    "camera": 61984,
    "camera2": 61985,
    "capslock-fill": 61986,
    "capslock": 61987,
    "card-checklist": 61988,
    "card-heading": 61989,
    "card-image": 61990,
    "card-list": 61991,
    "card-text": 61992,
    "caret-down-fill": 61993,
    "caret-down-square-fill": 61994,
    "caret-down-square": 61995,
    "caret-down": 61996,
    "caret-left-fill": 61997,
    "caret-left-square-fill": 61998,
    "caret-left-square": 61999,
    "caret-left": 62000,
    "caret-right-fill": 62001,
    "caret-right-square-fill": 62002,
    "caret-right-square": 62003,
    "caret-right": 62004,
    "caret-up-fill": 62005,
    "caret-up-square-fill": 62006,
    "caret-up-square": 62007,
    "caret-up": 62008,
    "cart-check-fill": 62009,
    "cart-check": 62010,
    "cart-dash-fill": 62011,
    "cart-dash": 62012,
    "cart-fill": 62013,
    "cart-plus-fill": 62014,
    "cart-plus": 62015,
    "cart-x-fill": 62016,
    "cart-x": 62017,
    "cart": 62018,
    "cart2": 62019,
    "cart3": 62020,
    "cart4": 62021,
    "cash-stack": 62022,
    "cash": 62023,
    "cast": 62024,
    "chat-dots-fill": 62025,
    "chat-dots": 62026,
    "chat-fill": 62027,
    "chat-left-dots-fill": 62028,
    "chat-left-dots": 62029,
    "chat-left-fill": 62030,
    "chat-left-quote-fill": 62031,
    "chat-left-quote": 62032,
    "chat-left-text-fill": 62033,
    "chat-left-text": 62034,
    "chat-left": 62035,
    "chat-quote-fill": 62036,
    "chat-quote": 62037,
    "chat-right-dots-fill": 62038,
    "chat-right-dots": 62039,
    "chat-right-fill": 62040,
    "chat-right-quote-fill": 62041,
    "chat-right-quote": 62042,
    "chat-right-text-fill": 62043,
    "chat-right-text": 62044,
    "chat-right": 62045,
    "chat-square-dots-fill": 62046,
    "chat-square-dots": 62047,
    "chat-square-fill": 62048,
    "chat-square-quote-fill": 62049,
    "chat-square-quote": 62050,
    "chat-square-text-fill": 62051,
    "chat-square-text": 62052,
    "chat-square": 62053,
    "chat-text-fill": 62054,
    "chat-text": 62055,
    "chat": 62056,
    "check-all": 62057,
    "check-circle-fill": 62058,
    "check-circle": 62059,
    "check-square-fill": 62060,
    "check-square": 62061,
    "check": 62062,
    "check2-all": 62063,
    "check2-circle": 62064,
    "check2-square": 62065,
    "check2": 62066,
    "chevron-bar-contract": 62067,
    "chevron-bar-down": 62068,
    "chevron-bar-expand": 62069,
    "chevron-bar-left": 62070,
    "chevron-bar-right": 62071,
    "chevron-bar-up": 62072,
    "chevron-compact-down": 62073,
    "chevron-compact-left": 62074,
    "chevron-compact-right": 62075,
    "chevron-compact-up": 62076,
    "chevron-contract": 62077,
    "chevron-double-down": 62078,
    "chevron-double-left": 62079,
    "chevron-double-right": 62080,
    "chevron-double-up": 62081,
    "chevron-down": 62082,
    "chevron-expand": 62083,
    "chevron-left": 62084,
    "chevron-right": 62085,
    "chevron-up": 62086,
    "circle-fill": 62087,
    "circle-half": 62088,
    "circle-square": 62089,
    "circle": 62090,
    "clipboard-check": 62091,
    "clipboard-data": 62092,
    "clipboard-minus": 62093,
    "clipboard-plus": 62094,
    "clipboard-x": 62095,
    "clipboard": 62096,
    "clock-fill": 62097,
    "clock-history": 62098,
    "clock": 62099,
    "cloud-arrow-down-fill": 62100,
    "cloud-arrow-down": 62101,
    "cloud-arrow-up-fill": 62102,
    "cloud-arrow-up": 62103,
    "cloud-check-fill": 62104,
    "cloud-check": 62105,
    "cloud-download-fill": 62106,
    "cloud-download": 62107,
    "cloud-drizzle-fill": 62108,
    "cloud-drizzle": 62109,
    "cloud-fill": 62110,
    "cloud-fog-fill": 62111,
    "cloud-fog": 62112,
    "cloud-fog2-fill": 62113,
    "cloud-fog2": 62114,
    "cloud-hail-fill": 62115,
    "cloud-hail": 62116,
    "cloud-haze-1": 62117,
    "cloud-haze-fill": 62118,
    "cloud-haze": 62119,
    "cloud-haze2-fill": 62120,
    "cloud-lightning-fill": 62121,
    "cloud-lightning-rain-fill": 62122,
    "cloud-lightning-rain": 62123,
    "cloud-lightning": 62124,
    "cloud-minus-fill": 62125,
    "cloud-minus": 62126,
    "cloud-moon-fill": 62127,
    "cloud-moon": 62128,
    "cloud-plus-fill": 62129,
    "cloud-plus": 62130,
    "cloud-rain-fill": 62131,
    "cloud-rain-heavy-fill": 62132,
    "cloud-rain-heavy": 62133,
    "cloud-rain": 62134,
    "cloud-slash-fill": 62135,
    "cloud-slash": 62136,
    "cloud-sleet-fill": 62137,
    "cloud-sleet": 62138,
    "cloud-snow-fill": 62139,
    "cloud-snow": 62140,
    "cloud-sun-fill": 62141,
    "cloud-sun": 62142,
    "cloud-upload-fill": 62143,
    "cloud-upload": 62144,
    "cloud": 62145,
    "clouds-fill": 62146,
    "clouds": 62147,
    "cloudy-fill": 62148,
    "cloudy": 62149,
    "code-slash": 62150,
    "code-square": 62151,
    "code": 62152,
    "collection-fill": 62153,
    "collection-play-fill": 62154,
    "collection-play": 62155,
    "collection": 62156,
    "columns-gap": 62157,
    "columns": 62158,
    "command": 62159,
    "compass-fill": 62160,
    "compass": 62161,
    "cone-striped": 62162,
    "cone": 62163,
    "controller": 62164,
    "cpu-fill": 62165,
    "cpu": 62166,
    "credit-card-2-back-fill": 62167,
    "credit-card-2-back": 62168,
    "credit-card-2-front-fill": 62169,
    "credit-card-2-front": 62170,
    "credit-card-fill": 62171,
    "credit-card": 62172,
    "crop": 62173,
    "cup-fill": 62174,
    "cup-straw": 62175,
    "cup": 62176,
    "cursor-fill": 62177,
    "cursor-text": 62178,
    "cursor": 62179,
    "dash-circle-dotted": 62180,
    "dash-circle-fill": 62181,
    "dash-circle": 62182,
    "dash-square-dotted": 62183,
    "dash-square-fill": 62184,
    "dash-square": 62185,
    "dash": 62186,
    "diagram-2-fill": 62187,
    "diagram-2": 62188,
    "diagram-3-fill": 62189,
    "diagram-3": 62190,
    "diamond-fill": 62191,
    "diamond-half": 62192,
    "diamond": 62193,
    "dice-1-fill": 62194,
    "dice-1": 62195,
    "dice-2-fill": 62196,
    "dice-2": 62197,
    "dice-3-fill": 62198,
    "dice-3": 62199,
    "dice-4-fill": 62200,
    "dice-4": 62201,
    "dice-5-fill": 62202,
    "dice-5": 62203,
    "dice-6-fill": 62204,
    "dice-6": 62205,
    "disc-fill": 62206,
    "disc": 62207,
    "discord": 62208,
    "display-fill": 62209,
    "display": 62210,
    "distribute-horizontal": 62211,
    "distribute-vertical": 62212,
    "door-closed-fill": 62213,
    "door-closed": 62214,
    "door-open-fill": 62215,
    "door-open": 62216,
    "dot": 62217,
    "download": 62218,
    "droplet-fill": 62219,
    "droplet-half": 62220,
    "droplet": 62221,
    "earbuds": 62222,
    "easel-fill": 62223,
    "easel": 62224,
    "egg-fill": 62225,
    "egg-fried": 62226,
    "egg": 62227,
    "eject-fill": 62228,
    "eject": 62229,
    "emoji-angry-fill": 62230,
    "emoji-angry": 62231,
    "emoji-dizzy-fill": 62232,
    "emoji-dizzy": 62233,
    "emoji-expressionless-fill": 62234,
    "emoji-expressionless": 62235,
    "emoji-frown-fill": 62236,
    "emoji-frown": 62237,
    "emoji-heart-eyes-fill": 62238,
    "emoji-heart-eyes": 62239,
    "emoji-laughing-fill": 62240,
    "emoji-laughing": 62241,
    "emoji-neutral-fill": 62242,
    "emoji-neutral": 62243,
    "emoji-smile-fill": 62244,
    "emoji-smile-upside-down-fill": 62245,
    "emoji-smile-upside-down": 62246,
    "emoji-smile": 62247,
    "emoji-sunglasses-fill": 62248,
    "emoji-sunglasses": 62249,
    "emoji-wink-fill": 62250,
    "emoji-wink": 62251,
    "envelope-fill": 62252,
    "envelope-open-fill": 62253,
    "envelope-open": 62254,
    "envelope": 62255,
    "eraser-fill": 62256,
    "eraser": 62257,
    "exclamation-circle-fill": 62258,
    "exclamation-circle": 62259,
    "exclamation-diamond-fill": 62260,
    "exclamation-diamond": 62261,
    "exclamation-octagon-fill": 62262,
    "exclamation-octagon": 62263,
    "exclamation-square-fill": 62264,
    "exclamation-square": 62265,
    "exclamation-triangle-fill": 62266,
    "exclamation-triangle": 62267,
    "exclamation": 62268,
    "exclude": 62269,
    "eye-fill": 62270,
    "eye-slash-fill": 62271,
    "eye-slash": 62272,
    "eye": 62273,
    "eyedropper": 62274,
    "eyeglasses": 62275,
    "facebook": 62276,
    "file-arrow-down-fill": 62277,
    "file-arrow-down": 62278,
    "file-arrow-up-fill": 62279,
    "file-arrow-up": 62280,
    "file-bar-graph-fill": 62281,
    "file-bar-graph": 62282,
    "file-binary-fill": 62283,
    "file-binary": 62284,
    "file-break-fill": 62285,
    "file-break": 62286,
    "file-check-fill": 62287,
    "file-check": 62288,
    "file-code-fill": 62289,
    "file-code": 62290,
    "file-diff-fill": 62291,
    "file-diff": 62292,
    "file-earmark-arrow-down-fill": 62293,
    "file-earmark-arrow-down": 62294,
    "file-earmark-arrow-up-fill": 62295,
    "file-earmark-arrow-up": 62296,
    "file-earmark-bar-graph-fill": 62297,
    "file-earmark-bar-graph": 62298,
    "file-earmark-binary-fill": 62299,
    "file-earmark-binary": 62300,
    "file-earmark-break-fill": 62301,
    "file-earmark-break": 62302,
    "file-earmark-check-fill": 62303,
    "file-earmark-check": 62304,
    "file-earmark-code-fill": 62305,
    "file-earmark-code": 62306,
    "file-earmark-diff-fill": 62307,
    "file-earmark-diff": 62308,
    "file-earmark-easel-fill": 62309,
    "file-earmark-easel": 62310,
    "file-earmark-excel-fill": 62311,
    "file-earmark-excel": 62312,
    "file-earmark-fill": 62313,
    "file-earmark-font-fill": 62314,
    "file-earmark-font": 62315,
    "file-earmark-image-fill": 62316,
    "file-earmark-image": 62317,
    "file-earmark-lock-fill": 62318,
    "file-earmark-lock": 62319,
    "file-earmark-lock2-fill": 62320,
    "file-earmark-lock2": 62321,
    "file-earmark-medical-fill": 62322,
    "file-earmark-medical": 62323,
    "file-earmark-minus-fill": 62324,
    "file-earmark-minus": 62325,
    "file-earmark-music-fill": 62326,
    "file-earmark-music": 62327,
    "file-earmark-person-fill": 62328,
    "file-earmark-person": 62329,
    "file-earmark-play-fill": 62330,
    "file-earmark-play": 62331,
    "file-earmark-plus-fill": 62332,
    "file-earmark-plus": 62333,
    "file-earmark-post-fill": 62334,
    "file-earmark-post": 62335,
    "file-earmark-ppt-fill": 62336,
    "file-earmark-ppt": 62337,
    "file-earmark-richtext-fill": 62338,
    "file-earmark-richtext": 62339,
    "file-earmark-ruled-fill": 62340,
    "file-earmark-ruled": 62341,
    "file-earmark-slides-fill": 62342,
    "file-earmark-slides": 62343,
    "file-earmark-spreadsheet-fill": 62344,
    "file-earmark-spreadsheet": 62345,
    "file-earmark-text-fill": 62346,
    "file-earmark-text": 62347,
    "file-earmark-word-fill": 62348,
    "file-earmark-word": 62349,
    "file-earmark-x-fill": 62350,
    "file-earmark-x": 62351,
    "file-earmark-zip-fill": 62352,
    "file-earmark-zip": 62353,
    "file-earmark": 62354,
    "file-easel-fill": 62355,
    "file-easel": 62356,
    "file-excel-fill": 62357,
    "file-excel": 62358,
    "file-fill": 62359,
    "file-font-fill": 62360,
    "file-font": 62361,
    "file-image-fill": 62362,
    "file-image": 62363,
    "file-lock-fill": 62364,
    "file-lock": 62365,
    "file-lock2-fill": 62366,
    "file-lock2": 62367,
    "file-medical-fill": 62368,
    "file-medical": 62369,
    "file-minus-fill": 62370,
    "file-minus": 62371,
    "file-music-fill": 62372,
    "file-music": 62373,
    "file-person-fill": 62374,
    "file-person": 62375,
    "file-play-fill": 62376,
    "file-play": 62377,
    "file-plus-fill": 62378,
    "file-plus": 62379,
    "file-post-fill": 62380,
    "file-post": 62381,
    "file-ppt-fill": 62382,
    "file-ppt": 62383,
    "file-richtext-fill": 62384,
    "file-richtext": 62385,
    "file-ruled-fill": 62386,
    "file-ruled": 62387,
    "file-slides-fill": 62388,
    "file-slides": 62389,
    "file-spreadsheet-fill": 62390,
    "file-spreadsheet": 62391,
    "file-text-fill": 62392,
    "file-text": 62393,
    "file-word-fill": 62394,
    "file-word": 62395,
    "file-x-fill": 62396,
    "file-x": 62397,
    "file-zip-fill": 62398,
    "file-zip": 62399,
    "file": 62400,
    "files-alt": 62401,
    "files": 62402,
    "film": 62403,
    "filter-circle-fill": 62404,
    "filter-circle": 62405,
    "filter-left": 62406,
    "filter-right": 62407,
    "filter-square-fill": 62408,
    "filter-square": 62409,
    "filter": 62410,
    "flag-fill": 62411,
    "flag": 62412,
    "flower1": 62413,
    "flower2": 62414,
    "flower3": 62415,
    "folder-check": 62416,
    "folder-fill": 62417,
    "folder-minus": 62418,
    "folder-plus": 62419,
    "folder-symlink-fill": 62420,
    "folder-symlink": 62421,
    "folder-x": 62422,
    "folder": 62423,
    "folder2-open": 62424,
    "folder2": 62425,
    "fonts": 62426,
    "forward-fill": 62427,
    "forward": 62428,
    "front": 62429,
    "fullscreen-exit": 62430,
    "fullscreen": 62431,
    "funnel-fill": 62432,
    "funnel": 62433,
    "gear-fill": 62434,
    "gear-wide-connected": 62435,
    "gear-wide": 62436,
    "gear": 62437,
    "gem": 62438,
    "geo-alt-fill": 62439,
    "geo-alt": 62440,
    "geo-fill": 62441,
    "geo": 62442,
    "gift-fill": 62443,
    "gift": 62444,
    "github": 62445,
    "globe": 62446,
    "globe2": 62447,
    "google": 62448,
    "graph-down": 62449,
    "graph-up": 62450,
    "grid-1x2-fill": 62451,
    "grid-1x2": 62452,
    "grid-3x2-gap-fill": 62453,
    "grid-3x2-gap": 62454,
    "grid-3x2": 62455,
    "grid-3x3-gap-fill": 62456,
    "grid-3x3-gap": 62457,
    "grid-3x3": 62458,
    "grid-fill": 62459,
    "grid": 62460,
    "grip-horizontal": 62461,
    "grip-vertical": 62462,
    "hammer": 62463,
    "hand-index-fill": 62464,
    "hand-index-thumb-fill": 62465,
    "hand-index-thumb": 62466,
    "hand-index": 62467,
    "hand-thumbs-down-fill": 62468,
    "hand-thumbs-down": 62469,
    "hand-thumbs-up-fill": 62470,
    "hand-thumbs-up": 62471,
    "handbag-fill": 62472,
    "handbag": 62473,
    "hash": 62474,
    "hdd-fill": 62475,
    "hdd-network-fill": 62476,
    "hdd-network": 62477,
    "hdd-rack-fill": 62478,
    "hdd-rack": 62479,
    "hdd-stack-fill": 62480,
    "hdd-stack": 62481,
    "hdd": 62482,
    "headphones": 62483,
    "headset": 62484,
    "heart-fill": 62485,
    "heart-half": 62486,
    "heart": 62487,
    "heptagon-fill": 62488,
    "heptagon-half": 62489,
    "heptagon": 62490,
    "hexagon-fill": 62491,
    "hexagon-half": 62492,
    "hexagon": 62493,
    "hourglass-bottom": 62494,
    "hourglass-split": 62495,
    "hourglass-top": 62496,
    "hourglass": 62497,
    "house-door-fill": 62498,
    "house-door": 62499,
    "house-fill": 62500,
    "house": 62501,
    "hr": 62502,
    "hurricane": 62503,
    "image-alt": 62504,
    "image-fill": 62505,
    "image": 62506,
    "images": 62507,
    "inbox-fill": 62508,
    "inbox": 62509,
    "inboxes-fill": 62510,
    "inboxes": 62511,
    "info-circle-fill": 62512,
    "info-circle": 62513,
    "info-square-fill": 62514,
    "info-square": 62515,
    "info": 62516,
    "input-cursor-text": 62517,
    "input-cursor": 62518,
    "instagram": 62519,
    "intersect": 62520,
    "journal-album": 62521,
    "journal-arrow-down": 62522,
    "journal-arrow-up": 62523,
    "journal-bookmark-fill": 62524,
    "journal-bookmark": 62525,
    "journal-check": 62526,
    "journal-code": 62527,
    "journal-medical": 62528,
    "journal-minus": 62529,
    "journal-plus": 62530,
    "journal-richtext": 62531,
    "journal-text": 62532,
    "journal-x": 62533,
    "journal": 62534,
    "journals": 62535,
    "joystick": 62536,
    "justify-left": 62537,
    "justify-right": 62538,
    "justify": 62539,
    "kanban-fill": 62540,
    "kanban": 62541,
    "key-fill": 62542,
    "key": 62543,
    "keyboard-fill": 62544,
    "keyboard": 62545,
    "ladder": 62546,
    "lamp-fill": 62547,
    "lamp": 62548,
    "laptop-fill": 62549,
    "laptop": 62550,
    "layer-backward": 62551,
    "layer-forward": 62552,
    "layers-fill": 62553,
    "layers-half": 62554,
    "layers": 62555,
    "layout-sidebar-inset-reverse": 62556,
    "layout-sidebar-inset": 62557,
    "layout-sidebar-reverse": 62558,
    "layout-sidebar": 62559,
    "layout-split": 62560,
    "layout-text-sidebar-reverse": 62561,
    "layout-text-sidebar": 62562,
    "layout-text-window-reverse": 62563,
    "layout-text-window": 62564,
    "layout-three-columns": 62565,
    "layout-wtf": 62566,
    "life-preserver": 62567,
    "lightbulb-fill": 62568,
    "lightbulb-off-fill": 62569,
    "lightbulb-off": 62570,
    "lightbulb": 62571,
    "lightning-charge-fill": 62572,
    "lightning-charge": 62573,
    "lightning-fill": 62574,
    "lightning": 62575,
    "link-45deg": 62576,
    "link": 62577,
    "linkedin": 62578,
    "list-check": 62579,
    "list-nested": 62580,
    "list-ol": 62581,
    "list-stars": 62582,
    "list-task": 62583,
    "list-ul": 62584,
    "list": 62585,
    "lock-fill": 62586,
    "lock": 62587,
    "mailbox": 62588,
    "mailbox2": 62589,
    "map-fill": 62590,
    "map": 62591,
    "markdown-fill": 62592,
    "markdown": 62593,
    "mask": 62594,
    "megaphone-fill": 62595,
    "megaphone": 62596,
    "menu-app-fill": 62597,
    "menu-app": 62598,
    "menu-button-fill": 62599,
    "menu-button-wide-fill": 62600,
    "menu-button-wide": 62601,
    "menu-button": 62602,
    "menu-down": 62603,
    "menu-up": 62604,
    "mic-fill": 62605,
    "mic-mute-fill": 62606,
    "mic-mute": 62607,
    "mic": 62608,
    "minecart-loaded": 62609,
    "minecart": 62610,
    "moisture": 62611,
    "moon-fill": 62612,
    "moon-stars-fill": 62613,
    "moon-stars": 62614,
    "moon": 62615,
    "mouse-fill": 62616,
    "mouse": 62617,
    "mouse2-fill": 62618,
    "mouse2": 62619,
    "mouse3-fill": 62620,
    "mouse3": 62621,
    "music-note-beamed": 62622,
    "music-note-list": 62623,
    "music-note": 62624,
    "music-player-fill": 62625,
    "music-player": 62626,
    "newspaper": 62627,
    "node-minus-fill": 62628,
    "node-minus": 62629,
    "node-plus-fill": 62630,
    "node-plus": 62631,
    "nut-fill": 62632,
    "nut": 62633,
    "octagon-fill": 62634,
    "octagon-half": 62635,
    "octagon": 62636,
    "option": 62637,
    "outlet": 62638,
    "paint-bucket": 62639,
    "palette-fill": 62640,
    "palette": 62641,
    "palette2": 62642,
    "paperclip": 62643,
    "paragraph": 62644,
    "patch-check-fill": 62645,
    "patch-check": 62646,
    "patch-exclamation-fill": 62647,
    "patch-exclamation": 62648,
    "patch-minus-fill": 62649,
    "patch-minus": 62650,
    "patch-plus-fill": 62651,
    "patch-plus": 62652,
    "patch-question-fill": 62653,
    "patch-question": 62654,
    "pause-btn-fill": 62655,
    "pause-btn": 62656,
    "pause-circle-fill": 62657,
    "pause-circle": 62658,
    "pause-fill": 62659,
    "pause": 62660,
    "peace-fill": 62661,
    "peace": 62662,
    "pen-fill": 62663,
    "pen": 62664,
    "pencil-fill": 62665,
    "pencil-square": 62666,
    "pencil": 62667,
    "pentagon-fill": 62668,
    "pentagon-half": 62669,
    "pentagon": 62670,
    "people-fill": 62671,
    "people": 62672,
    "percent": 62673,
    "person-badge-fill": 62674,
    "person-badge": 62675,
    "person-bounding-box": 62676,
    "person-check-fill": 62677,
    "person-check": 62678,
    "person-circle": 62679,
    "person-dash-fill": 62680,
    "person-dash": 62681,
    "person-fill": 62682,
    "person-lines-fill": 62683,
    "person-plus-fill": 62684,
    "person-plus": 62685,
    "person-square": 62686,
    "person-x-fill": 62687,
    "person-x": 62688,
    "person": 62689,
    "phone-fill": 62690,
    "phone-landscape-fill": 62691,
    "phone-landscape": 62692,
    "phone-vibrate-fill": 62693,
    "phone-vibrate": 62694,
    "phone": 62695,
    "pie-chart-fill": 62696,
    "pie-chart": 62697,
    "pin-angle-fill": 62698,
    "pin-angle": 62699,
    "pin-fill": 62700,
    "pin": 62701,
    "pip-fill": 62702,
    "pip": 62703,
    "play-btn-fill": 62704,
    "play-btn": 62705,
    "play-circle-fill": 62706,
    "play-circle": 62707,
    "play-fill": 62708,
    "play": 62709,
    "plug-fill": 62710,
    "plug": 62711,
    "plus-circle-dotted": 62712,
    "plus-circle-fill": 62713,
    "plus-circle": 62714,
    "plus-square-dotted": 62715,
    "plus-square-fill": 62716,
    "plus-square": 62717,
    "plus": 62718,
    "power": 62719,
    "printer-fill": 62720,
    "printer": 62721,
    "puzzle-fill": 62722,
    "puzzle": 62723,
    "question-circle-fill": 62724,
    "question-circle": 62725,
    "question-diamond-fill": 62726,
    "question-diamond": 62727,
    "question-octagon-fill": 62728,
    "question-octagon": 62729,
    "question-square-fill": 62730,
    "question-square": 62731,
    "question": 62732,
    "rainbow": 62733,
    "receipt-cutoff": 62734,
    "receipt": 62735,
    "reception-0": 62736,
    "reception-1": 62737,
    "reception-2": 62738,
    "reception-3": 62739,
    "reception-4": 62740,
    "record-btn-fill": 62741,
    "record-btn": 62742,
    "record-circle-fill": 62743,
    "record-circle": 62744,
    "record-fill": 62745,
    "record": 62746,
    "record2-fill": 62747,
    "record2": 62748,
    "reply-all-fill": 62749,
    "reply-all": 62750,
    "reply-fill": 62751,
    "reply": 62752,
    "rss-fill": 62753,
    "rss": 62754,
    "rulers": 62755,
    "save-fill": 62756,
    "save": 62757,
    "save2-fill": 62758,
    "save2": 62759,
    "scissors": 62760,
    "screwdriver": 62761,
    "search": 62762,
    "segmented-nav": 62763,
    "server": 62764,
    "share-fill": 62765,
    "share": 62766,
    "shield-check": 62767,
    "shield-exclamation": 62768,
    "shield-fill-check": 62769,
    "shield-fill-exclamation": 62770,
    "shield-fill-minus": 62771,
    "shield-fill-plus": 62772,
    "shield-fill-x": 62773,
    "shield-fill": 62774,
    "shield-lock-fill": 62775,
    "shield-lock": 62776,
    "shield-minus": 62777,
    "shield-plus": 62778,
    "shield-shaded": 62779,
    "shield-slash-fill": 62780,
    "shield-slash": 62781,
    "shield-x": 62782,
    "shield": 62783,
    "shift-fill": 62784,
    "shift": 62785,
    "shop-window": 62786,
    "shop": 62787,
    "shuffle": 62788,
    "signpost-2-fill": 62789,
    "signpost-2": 62790,
    "signpost-fill": 62791,
    "signpost-split-fill": 62792,
    "signpost-split": 62793,
    "signpost": 62794,
    "sim-fill": 62795,
    "sim": 62796,
    "skip-backward-btn-fill": 62797,
    "skip-backward-btn": 62798,
    "skip-backward-circle-fill": 62799,
    "skip-backward-circle": 62800,
    "skip-backward-fill": 62801,
    "skip-backward": 62802,
    "skip-end-btn-fill": 62803,
    "skip-end-btn": 62804,
    "skip-end-circle-fill": 62805,
    "skip-end-circle": 62806,
    "skip-end-fill": 62807,
    "skip-end": 62808,
    "skip-forward-btn-fill": 62809,
    "skip-forward-btn": 62810,
    "skip-forward-circle-fill": 62811,
    "skip-forward-circle": 62812,
    "skip-forward-fill": 62813,
    "skip-forward": 62814,
    "skip-start-btn-fill": 62815,
    "skip-start-btn": 62816,
    "skip-start-circle-fill": 62817,
    "skip-start-circle": 62818,
    "skip-start-fill": 62819,
    "skip-start": 62820,
    "slack": 62821,
    "slash-circle-fill": 62822,
    "slash-circle": 62823,
    "slash-square-fill": 62824,
    "slash-square": 62825,
    "slash": 62826,
    "sliders": 62827,
    "smartwatch": 62828,
    "snow": 62829,
    "snow2": 62830,
    "snow3": 62831,
    "sort-alpha-down-alt": 62832,
    "sort-alpha-down": 62833,
    "sort-alpha-up-alt": 62834,
    "sort-alpha-up": 62835,
    "sort-down-alt": 62836,
    "sort-down": 62837,
    "sort-numeric-down-alt": 62838,
    "sort-numeric-down": 62839,
    "sort-numeric-up-alt": 62840,
    "sort-numeric-up": 62841,
    "sort-up-alt": 62842,
    "sort-up": 62843,
    "soundwave": 62844,
    "speaker-fill": 62845,
    "speaker": 62846,
    "speedometer": 62847,
    "speedometer2": 62848,
    "spellcheck": 62849,
    "square-fill": 62850,
    "square-half": 62851,
    "square": 62852,
    "stack": 62853,
    "star-fill": 62854,
    "star-half": 62855,
    "star": 62856,
    "stars": 62857,
    "stickies-fill": 62858,
    "stickies": 62859,
    "sticky-fill": 62860,
    "sticky": 62861,
    "stop-btn-fill": 62862,
    "stop-btn": 62863,
    "stop-circle-fill": 62864,
    "stop-circle": 62865,
    "stop-fill": 62866,
    "stop": 62867,
    "stoplights-fill": 62868,
    "stoplights": 62869,
    "stopwatch-fill": 62870,
    "stopwatch": 62871,
    "subtract": 62872,
    "suit-club-fill": 62873,
    "suit-club": 62874,
    "suit-diamond-fill": 62875,
    "suit-diamond": 62876,
    "suit-heart-fill": 62877,
    "suit-heart": 62878,
    "suit-spade-fill": 62879,
    "suit-spade": 62880,
    "sun-fill": 62881,
    "sun": 62882,
    "sunglasses": 62883,
    "sunrise-fill": 62884,
    "sunrise": 62885,
    "sunset-fill": 62886,
    "sunset": 62887,
    "symmetry-horizontal": 62888,
    "symmetry-vertical": 62889,
    "table": 62890,
    "tablet-fill": 62891,
    "tablet-landscape-fill": 62892,
    "tablet-landscape": 62893,
    "tablet": 62894,
    "tag-fill": 62895,
    "tag": 62896,
    "tags-fill": 62897,
    "tags": 62898,
    "telegram": 62899,
    "telephone-fill": 62900,
    "telephone-forward-fill": 62901,
    "telephone-forward": 62902,
    "telephone-inbound-fill": 62903,
    "telephone-inbound": 62904,
    "telephone-minus-fill": 62905,
    "telephone-minus": 62906,
    "telephone-outbound-fill": 62907,
    "telephone-outbound": 62908,
    "telephone-plus-fill": 62909,
    "telephone-plus": 62910,
    "telephone-x-fill": 62911,
    "telephone-x": 62912,
    "telephone": 62913,
    "terminal-fill": 62914,
    "terminal": 62915,
    "text-center": 62916,
    "text-indent-left": 62917,
    "text-indent-right": 62918,
    "text-left": 62919,
    "text-paragraph": 62920,
    "text-right": 62921,
    "textarea-resize": 62922,
    "textarea-t": 62923,
    "textarea": 62924,
    "thermometer-half": 62925,
    "thermometer-high": 62926,
    "thermometer-low": 62927,
    "thermometer-snow": 62928,
    "thermometer-sun": 62929,
    "thermometer": 62930,
    "three-dots-vertical": 62931,
    "three-dots": 62932,
    "toggle-off": 62933,
    "toggle-on": 62934,
    "toggle2-off": 62935,
    "toggle2-on": 62936,
    "toggles": 62937,
    "toggles2": 62938,
    "tools": 62939,
    "tornado": 62940,
    "trash-fill": 62941,
    "trash": 62942,
    "trash2-fill": 62943,
    "trash2": 62944,
    "tree-fill": 62945,
    "tree": 62946,
    "triangle-fill": 62947,
    "triangle-half": 62948,
    "triangle": 62949,
    "trophy-fill": 62950,
    "trophy": 62951,
    "tropical-storm": 62952,
    "truck-flatbed": 62953,
    "truck": 62954,
    "tsunami": 62955,
    "tv-fill": 62956,
    "tv": 62957,
    "twitch": 62958,
    "twitter": 62959,
    "type-bold": 62960,
    "type-h1": 62961,
    "type-h2": 62962,
    "type-h3": 62963,
    "type-italic": 62964,
    "type-strikethrough": 62965,
    "type-underline": 62966,
    "type": 62967,
    "ui-checks-grid": 62968,
    "ui-checks": 62969,
    "ui-radios-grid": 62970,
    "ui-radios": 62971,
    "umbrella-fill": 62972,
    "umbrella": 62973,
    "union": 62974,
    "unlock-fill": 62975,
    "unlock": 62976,
    "upc-scan": 62977,
    "upc": 62978,
    "upload": 62979,
    "vector-pen": 62980,
    "view-list": 62981,
    "view-stacked": 62982,
    "vinyl-fill": 62983,
    "vinyl": 62984,
    "voicemail": 62985,
    "volume-down-fill": 62986,
    "volume-down": 62987,
    "volume-mute-fill": 62988,
    "volume-mute": 62989,
    "volume-off-fill": 62990,
    "volume-off": 62991,
    "volume-up-fill": 62992,
    "volume-up": 62993,
    "vr": 62994,
    "wallet-fill": 62995,
    "wallet": 62996,
    "wallet2": 62997,
    "watch": 62998,
    "water": 62999,
    "whatsapp": 63000,
    "wifi-1": 63001,
    "wifi-2": 63002,
    "wifi-off": 63003,
    "wifi": 63004,
    "wind": 63005,
    "window-dock": 63006,
    "window-sidebar": 63007,
    "window": 63008,
    "wrench": 63009,
    "x-circle-fill": 63010,
    "x-circle": 63011,
    "x-diamond-fill": 63012,
    "x-diamond": 63013,
    "x-octagon-fill": 63014,
    "x-octagon": 63015,
    "x-square-fill": 63016,
    "x-square": 63017,
    "x": 63018,
    "youtube": 63019,
    "zoom-in": 63020,
    "zoom-out": 63021,
    "bank": 63022,
    "bank2": 63023,
    "bell-slash-fill": 63024,
    "bell-slash": 63025,
    "cash-coin": 63026,
    "check-lg": 63027,
    "coin": 63028,
    "currency-bitcoin": 63029,
    "currency-dollar": 63030,
    "currency-euro": 63031,
    "currency-exchange": 63032,
    "currency-pound": 63033,
    "currency-yen": 63034,
    "dash-lg": 63035,
    "exclamation-lg": 63036,
    "file-earmark-pdf-fill": 63037,
    "file-earmark-pdf": 63038,
    "file-pdf-fill": 63039,
    "file-pdf": 63040,
    "gender-ambiguous": 63041,
    "gender-female": 63042,
    "gender-male": 63043,
    "gender-trans": 63044,
    "headset-vr": 63045,
    "info-lg": 63046,
    "mastodon": 63047,
    "messenger": 63048,
    "piggy-bank-fill": 63049,
    "piggy-bank": 63050,
    "pin-map-fill": 63051,
    "pin-map": 63052,
    "plus-lg": 63053,
    "question-lg": 63054,
    "recycle": 63055,
    "reddit": 63056,
    "safe-fill": 63057,
    "safe2-fill": 63058,
    "safe2": 63059,
    "sd-card-fill": 63060,
    "sd-card": 63061,
    "skype": 63062,
    "slash-lg": 63063,
    "translate": 63064,
    "x-lg": 63065,
    "safe": 63066,
    "apple": 63067,
    "microsoft": 63069,
    "windows": 63070,
    "behance": 63068,
    "dribbble": 63071,
    "line": 63072,
    "medium": 63073,
    "paypal": 63074,
    "pinterest": 63075,
    "signal": 63076,
    "snapchat": 63077,
    "spotify": 63078,
    "stack-overflow": 63079,
    "strava": 63080,
    "wordpress": 63081,
    "vimeo": 63082,
    "activity": 63083,
    "easel2-fill": 63084,
    "easel2": 63085,
    "easel3-fill": 63086,
    "easel3": 63087,
    "fan": 63088,
    "fingerprint": 63089,
    "graph-down-arrow": 63090,
    "graph-up-arrow": 63091,
    "hypnotize": 63092,
    "magic": 63093,
    "person-rolodex": 63094,
    "person-video": 63095,
    "person-video2": 63096,
    "person-video3": 63097,
    "person-workspace": 63098,
    "radioactive": 63099,
    "webcam-fill": 63100,
    "webcam": 63101,
    "yin-yang": 63102,
    "bandaid-fill": 63104,
    "bandaid": 63105,
    "bluetooth": 63106,
    "body-text": 63107,
    "boombox": 63108,
    "boxes": 63109,
    "dpad-fill": 63110,
    "dpad": 63111,
    "ear-fill": 63112,
    "ear": 63113,
    "envelope-check-1": 63114,
    "envelope-check-fill": 63115,
    "envelope-check": 63116,
    "envelope-dash-1": 63117,
    "envelope-dash-fill": 63118,
    "envelope-dash": 63119,
    "envelope-exclamation-1": 63120,
    "envelope-exclamation-fill": 63121,
    "envelope-exclamation": 63122,
    "envelope-plus-fill": 63123,
    "envelope-plus": 63124,
    "envelope-slash-1": 63125,
    "envelope-slash-fill": 63126,
    "envelope-slash": 63127,
    "envelope-x-1": 63128,
    "envelope-x-fill": 63129,
    "envelope-x": 63130,
    "explicit-fill": 63131,
    "explicit": 63132,
    "git": 63133,
    "infinity": 63134,
    "list-columns-reverse": 63135,
    "list-columns": 63136,
    "meta": 63137,
    "mortorboard-fill": 63138,
    "mortorboard": 63139,
    "nintendo-switch": 63140,
    "pc-display-horizontal": 63141,
    "pc-display": 63142,
    "pc-horizontal": 63143,
    "pc": 63144,
    "playstation": 63145,
    "plus-slash-minus": 63146,
    "projector-fill": 63147,
    "projector": 63148,
    "qr-code-scan": 63149,
    "qr-code": 63150,
    "quora": 63151,
    "quote": 63152,
    "robot": 63153,
    "send-check-fill": 63154,
    "send-check": 63155,
    "send-dash-fill": 63156,
    "send-dash": 63157,
    "send-exclamation-1": 63158,
    "send-exclamation-fill": 63159,
    "send-exclamation": 63160,
    "send-fill": 63161,
    "send-plus-fill": 63162,
    "send-plus": 63163,
    "send-slash-fill": 63164,
    "send-slash": 63165,
    "send-x-fill": 63166,
    "send-x": 63167,
    "send": 63168,
    "steam": 63169,
    "terminal-dash-1": 63170,
    "terminal-dash": 63171,
    "terminal-plus": 63172,
    "terminal-split": 63173,
    "ticket-detailed-fill": 63174,
    "ticket-detailed": 63175,
    "ticket-fill": 63176,
    "ticket-perforated-fill": 63177,
    "ticket-perforated": 63178,
    "ticket": 63179,
    "tiktok": 63180,
    "window-dash": 63181,
    "window-desktop": 63182,
    "window-fullscreen": 63183,
    "window-plus": 63184,
    "window-split": 63185,
    "window-stack": 63186,
    "window-x": 63187,
    "xbox": 63188,
    "ethernet": 63189,
    "hdmi-fill": 63190,
    "hdmi": 63191,
    "usb-c-fill": 63192,
    "usb-c": 63193,
    "usb-fill": 63194,
    "usb-plug-fill": 63195,
    "usb-plug": 63196,
    "usb-symbol": 63197,
    "usb": 63198,
    "boombox-fill": 63199,
    "displayport-1": 63200,
    "displayport": 63201,
    "gpu-card": 63202,
    "memory": 63203,
    "modem-fill": 63204,
    "modem": 63205,
    "motherboard-fill": 63206,
    "motherboard": 63207,
    "optical-audio-fill": 63208,
    "optical-audio": 63209,
    "pci-card": 63210,
    "router-fill": 63211,
    "router": 63212,
    "ssd-fill": 63213,
    "ssd": 63214,
    "thunderbolt-fill": 63215,
    "thunderbolt": 63216,
    "usb-drive-fill": 63217,
    "usb-drive": 63218,
    "usb-micro-fill": 63219,
    "usb-micro": 63220,
    "usb-mini-fill": 63221,
    "usb-mini": 63222,
    "cloud-haze2": 63223,
    "device-hdd-fill": 63224,
    "device-hdd": 63225,
    "device-ssd-fill": 63226,
    "device-ssd": 63227,
    "displayport-fill": 63228,
    "mortarboard-fill": 63229,
    "mortarboard": 63230,
    "terminal-x": 63231,
    "arrow-through-heart-fill": 63232,
    "arrow-through-heart": 63233,
    "badge-sd-fill": 63234,
    "badge-sd": 63235,
    "bag-heart-fill": 63236,
    "bag-heart": 63237,
    "balloon-fill": 63238,
    "balloon-heart-fill": 63239,
    "balloon-heart": 63240,
    "balloon": 63241,
    "box2-fill": 63242,
    "box2-heart-fill": 63243,
    "box2-heart": 63244,
    "box2": 63245,
    "braces-asterisk": 63246,
    "calendar-heart-fill": 63247,
    "calendar-heart": 63248,
    "calendar2-heart-fill": 63249,
    "calendar2-heart": 63250,
    "chat-heart-fill": 63251,
    "chat-heart": 63252,
    "chat-left-heart-fill": 63253,
    "chat-left-heart": 63254,
    "chat-right-heart-fill": 63255,
    "chat-right-heart": 63256,
    "chat-square-heart-fill": 63257,
    "chat-square-heart": 63258,
    "clipboard-check-fill": 63259,
    "clipboard-data-fill": 63260,
    "clipboard-fill": 63261,
    "clipboard-heart-fill": 63262,
    "clipboard-heart": 63263,
    "clipboard-minus-fill": 63264,
    "clipboard-plus-fill": 63265,
    "clipboard-pulse": 63266,
    "clipboard-x-fill": 63267,
    "clipboard2-check-fill": 63268,
    "clipboard2-check": 63269,
    "clipboard2-data-fill": 63270,
    "clipboard2-data": 63271,
    "clipboard2-fill": 63272,
    "clipboard2-heart-fill": 63273,
    "clipboard2-heart": 63274,
    "clipboard2-minus-fill": 63275,
    "clipboard2-minus": 63276,
    "clipboard2-plus-fill": 63277,
    "clipboard2-plus": 63278,
    "clipboard2-pulse-fill": 63279,
    "clipboard2-pulse": 63280,
    "clipboard2-x-fill": 63281,
    "clipboard2-x": 63282,
    "clipboard2": 63283,
    "emoji-kiss-fill": 63284,
    "emoji-kiss": 63285,
    "envelope-heart-fill": 63286,
    "envelope-heart": 63287,
    "envelope-open-heart-fill": 63288,
    "envelope-open-heart": 63289,
    "envelope-paper-fill": 63290,
    "envelope-paper-heart-fill": 63291,
    "envelope-paper-heart": 63292,
    "envelope-paper": 63293,
    "filetype-aac": 63294,
    "filetype-ai": 63295,
    "filetype-bmp": 63296,
    "filetype-cs": 63297,
    "filetype-css": 63298,
    "filetype-csv": 63299,
    "filetype-doc": 63300,
    "filetype-docx": 63301,
    "filetype-exe": 63302,
    "filetype-gif": 63303,
    "filetype-heic": 63304,
    "filetype-html": 63305,
    "filetype-java": 63306,
    "filetype-jpg": 63307,
    "filetype-js": 63308,
    "filetype-jsx": 63309,
    "filetype-key": 63310,
    "filetype-m4p": 63311,
    "filetype-md": 63312,
    "filetype-mdx": 63313,
    "filetype-mov": 63314,
    "filetype-mp3": 63315,
    "filetype-mp4": 63316,
    "filetype-otf": 63317,
    "filetype-pdf": 63318,
    "filetype-php": 63319,
    "filetype-png": 63320,
    "filetype-ppt-1": 63321,
    "filetype-ppt": 63322,
    "filetype-psd": 63323,
    "filetype-py": 63324,
    "filetype-raw": 63325,
    "filetype-rb": 63326,
    "filetype-sass": 63327,
    "filetype-scss": 63328,
    "filetype-sh": 63329,
    "filetype-svg": 63330,
    "filetype-tiff": 63331,
    "filetype-tsx": 63332,
    "filetype-ttf": 63333,
    "filetype-txt": 63334,
    "filetype-wav": 63335,
    "filetype-woff": 63336,
    "filetype-xls-1": 63337,
    "filetype-xls": 63338,
    "filetype-xml": 63339,
    "filetype-yml": 63340,
    "heart-arrow": 63341,
    "heart-pulse-fill": 63342,
    "heart-pulse": 63343,
    "heartbreak-fill": 63344,
    "heartbreak": 63345,
    "hearts": 63346,
    "hospital-fill": 63347,
    "hospital": 63348,
    "house-heart-fill": 63349,
    "house-heart": 63350,
    "incognito": 63351,
    "magnet-fill": 63352,
    "magnet": 63353,
    "person-heart": 63354,
    "person-hearts": 63355,
    "phone-flip": 63356,
    "plugin": 63357,
    "postage-fill": 63358,
    "postage-heart-fill": 63359,
    "postage-heart": 63360,
    "postage": 63361,
    "postcard-fill": 63362,
    "postcard-heart-fill": 63363,
    "postcard-heart": 63364,
    "postcard": 63365,
    "search-heart-fill": 63366,
    "search-heart": 63367,
    "sliders2-vertical": 63368,
    "sliders2": 63369,
    "trash3-fill": 63370,
    "trash3": 63371,
    "valentine": 63372,
    "valentine2": 63373,
    "wrench-adjustable-circle-fill": 63374,
    "wrench-adjustable-circle": 63375,
    "wrench-adjustable": 63376,
    "filetype-json": 63377,
    "filetype-pptx": 63378,
    "filetype-xlsx": 63379,
    "1-circle-1": 63380,
    "1-circle-fill-1": 63381,
    "1-circle-fill": 63382,
    "1-circle": 63383,
    "1-square-fill": 63384,
    "1-square": 63385,
    "2-circle-1": 63386,
    "2-circle-fill-1": 63387,
    "2-circle-fill": 63388,
    "2-circle": 63389,
    "2-square-fill": 63390,
    "2-square": 63391,
    "3-circle-1": 63392,
    "3-circle-fill-1": 63393,
    "3-circle-fill": 63394,
    "3-circle": 63395,
    "3-square-fill": 63396,
    "3-square": 63397,
    "4-circle-1": 63398,
    "4-circle-fill-1": 63399,
    "4-circle-fill": 63400,
    "4-circle": 63401,
    "4-square-fill": 63402,
    "4-square": 63403,
    "5-circle-1": 63404,
    "5-circle-fill-1": 63405,
    "5-circle-fill": 63406,
    "5-circle": 63407,
    "5-square-fill": 63408,
    "5-square": 63409,
    "6-circle-1": 63410,
    "6-circle-fill-1": 63411,
    "6-circle-fill": 63412,
    "6-circle": 63413,
    "6-square-fill": 63414,
    "6-square": 63415,
    "7-circle-1": 63416,
    "7-circle-fill-1": 63417,
    "7-circle-fill": 63418,
    "7-circle": 63419,
    "7-square-fill": 63420,
    "7-square": 63421,
    "8-circle-1": 63422,
    "8-circle-fill-1": 63423,
    "8-circle-fill": 63424,
    "8-circle": 63425,
    "8-square-fill": 63426,
    "8-square": 63427,
    "9-circle-1": 63428,
    "9-circle-fill-1": 63429,
    "9-circle-fill": 63430,
    "9-circle": 63431,
    "9-square-fill": 63432,
    "9-square": 63433,
    "airplane-engines-fill": 63434,
    "airplane-engines": 63435,
    "airplane-fill": 63436,
    "airplane": 63437,
    "alexa": 63438,
    "alipay": 63439,
    "android": 63440,
    "android2": 63441,
    "box-fill": 63442,
    "box-seam-fill": 63443,
    "browser-chrome": 63444,
    "browser-edge": 63445,
    "browser-firefox": 63446,
    "browser-safari": 63447,
    "c-circle-1": 63448,
    "c-circle-fill-1": 63449,
    "c-circle-fill": 63450,
    "c-circle": 63451,
    "c-square-fill": 63452,
    "c-square": 63453,
    "capsule-pill": 63454,
    "capsule": 63455,
    "car-front-fill": 63456,
    "car-front": 63457,
    "cassette-fill": 63458,
    "cassette": 63459,
    "cc-circle-1": 63460,
    "cc-circle-fill-1": 63461,
    "cc-circle-fill": 63462,
    "cc-circle": 63463,
    "cc-square-fill": 63464,
    "cc-square": 63465,
    "cup-hot-fill": 63466,
    "cup-hot": 63467,
    "currency-rupee": 63468,
    "dropbox": 63469,
    "escape": 63470,
    "fast-forward-btn-fill": 63471,
    "fast-forward-btn": 63472,
    "fast-forward-circle-fill": 63473,
    "fast-forward-circle": 63474,
    "fast-forward-fill": 63475,
    "fast-forward": 63476,
    "filetype-sql": 63477,
    "fire": 63478,
    "google-play": 63479,
    "h-circle-1": 63480,
    "h-circle-fill-1": 63481,
    "h-circle-fill": 63482,
    "h-circle": 63483,
    "h-square-fill": 63484,
    "h-square": 63485,
    "indent": 63486,
    "lungs-fill": 63487,
    "lungs": 63488,
    "microsoft-teams": 63489,
    "p-circle-1": 63490,
    "p-circle-fill-1": 63491,
    "p-circle-fill": 63492,
    "p-circle": 63493,
    "p-square-fill": 63494,
    "p-square": 63495,
    "pass-fill": 63496,
    "pass": 63497,
    "prescription": 63498,
    "prescription2": 63499,
    "r-circle-1": 63500,
    "r-circle-fill-1": 63501,
    "r-circle-fill": 63502,
    "r-circle": 63503,
    "r-square-fill": 63504,
    "r-square": 63505,
    "repeat-1": 63506,
    "repeat": 63507,
    "rewind-btn-fill": 63508,
    "rewind-btn": 63509,
    "rewind-circle-fill": 63510,
    "rewind-circle": 63511,
    "rewind-fill": 63512,
    "rewind": 63513,
    "train-freight-front-fill": 63514,
    "train-freight-front": 63515,
    "train-front-fill": 63516,
    "train-front": 63517,
    "train-lightrail-front-fill": 63518,
    "train-lightrail-front": 63519,
    "truck-front-fill": 63520,
    "truck-front": 63521,
    "ubuntu": 63522,
    "unindent": 63523,
    "unity": 63524,
    "universal-access-circle": 63525,
    "universal-access": 63526,
    "virus": 63527,
    "virus2": 63528,
    "wechat": 63529,
    "yelp": 63530,
    "sign-stop-fill": 63531,
    "sign-stop-lights-fill": 63532,
    "sign-stop-lights": 63533,
    "sign-stop": 63534,
    "sign-turn-left-fill": 63535,
    "sign-turn-left": 63536,
    "sign-turn-right-fill": 63537,
    "sign-turn-right": 63538,
    "sign-turn-slight-left-fill": 63539,
    "sign-turn-slight-left": 63540,
    "sign-turn-slight-right-fill": 63541,
    "sign-turn-slight-right": 63542,
    "sign-yield-fill": 63543,
    "sign-yield": 63544,
    "ev-station-fill": 63545,
    "ev-station": 63546,
    "fuel-pump-diesel-fill": 63547,
    "fuel-pump-diesel": 63548,
    "fuel-pump-fill": 63549,
    "fuel-pump": 63550
}

export { bootstrapicons}