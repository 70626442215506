<!-- ========== App Menu ========== -->
<div class="app-menu navbar-menu">
    <!-- LOGO -->
    <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
            <span class="logo-sm">
                <img *ngIf="logoUrl" [src]="logoUrl" alt="Logo" height="22">
                <img *ngIf="!logoUrl" src="assets/images/logo-sm.png" alt="" height="22">
            </span>
            <span class="logo-lg">
                <img *ngIf="logoUrl" [src]="logoUrl" alt="Logo" height="22">
                <img *ngIf="!logoUrl" src="assets/images/logo-dark.png" alt="" height="22">
            </span>
        </a>
        <a routerLink="/" class="logo logo-light">
            <span class="logo-sm">
                <img *ngIf="logoUrl" [src]="logoUrl" alt="Logo" height="22">
                <img *ngIf="!logoUrl" src="assets/images/logo-sm.png" alt="" height="22">
            </span>
            <span class="logo-lg">
                <img *ngIf="logoUrl" [src]="logoUrl" alt="Logo" height="22">
                <img *ngIf="!logoUrl" src="assets/images/logo-light.png" alt="" height="22">
            </span>
        </a>
        <button type="button" class="btn btn-sm p-0 fs-3xl header-item float-end btn-vertical-sm-hover shadow-none"
            id="vertical-hover" (click)="toggleMobileMenu($event)">
            <i class="ri-record-circle-line"></i>
        </button>
    </div>

    <ngx-simplebar id="scrollbar" style="height: 100%;" data-simplebar-track="light">
        <div class="container-fluid">
            <div id="two-column-menu"></div>
            <ul class="navbar-nav" id="navbar-nav">
                <ng-container *ngFor="let item of menuItems; trackBy: trackByFn">
                    <ng-container *ngIf="item.isTitle">
                        <li class="menu-title"><span>{{ item.label | translate }}</span></li>
                    </ng-container>
                    <ng-container *ngIf="!item.isTitle && !item.isLayout">
                        <li class="nav-item">
                            <ng-container *ngIf="hasItems(item)">
                                <a href="javascript:void(0);" class="nav-link menu-link"
                                    [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}"
                                    data-bs-toggle="collapse" aria-expanded="false" (click)="toggleItem($event)">
                                    <i class="{{ item.icon }}"></i>
                                    <span> {{ item.label | translate }}</span>
                                    <ng-container *ngIf="item.badge">
                                        <span class="badge badge-pill bg-danger">{{item.badge | translate}}</span>
                                    </ng-container>
                                </a>
                                <div class="collapse menu-dropdown mega-dropdown-menu">
                                    <ul class="nav nav-sm flex-column" aria-expanded="false">
                                        <ng-container *ngFor="let subitem of item.subItems; trackBy: trackByFn">
                                            <li class="nav-item">
                                                <ng-container *ngIf="hasItems(subitem)">
                                                    <a class="nav-link" href="javascript:void(0);"
                                                        [attr.data-parent]="subitem.parentId" data-bs-toggle="collapse"
                                                        aria-expanded="false" (click)="toggleSubItem($event)">
                                                        {{ subitem.label | translate}}
                                                    </a>
                                                    <div class="collapse menu-dropdown sub-menu">
                                                        <ul class="nav nav-sm flex-column" aria-expanded="false">
                                                            <ng-container
                                                                *ngFor="let subSubitem of subitem.subItems; trackBy: trackByFn">
                                                                <li class="nav-item">
                                                                    <ng-container *ngIf="hasItems(subSubitem)">
                                                                        <a class="nav-link" href="javascript:void(0);"
                                                                            [attr.data-parent]="subSubitem.parentId"
                                                                            data-bs-toggle="collapse"
                                                                            aria-expanded="false"
                                                                            (click)="toggleExtraSubItem($event)">
                                                                            {{ subSubitem.label | translate}}
                                                                        </a>
                                                                        <div
                                                                            class="collapse menu-dropdown extra-sub-menu">
                                                                            <ul class="nav nav-sm flex-column"
                                                                                aria-expanded="false">
                                                                                <ng-container
                                                                                    *ngFor="let subSubitem1 of subSubitem.subItems; trackBy: trackByFn">
                                                                                    <li class="nav-item">
                                                                                        <a [attr.data-parent]="subSubitem1.parentId"
                                                                                            [routerLink]="subSubitem1.link"
                                                                                            class="nav-link"
                                                                                            (click)="updateActive($event)">
                                                                                            {{ subSubitem1.label |
                                                                                            translate }}
                                                                                        </a>
                                                                                    </li>
                                                                                </ng-container>
                                                                            </ul>
                                                                        </div>
                                                                    </ng-container>
                                                                    <ng-container *ngIf="!hasItems(subSubitem)">
                                                                        <a [routerLink]="subSubitem.link"
                                                                            class="nav-link"
                                                                            [attr.data-parent]="subSubitem.parentId"
                                                                            (click)="updateActive($event)"
                                                                            aria-expanded="false">
                                                                            {{ subSubitem.label | translate}}
                                                                        </a>
                                                                    </ng-container>
                                                                </li>
                                                            </ng-container>
                                                        </ul>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="!hasItems(subitem)">
                                                    <a [routerLink]="subitem.link" class="nav-link"
                                                        [attr.data-parent]="subitem.parentId"
                                                        (click)="updateActive($event)" aria-expanded="false">
                                                        {{ subitem.label | translate}}
                                                    </a>
                                                </ng-container>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!hasItems(item)">
                                <a [routerLink]="item.link" class="side-nav-link-ref nav-link menu-link"
                                    (click)="toggleParentItem($event)">
                                    <ng-container *ngIf="item.icon">
                                        <i class="bx {{ item.icon }} icon nav-icon" class=""></i>
                                    </ng-container>
                                    <span class=""> {{ item.label | translate }}</span>
                                    <ng-container *ngIf="item.badge">
                                        <span class="badge badge-pill bg-secondary">{{item.badge | translate}}</span>
                                    </ng-container>
                                </a>
                            </ng-container>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
        <!-- Sidebar -->
    </ngx-simplebar>
    <div class="sidebar-background"></div>
</div>
<!-- Left Sidebar End -->
<!-- Vertical Overlay-->
<div class="vertical-overlay" (click)="SidebarHide()"></div>