<!-- Begin page -->
<div id="layout-wrapper">
    <!-- Top Bar -->
    <app-topbar></app-topbar>
    <!-- Side Bar -->
    <app-two-column-sidebar></app-two-column-sidebar>
    <div class="main-content">

        <div class="page-content">
            <div class="container-fluid">
                <!-- Main Content -->
                <router-outlet></router-outlet>
            </div>
        </div>
        <!-- Footer -->
        <app-footer></app-footer>
    </div>
</div>
<!-- Right Side Bar 
<app-customizer></app-customizer>-->