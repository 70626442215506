const chatMessagesData = [
    {
        "id": 1,
        "flagImg": "assets/images/flags/ac.svg",
        "countryName": "Ascension Island",
        "countryCode": "+247"
    },
    {
        "id": 2,
        "flagImg": "assets/images/flags/ad.svg",
        "countryName": "Andorra",
        "countryCode": "+376"
    },
    {
        "id": 3,
        "flagImg": "assets/images/flags/ae.svg",
        "countryName": "United Arab Emirates",
        "countryCode": "+971"
    },
    {
        "id": 4,
        "flagImg": "assets/images/flags/af.svg",
        "countryName": "Afghanistan",
        "countryCode": "+93"
    },
    {
        "id": 5,
        "flagImg": "assets/images/flags/ag.svg",
        "countryName": "Antigua and Barbuda",
        "countryCode": "+93"
    },
    {
        "id": 6,
        "flagImg": "assets/images/flags/ai.svg",
        "countryName": "Anguilla",
        "countryCode": "+1"
    },
    {
        "id": 7,
        "flagImg": "assets/images/flags/am.svg",
        "countryName": "Armenia",
        "countryCode": "+374"
    },
    {
        "id": 8,
        "flagImg": "assets/images/flags/ao.svg",
        "countryName": "Angola",
        "countryCode": "+244"
    },
    {
        "id": 9,
        "flagImg": "assets/images/flags/aq.svg",
        "countryName": "Antarctica",
        "countryCode": "+672"
    },
    {
        "id": 10,
        "flagImg": "assets/images/flags/ar.svg",
        "countryName": "Argentina",
        "countryCode": "+54"
    },
    {
        "id": 11,
        "flagImg": "assets/images/flags/as.svg",
        "countryName": "American Samoa",
        "countryCode": "+1"
    },
    {
        "id": 12,
        "flagImg": "assets/images/flags/at.svg",
        "countryName": "Austria",
        "countryCode": "+43"
    },
    {
        "id": 13,
        "flagImg": "assets/images/flags/au.svg",
        "countryName": "Australia",
        "countryCode": "+61"
    },
    {
        "id": 14,
        "flagImg": "assets/images/flags/aw.svg",
        "countryName": "Aruba",
        "countryCode": "+297"
    },
    {
        "id": 15,
        "flagImg": "assets/images/flags/ax.svg",
        "countryName": "Aland Islands",
        "countryCode": "+358"
    },
    {
        "id": 16,
        "flagImg": "assets/images/flags/ba.svg",
        "countryName": "Bosnia and Herzegovina",
        "countryCode": "+387"
    },
    {
        "id": 17,
        "flagImg": "assets/images/flags/bb.svg",
        "countryName": "Barbados",
        "countryCode": "+1"
    },
    {
        "id": 18,
        "flagImg": "assets/images/flags/bd.svg",
        "countryName": "Bangladesh",
        "countryCode": "+880"
    },
    {
        "id": 19,
        "flagImg": "assets/images/flags/be.svg",
        "countryName": "Belgium",
        "countryCode": "+32"
    },
    {
        "id": 20,
        "flagImg": "assets/images/flags/bf.svg",
        "countryName": "Burkina Faso",
        "countryCode": "+226"
    },
    {
        "id": 21,
        "flagImg": "assets/images/flags/bf.svg",
        "countryName": "Bulgaria",
        "countryCode": "+359"
    },
    {
        "id": 22,
        "flagImg": "assets/images/flags/bh.svg",
        "countryName": "Bahrain",
        "countryCode": "+973"
    },
    {
        "id": 23,
        "flagImg": "assets/images/flags/bi.svg",
        "countryName": "Burundi",
        "countryCode": "+257"
    },
    {
        "id": 24,
        "flagImg": "assets/images/flags/bj.svg",
        "countryName": "Benin",
        "countryCode": "+229"
    },
    {
        "id": 25,
        "flagImg": "assets/images/flags/bl.svg",
        "countryName": "Saint Barthélemy",
        "countryCode": "+590"
    },
    {
        "id": 26,
        "flagImg": "assets/images/flags/bm.svg",
        "countryName": "Bermuda",
        "countryCode": "+1"
    },
    {
        "id": 27,
        "flagImg": "assets/images/flags/bn.svg",
        "countryName": "Brunei Darussalam",
        "countryCode": "+673"
    },
    {
        "id": 28,
        "flagImg": "assets/images/flags/bo.svg",
        "countryName": "Bolivia (Plurinational State of)",
        "countryCode": "+591"
    },
    {
        "id": 29,
        "flagImg": "assets/images/flags/bq.svg",
        "countryName": "Bonaire, Sint Eustatius and Saba",
        "countryCode": "+599"
    },
    {
        "id": 30,
        "flagImg": "assets/images/flags/br.svg",
        "countryName": "Brazil",
        "countryCode": "+55"
    },
    {
        "id": 31,
        "flagImg": "assets/images/flags/bs.svg",
        "countryName": "Bahamas",
        "countryCode": "+1"
    },
    {
        "id": 32,
        "flagImg": "assets/images/flags/bt.svg",
        "countryName": "Bhutan",
        "countryCode": "+975"
    },
    {
        "id": 33,
        "flagImg": "assets/images/flags/bv.svg",
        "countryName": "Bouvet Island",
        "countryCode": "+47"
    },
    {
        "id": 34,
        "flagImg": "assets/images/flags/bw.svg",
        "countryName": "Botswana",
        "countryCode": "+267"
    },
    {
        "id": 35,
        "flagImg": "assets/images/flags/by.svg",
        "countryName": "Belarus",
        "countryCode": "+375"
    },
    {
        "id": 36,
        "flagImg": "assets/images/flags/bz.svg",
        "countryName": "Belize",
        "countryCode": "+501"
    },
    {
        "id": 37,
        "flagImg": "assets/images/flags/ca.svg",
        "countryName": "Canada",
        "countryCode": "+1"
    },
    {
        "id": 38,
        "flagImg": "assets/images/flags/cc.svg",
        "countryName": "Cocos (Keeling) Island",
        "countryCode": "+61"
    },
    {
        "id": 39,
        "flagImg": "assets/images/flags/cd.svg",
        "countryName": "Democratic Republic of the Congo",
        "countryCode": "+243"
    },
    {
        "id": 40,
        "flagImg": "assets/images/flags/cf.svg",
        "countryName": "Central African Republic",
        "countryCode": "+236"
    },
    {
        "id": 41,
        "flagImg": "assets/images/flags/cg.svg",
        "countryName": "Republic of the Congo",
        "countryCode": "+243"
    },
    {
        "id": 42,
        "flagImg": "assets/images/flags/ch.svg",
        "countryName": "Switzerland",
        "countryCode": "+41"
    },
    {
        "id": 43,
        "flagImg": "assets/images/flags/ci.svg",
        "countryName": "Ivory Coast",
        "countryCode": "+225"
    },
    {
        "id": 44,
        "flagImg": "assets/images/flags/ck.svg",
        "countryName": "Cook Islands",
        "countryCode": "+682"
    },
    {
        "id": 45,
        "flagImg": "assets/images/flags/cl.svg",
        "countryName": "Chile",
        "countryCode": "+56"
    },
    {
        "id": 46,
        "flagImg": "assets/images/flags/cm.svg",
        "countryName": "Cameroon",
        "countryCode": "+237"
    },
    {
        "id": 47,
        "flagImg": "assets/images/flags/cn.svg",
        "countryName": "China",
        "countryCode": "+86"
    },
    {
        "id": 48,
        "flagImg": "assets/images/flags/co.svg",
        "countryName": "Colombia",
        "countryCode": "+57"
    },
    {
        "id": 49,
        "flagImg": "assets/images/flags/cp.svg",
        "countryName": "Clipperton Island",
        "countryCode": "+55"
    },
    {
        "id": 50,
        "flagImg": "assets/images/flags/cr.svg",
        "countryName": "Costa Rica",
        "countryCode": "+506"
    },
    {
        "id": 51,
        "flagImg": "assets/images/flags/cu.svg",
        "countryName": "Cuba",
        "countryCode": "+53"
    },
    {
        "id": 52,
        "flagImg": "assets/images/flags/cv.svg",
        "countryName": "Cabo Verde",
        "countryCode": "+238"
    },
    {
        "id": 53,
        "flagImg": "assets/images/flags/cw.svg",
        "countryName": "Curaçao",
        "countryCode": "+599"
    },
    {
        "id": 54,
        "flagImg": "assets/images/flags/cx.svg",
        "countryName": "Christmas Island",
        "countryCode": "+61"
    },
    {
        "id": 55,
        "flagImg": "assets/images/flags/cy.svg",
        "countryName": "Cyprus",
        "countryCode": "+357"
    },
    {
        "id": 56,
        "flagImg": "assets/images/flags/cz.svg",
        "countryName": "Czech Republic",
        "countryCode": "+420"
    },
    {
        "id": 57,
        "flagImg": "assets/images/flags/de.svg",
        "countryName": "Germany",
        "countryCode": "+49"
    },
    {
        "id": 58,
        "flagImg": "assets/images/flags/dg.svg",
        "countryName": "Diego Garcia",
        "countryCode": "+246"
    },
    {
        "id": 59,
        "flagImg": "assets/images/flags/dj.svg",
        "countryName": "Djibouti",
        "countryCode": "+253"
    },
    {
        "id": 60,
        "flagImg": "assets/images/flags/dk.svg",
        "countryName": "Denmark",
        "countryCode": "+45"
    },
    {
        "id": 61,
        "flagImg": "assets/images/flags/dm.svg",
        "countryName": "Dominica",
        "countryCode": "+1"
    },
    {
        "id": 62,
        "flagImg": "assets/images/flags/do.svg",
        "countryName": "Dominican Republic",
        "countryCode": "+1"
    },
    {
        "id": 63,
        "flagImg": "assets/images/flags/dz.svg",
        "countryName": "Algeria",
        "countryCode": "+213"
    },
    {
        "id": 64,
        "flagImg": "assets/images/flags/ea.svg",
        "countryName": "Ceuta & Melilla",
        "countryCode": "34"
    },
    {
        "id": 65,
        "flagImg": "assets/images/flags/ec.svg",
        "countryName": "Ecuador",
        "countryCode": "+593"
    },
    {
        "id": 66,
        "flagImg": "assets/images/flags/ee.svg",
        "countryName": "Estonia",
        "countryCode": "+372"
    },
    {
        "id": 67,
        "flagImg": "assets/images/flags/eg.svg",
        "countryName": "Egypt",
        "countryCode": "+20"
    },
    {
        "id": 68,
        "flagImg": "assets/images/flags/eh.svg",
        "countryName": "Western Sahara",
        "countryCode": "+212"
    },
    {
        "id": 69,
        "flagImg": "assets/images/flags/er.svg",
        "countryName": "Eritrea",
        "countryCode": "+291"
    },
    {
        "id": 70,
        "flagImg": "assets/images/flags/es.svg",
        "countryName": "Spain",
        "countryCode": "+34"
    },
    {
        "id": 71,
        "flagImg": "assets/images/flags/es-ct.svg",
        "countryName": "Catalonia",
        "countryCode": "+34"
    },
    {
        "id": 72,
        "flagImg": "assets/images/flags/es-ga.svg",
        "countryName": "Galicia",
        "countryCode": "+34"
    },
    {
        "id": 73,
        "flagImg": "assets/images/flags/et.svg",
        "countryName": "Ethiopia",
        "countryCode": "+251"
    },
    {
        "id": 74,
        "flagImg": "assets/images/flags/eu.svg",
        "countryName": "Europe",
        "countryCode": "+3"
    },
    {
        "id": 75,
        "flagImg": "assets/images/flags/fi.svg",
        "countryName": "Finland",
        "countryCode": "+358"
    },
    {
        "id": 76,
        "flagImg": "assets/images/flags/fj.svg",
        "countryName": "Fiji",
        "countryCode": "+679"
    },
    {
        "id": 77,
        "flagImg": "assets/images/flags/fk.svg",
        "countryName": "Falkland Islands",
        "countryCode": "+500"
    },
    {
        "id": 78,
        "flagImg": "assets/images/flags/fm.svg",
        "countryName": "Federated States of Micronesia",
        "countryCode": "+691"
    },
    {
        "id": 79,
        "flagImg": "assets/images/flags/fo.svg",
        "countryName": "Faroe Islands",
        "countryCode": "+298"
    },
    {
        "id": 80,
        "flagImg": "assets/images/flags/fr.svg",
        "countryName": "France",
        "countryCode": "+33"
    },
    {
        "id": 81,
        "flagImg": "assets/images/flags/ga.svg",
        "countryName": "Gabon",
        "countryCode": "+241"
    },
    {
        "id": 82,
        "flagImg": "assets/images/flags/gb-eng.svg",
        "countryName": "England",
        "countryCode": "+44"
    },
    {
        "id": 83,
        "flagImg": "assets/images/flags/gb-nir.svg",
        "countryName": "Northern Ireland",
        "countryCode": "+44"
    },
    {
        "id": 84,
        "flagImg": "assets/images/flags/gb-sct.svg",
        "countryName": "Scotland",
        "countryCode": "+44"
    },
    {
        "id": 85,
        "flagImg": "assets/images/flags/gb-wls.svg",
        "countryName": "Wales",
        "countryCode": "+44"
    },
    {
        "id": 86,
        "flagImg": "assets/images/flags/gd.svg",
        "countryName": "Grenada",
        "countryCode": "+1"
    },
    {
        "id": 87,
        "flagImg": "assets/images/flags/ge.svg",
        "countryName": "Georgia",
        "countryCode": "+995"
    },
    {
        "id": 88,
        "flagImg": "assets/images/flags/gf.svg",
        "countryName": "French Guiana",
        "countryCode": "+594"
    },
    {
        "id": 99,
        "flagImg": "assets/images/flags/gg.svg",
        "countryName": "Guernsey",
        "countryCode": "+44"
    },
    {
        "id": 90,
        "flagImg": "assets/images/flags/gh.svg",
        "countryName": "Ghana",
        "countryCode": "+233"
    },
    {
        "id": 91,
        "flagImg": "assets/images/flags/gi.svg",
        "countryName": "Gibraltar",
        "countryCode": "+350"
    },
    {
        "id": 92,
        "flagImg": "assets/images/flags/gl.svg",
        "countryName": "Greenland",
        "countryCode": "+299"
    },
    {
        "id": 93,
        "flagImg": "assets/images/flags/gm.svg",
        "countryName": "Gambia",
        "countryCode": "+220"
    },
    {
        "id": 94,
        "flagImg": "assets/images/flags/gn.svg",
        "countryName": "Guinea",
        "countryCode": "+224"
    },
    {
        "id": 95,
        "flagImg": "assets/images/flags/gp.svg",
        "countryName": "Guadeloupe",
        "countryCode": "+590"
    },
    {
        "id": 96,
        "flagImg": "assets/images/flags/gq.svg",
        "countryName": "Equatorial Guinea",
        "countryCode": "+240"
    },
    {
        "id": 97,
        "flagImg": "assets/images/flags/gr.svg",
        "countryName": "Greece",
        "countryCode": "+30"
    },
    {
        "id": 98,
        "flagImg": "assets/images/flags/gs.svg",
        "countryName": "South Georgia and the South Sandwich Islands",
        "countryCode": "+500"
    },
    {
        "id": 99,
        "flagImg": "assets/images/flags/gt.svg",
        "countryName": "Guatemala",
        "countryCode": "+502"
    },
    {
        "id": 100,
        "flagImg": "assets/images/flags/gu.svg",
        "countryName": "Guam",
        "countryCode": "+1"
    },
    {
        "id": 101,
        "flagImg": "assets/images/flags/gw.svg",
        "countryName": "Guinea-Bissau",
        "countryCode": "+245"
    },
    {
        "id": 102,
        "flagImg": "assets/images/flags/gy.svg",
        "countryName": "Guyana",
        "countryCode": "+592"
    },
    {
        "id": 103,
        "flagImg": "assets/images/flags/hk.svg",
        "countryName": "Hong Kong",
        "countryCode": "+852"
    },
    {
        "id": 104,
        "flagImg": "assets/images/flags/hn.svg",
        "countryName": "Honduras",
        "countryCode": "+504"
    },
    {
        "id": 105,
        "flagImg": "assets/images/flags/hr.svg",
        "countryName": "Croatia",
        "countryCode": "+385"
    },
    {
        "id": 106,
        "flagImg": "assets/images/flags/ht.svg",
        "countryName": "Haiti",
        "countryCode": "+509"
    },
    {
        "id": 107,
        "flagImg": "assets/images/flags/hu.svg",
        "countryName": "Hungary",
        "countryCode": "+36"
    },
    {
        "id": 108,
        "flagImg": "assets/images/flags/id.svg",
        "countryName": "Indonesia",
        "countryCode": "+62"
    },
    {
        "id": 109,
        "flagImg": "assets/images/flags/ie.svg",
        "countryName": "Ireland",
        "countryCode": "+353"
    },
    {
        "id": 110,
        "flagImg": "assets/images/flags/il.svg",
        "countryName": "Israel",
        "countryCode": "+972"
    },
    {
        "id": 111,
        "flagImg": "assets/images/flags/im.svg",
        "countryName": "Isle of Man",
        "countryCode": "+44"
    },
    {
        "id": 112,
        "flagImg": "assets/images/flags/in.svg",
        "countryName": "India",
        "countryCode": "+91"
    },
    {
        "id": 113,
        "flagImg": "assets/images/flags/io.svg",
        "countryName": "British Indian Ocean Territory",
        "countryCode": "+246"
    },
    {
        "id": 114,
        "flagImg": "assets/images/flags/iq.svg",
        "countryName": "Iraq",
        "countryCode": "+964"
    },
    {
        "id": 115,
        "flagImg": "assets/images/flags/ir.svg",
        "countryName": "Iran (Islamic Republic of)",
        "countryCode": "+98"
    },
    {
        "id": 116,
        "flagImg": "assets/images/flags/is.svg",
        "countryName": "Iceland",
        "countryCode": "+354"
    },
    {
        "id": 117,
        "flagImg": "assets/images/flags/it.svg",
        "countryName": "Italy",
        "countryCode": "+39"
    },
    {
        "id": 118,
        "flagImg": "assets/images/flags/je.svg",
        "countryName": "Jersey",
        "countryCode": "+44"
    },
    {
        "id": 119,
        "flagImg": "assets/images/flags/jm.svg",
        "countryName": "Jamaica",
        "countryCode": "+1"
    },
    {
        "id": 120,
        "flagImg": "assets/images/flags/jo.svg",
        "countryName": "Jordan",
        "countryCode": "+962"
    },
    {
        "id": 121,
        "flagImg": "assets/images/flags/jp.svg",
        "countryName": "Japan",
        "countryCode": "+81"
    },
    {
        "id": 122,
        "flagImg": "assets/images/flags/ke.svg",
        "countryName": "Kenya",
        "countryCode": "+254"
    },
    {
        "id": 123,
        "flagImg": "assets/images/flags/kg.svg",
        "countryName": "Kyrgyzstan",
        "countryCode": "+996"
    },
    {
        "id": 124,
        "flagImg": "assets/images/flags/kh.svg",
        "countryName": "Cambodia",
        "countryCode": "+855"
    },
    {
        "id": 125,
        "flagImg": "assets/images/flags/ki.svg",
        "countryName": "Kiribati",
        "countryCode": "+686"
    },
    {
        "id": 126,
        "flagImg": "assets/images/flags/km.svg",
        "countryName": "Comoros",
        "countryCode": "+269"
    },
    {
        "id": 127,
        "flagImg": "assets/images/flags/kn.svg",
        "countryName": "Saint Kitts and Nevis",
        "countryCode": "+1"
    },
    {
        "id": 128,
        "flagImg": "assets/images/flags/kp.svg",
        "countryName": "North Korea",
        "countryCode": "+850"
    },
    {
        "id": 129,
        "flagImg": "assets/images/flags/kr.svg",
        "countryName": "South Korea",
        "countryCode": "+82"
    },
    {
        "id": 130,
        "flagImg": "assets/images/flags/kw.svg",
        "countryName": "Kuwait",
        "countryCode": "+965"
    },
    {
        "id": 131,
        "flagImg": "assets/images/flags/ky.svg",
        "countryName": "Cayman Islands",
        "countryCode": "+1"
    },
    {
        "id": 132,
        "flagImg": "assets/images/flags/kz.svg",
        "countryName": "Kazakhstan",
        "countryCode": "+7"
    },
    {
        "id": 133,
        "flagImg": "assets/images/flags/la.svg",
        "countryName": "Laos",
        "countryCode": "+856"
    },
    {
        "id": 134,
        "flagImg": "assets/images/flags/lb.svg",
        "countryName": "Lebanon",
        "countryCode": "+961"
    },
    {
        "id": 135,
        "flagImg": "assets/images/flags/lc.svg",
        "countryName": "Saint Lucia",
        "countryCode": "+1"
    },
    {
        "id": 136,
        "flagImg": "assets/images/flags/li.svg",
        "countryName": "Liechtenstein",
        "countryCode": "+423"
    },
    {
        "id": 137,
        "flagImg": "assets/images/flags/lk.svg",
        "countryName": "Sri Lanka",
        "countryCode": "+94"
    },
    {
        "id": 138,
        "flagImg": "assets/images/flags/lr.svg",
        "countryName": "Liberia",
        "countryCode": "+231"
    },
    {
        "id": 139,
        "flagImg": "assets/images/flags/ls.svg",
        "countryName": "Lesotho",
        "countryCode": "+266"
    },
    {
        "id": 140,
        "flagImg": "assets/images/flags/lt.svg",
        "countryName": "Lithuania",
        "countryCode": "+370"
    },
    {
        "id": 141,
        "flagImg": "assets/images/flags/lu.svg",
        "countryName": "Luxembourg",
        "countryCode": "+352"
    },
    {
        "id": 142,
        "flagImg": "assets/images/flags/lv.svg",
        "countryName": "Latvia",
        "countryCode": "+371"
    },
    {
        "id": 143,
        "flagImg": "assets/images/flags/ly.svg",
        "countryName": "Libya",
        "countryCode": "+218"
    },
    {
        "id": 144,
        "flagImg": "assets/images/flags/ma.svg",
        "countryName": "Morocco",
        "countryCode": "+212"
    },
    {
        "id": 145,
        "flagImg": "assets/images/flags/mc.svg",
        "countryName": "Monaco",
        "countryCode": "+377"
    },
    {
        "id": 146,
        "flagImg": "assets/images/flags/md.svg",
        "countryName": "Moldova",
        "countryCode": "+373"
    },
    {
        "id": 147,
        "flagImg": "assets/images/flags/me.svg",
        "countryName": "Montenegro",
        "countryCode": "+382"
    },
    {
        "id": 148,
        "flagImg": "assets/images/flags/mf.svg",
        "countryName": "Saint Martin",
        "countryCode": "+721"
    },
    {
        "id": 149,
        "flagImg": "assets/images/flags/mg.svg",
        "countryName": "Madagascar",
        "countryCode": "+261"
    },
    {
        "id": 150,
        "flagImg": "assets/images/flags/mh.svg",
        "countryName": "Marshall Islands",
        "countryCode": "+692"
    },
    {
        "id": 151,
        "flagImg": "assets/images/flags/mk.svg",
        "countryName": "North Macedonia",
        "countryCode": "+389"
    },
    {
        "id": 152,
        "flagImg": "assets/images/flags/ml.svg",
        "countryName": "Mali",
        "countryCode": "+223"
    },
    {
        "id": 153,
        "flagImg": "assets/images/flags/mm.svg",
        "countryName": "Myanmar",
        "countryCode": "+95"
    },
    {
        "id": 154,
        "flagImg": "assets/images/flags/mn.svg",
        "countryName": "Mongolia",
        "countryCode": "+976"
    },
    {
        "id": 155,
        "flagImg": "assets/images/flags/mo.svg",
        "countryName": "Macau",
        "countryCode": "+853"
    },
    {
        "id": 156,
        "flagImg": "assets/images/flags/mp.svg",
        "countryName": "Northern Mariana Islands",
        "countryCode": "+1"
    },
    {
        "id": 157,
        "flagImg": "assets/images/flags/mq.svg",
        "countryName": "Martinique",
        "countryCode": "+596"
    },
    {
        "id": 158,
        "flagImg": "assets/images/flags/mr.svg",
        "countryName": "Mauritania",
        "countryCode": "+222"
    },
    {
        "id": 159,
        "flagImg": "assets/images/flags/ms.svg",
        "countryName": "Montserrat",
        "countryCode": "+1"
    },
    {
        "id": 160,
        "flagImg": "assets/images/flags/mt.svg",
        "countryName": "Malta",
        "countryCode": "+356"
    },
    {
        "id": 161,
        "flagImg": "assets/images/flags/mu.svg",
        "countryName": "Mauritius",
        "countryCode": "+230"
    },
    {
        "id": 162,
        "flagImg": "assets/images/flags/mv.svg",
        "countryName": "Maldives",
        "countryCode": "+960"
    },
    {
        "id": 163,
        "flagImg": "assets/images/flags/mw.svg",
        "countryName": "Malawi",
        "countryCode": "+265"
    },
    {
        "id": 164,
        "flagImg": "assets/images/flags/mx.svg",
        "countryName": "Mexico",
        "countryCode": "+52"
    },
    {
        "id": 165,
        "flagImg": "assets/images/flags/my.svg",
        "countryName": "Malaysia",
        "countryCode": "+60"
    },
    {
        "id": 166,
        "flagImg": "assets/images/flags/mz.svg",
        "countryName": "Mozambique",
        "countryCode": "+258"
    },
    {
        "id": 167,
        "flagImg": "assets/images/flags/na.svg",
        "countryName": "Namibia",
        "countryCode": "+264"
    },
    {
        "id": 168,
        "flagImg": "assets/images/flags/nc.svg",
        "countryName": "New Caledonia",
        "countryCode": "+687"
    },
    {
        "id": 169,
        "flagImg": "assets/images/flags/ne.svg",
        "countryName": "Niger",
        "countryCode": "+227"
    },
    {
        "id": 170,
        "flagImg": "assets/images/flags/nf.svg",
        "countryName": "Norfolk Island",
        "countryCode": "+672"
    },
    {
        "id": 171,
        "flagImg": "assets/images/flags/ng.svg",
        "countryName": "Nigeria",
        "countryCode": "+234"
    },
    {
        "id": 172,
        "flagImg": "assets/images/flags/ni.svg",
        "countryName": "Nicaragua",
        "countryCode": "+505"
    },
    {
        "id": 173,
        "flagImg": "assets/images/flags/nl.svg",
        "countryName": "Netherlands",
        "countryCode": "+31"
    },
    {
        "id": 174,
        "flagImg": "assets/images/flags/no.svg",
        "countryName": "Norway",
        "countryCode": "+47"
    },
    {
        "id": 175,
        "flagImg": "assets/images/flags/np.svg",
        "countryName": "Nepal",
        "countryCode": "+977"
    },
    {
        "id": 176,
        "flagImg": "assets/images/flags/nr.svg",
        "countryName": "Nauru",
        "countryCode": "+674"
    },
    {
        "id": 177,
        "flagImg": "assets/images/flags/nu.svg",
        "countryName": "Niue",
        "countryCode": "+683"
    },
    {
        "id": 178,
        "flagImg": "assets/images/flags/nz.svg",
        "countryName": "New Zealand",
        "countryCode": "+64"
    },
    {
        "id": 179,
        "flagImg": "assets/images/flags/om.svg",
        "countryName": "Oman",
        "countryCode": "+968"
    },
    {
        "id": 180,
        "flagImg": "assets/images/flags/pa.svg",
        "countryName": "Panama",
        "countryCode": "+507"
    },
    {
        "id": 181,
        "flagImg": "assets/images/flags/pe.svg",
        "countryName": "Peru",
        "countryCode": "+51"
    },
    {
        "id": 182,
        "flagImg": "assets/images/flags/pf.svg",
        "countryName": "French Polynesia",
        "countryCode": "+689"
    },
    {
        "id": 183,
        "flagImg": "assets/images/flags/pg.svg",
        "countryName": "Papua New Guinea",
        "countryCode": "+675"
    },
    {
        "id": 184,
        "flagImg": "assets/images/flags/ph.svg",
        "countryName": "Philippines",
        "countryCode": "+63"
    },
    {
        "id": 185,
        "flagImg": "assets/images/flags/pk.svg",
        "countryName": "Pakistan",
        "countryCode": "+92"
    },
    {
        "id": 186,
        "flagImg": "assets/images/flags/pl.svg",
        "countryName": "Poland",
        "countryCode": "+48"
    },
    {
        "id": 187,
        "flagImg": "assets/images/flags/pm.svg",
        "countryName": "Saint Pierre and Miquelon",
        "countryCode": "+508"
    },
    {
        "id": 188,
        "flagImg": "assets/images/flags/pn.svg",
        "countryName": "Pitcairn",
        "countryCode": "+64"
    },
    {
        "id": 189,
        "flagImg": "assets/images/flags/pr.svg",
        "countryName": "Puerto Rico",
        "countryCode": "+1"
    },
    {
        "id": 190,
        "flagImg": "assets/images/flags/ps.svg",
        "countryName": "State of Palestine",
        "countryCode": "+970"
    },
    {
        "id": 191,
        "flagImg": "assets/images/flags/pt.svg",
        "countryName": "Portugal",
        "countryCode": "+351"
    },
    {
        "id": 192,
        "flagImg": "assets/images/flags/pw.svg",
        "countryName": "Palau",
        "countryCode": "+680"
    },
    {
        "id": 193,
        "flagImg": "assets/images/flags/py.svg",
        "countryName": "Paraguay",
        "countryCode": "+595"
    },
    {
        "id": 194,
        "flagImg": "assets/images/flags/qa.svg",
        "countryName": "Qatar",
        "countryCode": "+974"
    },
    {
        "id": 195,
        "flagImg": "assets/images/flags/re.svg",
        "countryName": "Réunion",
        "countryCode": "+262"
    },
    {
        "id": 196,
        "flagImg": "assets/images/flags/ro.svg",
        "countryName": "Romania",
        "countryCode": "+40"
    },
    {
        "id": 197,
        "flagImg": "assets/images/flags/rs.svg",
        "countryName": "Serbia",
        "countryCode": "+381"
    },
    {
        "id": 198,
        "flagImg": "assets/images/flags/ru.svg",
        "countryName": "Russia",
        "countryCode": "+7"
    },
    {
        "id": 199,
        "flagImg": "assets/images/flags/rw.svg",
        "countryName": "Rwanda",
        "countryCode": "+250"
    },
    {
        "id": 200,
        "flagImg": "assets/images/flags/sa.svg",
        "countryName": "Saudi Arabia",
        "countryCode": "+966"
    },
    {
        "id": 201,
        "flagImg": "assets/images/flags/sb.svg",
        "countryName": "Solomon Islands",
        "countryCode": "+677"
    },
    {
        "id": 202,
        "flagImg": "assets/images/flags/sc.svg",
        "countryName": "Seychelles",
        "countryCode": "+248"
    },
    {
        "id": 203,
        "flagImg": "assets/images/flags/sd.svg",
        "countryName": "Sudan",
        "countryCode": "+249"
    },
    {
        "id": 204,
        "flagImg": "assets/images/flags/se.svg",
        "countryName": "Sweden",
        "countryCode": "+46"
    },
    {
        "id": 205,
        "flagImg": "assets/images/flags/sg.svg",
        "countryName": "Singapore",
        "countryCode": "+65"
    },
    {
        "id": 207,
        "flagImg": "assets/images/flags/si.svg",
        "countryName": "Slovenia",
        "countryCode": "+386"
    },
    {
        "id": 208,
        "flagImg": "assets/images/flags/sj.svg",
        "countryName": "Svalbard and Jan Mayen",
        "countryCode": "47"
    },
    {
        "id": 209,
        "flagImg": "assets/images/flags/sk.svg",
        "countryName": "Slovakia",
        "countryCode": "+421"
    },
    {
        "id": 210,
        "flagImg": "assets/images/flags/sl.svg",
        "countryName": "Sierra Leone",
        "countryCode": "+232"
    },
    {
        "id": 212,
        "flagImg": "assets/images/flags/sm.svg",
        "countryName": "San Marino",
        "countryCode": "+378"
    },
    {
        "id": 213,
        "flagImg": "assets/images/flags/sn.svg",
        "countryName": "Senegal",
        "countryCode": "+221"
    },
    {
        "id": 214,
        "flagImg": "assets/images/flags/so.svg",
        "countryName": "Somalia",
        "countryCode": "+252"
    },
    {
        "id": 215,
        "flagImg": "assets/images/flags/sr.svg",
        "countryName": "Suriname",
        "countryCode": "+597"
    },
    {
        "id": 216,
        "flagImg": "assets/images/flags/ss.svg",
        "countryName": "South Sudan",
        "countryCode": "+211"
    },
    {
        "id": 217,
        "flagImg": "assets/images/flags/st.svg",
        "countryName": "Sao Tome and Principe",
        "countryCode": "+239"
    },
    {
        "id": 218,
        "flagImg": "assets/images/flags/sv.svg",
        "countryName": "El Salvador",
        "countryCode": "+503"
    },
    {
        "id": 219,
        "flagImg": "assets/images/flags/sx.svg",
        "countryName": "Sint Maarten",
        "countryCode": "+721"
    },
    {
        "id": 220,
        "flagImg": "assets/images/flags/sy.svg",
        "countryName": "Syria",
        "countryCode": "+963"
    },
    {
        "id": 221,
        "flagImg": "assets/images/flags/sz.svg",
        "countryName": "Eswatini",
        "countryCode": "+268"
    },
    {
        "id": 222,
        "flagImg": "assets/images/flags/ta.svg",
        "countryName": "Tristan da Cunha",
        "countryCode": "+290"
    },
    {
        "id": 223,
        "flagImg": "assets/images/flags/tc.svg",
        "countryName": "Turks and Caicos Islands",
        "countryCode": "+1"
    },
    {
        "id": 224,
        "flagImg": "assets/images/flags/td.svg",
        "countryName": "Chad",
        "countryCode": "+235"
    },
    {
        "id": 225,
        "flagImg": "assets/images/flags/tg.svg",
        "countryName": "Togo",
        "countryCode": "+228"
    },
    {
        "id": 226,
        "flagImg": "assets/images/flags/th.svg",
        "countryName": "Thailand",
        "countryCode": "+66"
    },
    {
        "id": 227,
        "flagImg": "assets/images/flags/tj.svg",
        "countryName": "Tajikistan",
        "countryCode": "+992"
    },
    {
        "id": 228,
        "flagImg": "assets/images/flags/tk.svg",
        "countryName": "Tokelau",
        "countryCode": "+690"
    },
    {
        "id": 229,
        "flagImg": "assets/images/flags/tl.svg",
        "countryName": "Timor-Leste",
        "countryCode": "+670"
    },
    {
        "id": 230,
        "flagImg": "assets/images/flags/tm.svg",
        "countryName": "Turkmenistan",
        "countryCode": "+993"
    },
    {
        "id": 231,
        "flagImg": "assets/images/flags/tn.svg",
        "countryName": "Tunisia",
        "countryCode": "+216"
    },
    {
        "id": 232,
        "flagImg": "assets/images/flags/to.svg",
        "countryName": "Tonga",
        "countryCode": "+676"
    },
    {
        "id": 233,
        "flagImg": "assets/images/flags/tr.svg",
        "countryName": "Turkey",
        "countryCode": "+90"
    },
    {
        "id": 234,
        "flagImg": "assets/images/flags/tt.svg",
        "countryName": "Trinidad and Tobago",
        "countryCode": "+1"
    },
    {
        "id": 235,
        "flagImg": "assets/images/flags/tv.svg",
        "countryName": "Tuvalu",
        "countryCode": "+688"
    },
    {
        "id": 236,
        "flagImg": "assets/images/flags/tw.svg",
        "countryName": "Taiwan",
        "countryCode": "+886"
    },
    {
        "id": 237,
        "flagImg": "assets/images/flags/tz.svg",
        "countryName": "Tanzania",
        "countryCode": "+255"
    },
    {
        "id": 238,
        "flagImg": "assets/images/flags/ua.svg",
        "countryName": "Ukraine",
        "countryCode": "+380"
    },
    {
        "id": 239,
        "flagImg": "assets/images/flags/ug.svg",
        "countryName": "Uganda",
        "countryCode": "+256"
    },
    {
        "id": 240,
        "flagImg": "assets/images/flags/us.svg",
        "countryName": "United States of America",
        "countryCode": "+1"
    },
    {
        "id": 242,
        "flagImg": "assets/images/flags/uy.svg",
        "countryName": "Uruguay",
        "countryCode": "+598"
    },
    {
        "id": 243,
        "flagImg": "assets/images/flags/uz.svg",
        "countryName": "Uzbekistan",
        "countryCode": "+998"
    },
    {
        "id": 244,
        "flagImg": "assets/images/flags/va.svg",
        "countryName": "Holy See",
        "countryCode": "+379"
    },
    {
        "id": 245,
        "flagImg": "assets/images/flags/vc.svg",
        "countryName": "Saint Vincent and the Grenadines",
        "countryCode": "+1"
    },
    {
        "id": 246,
        "flagImg": "assets/images/flags/ve.svg",
        "countryName": "Venezuela (Bolivarian Republic of)",
        "countryCode": "+58"
    },
    {
        "id": 247,
        "flagImg": "assets/images/flags/vg.svg",
        "countryName": "Virgin Islands (British)",
        "countryCode": "+1"
    },
    {
        "id": 248,
        "flagImg": "assets/images/flags/vi.svg",
        "countryName": "Virgin Islands (U.S.)",
        "countryCode": "+1"
    },
    {
        "id": 249,
        "flagImg": "assets/images/flags/vn.svg",
        "countryName": "Vietnam",
        "countryCode": "+84"
    },
    {
        "id": 250,
        "flagImg": "assets/images/flags/vu.svg",
        "countryName": "Vanuatu",
        "countryCode": "+678"
    },
    {
        "id": 251,
        "flagImg": "assets/images/flags/wf.svg",
        "countryName": "Wallis and Futuna",
        "countryCode": "+681"
    },
    {
        "id": 252,
        "flagImg": "assets/images/flags/ws.svg",
        "countryName": "Samoa",
        "countryCode": "+685"
    },
    {
        "id": 253,
        "flagImg": "assets/images/flags/xk.svg",
        "countryName": "Kosovo",
        "countryCode": "+383"
    },
    {
        "id": 254,
        "flagImg": "assets/images/flags/ye.svg",
        "countryName": "Yemen",
        "countryCode": "+967"
    },
    {
        "id": 255,
        "flagImg": "assets/images/flags/yt.svg",
        "countryName": "Mayotte",
        "countryCode": "+262"
    },
    {
        "id": 256,
        "flagImg": "assets/images/flags/za.svg",
        "countryName": "South Africa",
        "countryCode": "+27"
    },
    {
        "id": 257,
        "flagImg": "assets/images/flags/zm.svg",
        "countryName": "Zambia",
        "countryCode": "+260"
    },
    {
        "id": 258,
        "flagImg": "assets/images/flags/zw.svg",
        "countryName": "Zimbabwe",
        "countryCode": "+263"
    }
]

export { chatMessagesData };