<div class="app-menu navbar-menu" #menuBar>
    <!-- LOGO -->
    <div class="navbar-brand-box">
        <!-- Dark Logo-->
        <a routerLink="/" class="logo logo-dark">
            <span class="logo-sm">
                <img src="assets/images/logo-sm.png" alt="" height="22">
            </span>
            <span class="logo-lg">
                <img src="assets/images/logo-dark.png" alt="" height="17">
            </span>
        </a>
        <!-- Light Logo-->
        <a routerLink="/" class="logo logo-light">
            <span class="logo-sm">
                <img src="assets/images/logo-sm.png" alt="" height="22">
            </span>
            <span class="logo-lg">
                <img src="assets/images/logo-light.png" alt="" height="17">
            </span>
        </a>
        <button type="button" class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
            <i class="ri-record-circle-line"></i>
        </button>
    </div>

    <div id="scrollbar">
        <div class="container-fluid">

            <div id="two-column-menu">
            </div>
            <ul class="navbar-nav" id="navbar-nav">
                @for (item of menuItems; track $index) {
                <ng-container>
                    @if(item.isTitle){
                    <li class="menu-title"><span>{{ item.label | translate
                            }}</span></li>
                    }
                    @if(!item.isTitle && !item.isLayout){
                    <li class="nav-item">
                        @if(hasItems(item)){
                        <a href="javascript:void(0);" class="is-parent nav-link menu-link" [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}" data-bs-toggle="collapse" aria-expanded="false" (click)="toggleItem($event)">
                            <i class="{{ item.icon }} icon nav-icon" class=""></i>
                            <span class=""> {{ item.label | translate }}</span>
                            @if(item.badge){
                            <span class="badge badge-pill {{item.badge.variant}}">{{item.badge.text
                                | translate}}</span>}
                        </a>
                        <div class="collapse menu-dropdown" id="sidebarDashboards">
                            @if(hasItems(item)){
                            <ul class="nav nav-sm flex-column" aria-expanded="false">
                                @for (subitem of item.subItems; track $index) {
                                <li class="nav-item">

                                    @if(hasItems(subitem)){
                                    <a class="nav-link" href="javascript:void(0);" [attr.data-parent]="subitem.parentId" data-bs-toggle="collapse" aria-expanded="false" (click)="toggleSubItem($event)">
                                        @if(subitem.icon){
                                        <i class="bx {{ subitem.icon }} icon nav-icon" class=""></i>}{{ subitem.label | translate}}
                                    </a>
                                    <div class="collapse menu-dropdown">
                                        <ul class="nav nav-sm flex-column" aria-expanded="false">
                                            @for (subSubitem of subitem.subItems; track $index) {
                                            <li class="nav-item">

                                                @if(subSubitem.childItem){
                                                <a [attr.data-parent]="subSubitem.parentId" data-bs-toggle="collapse" [routerLink]="subSubitem.link" aria-expanded="true" class="nav-link" (click)="updateActive($event)">
                                                    {{ subSubitem.label | translate }}
                                                </a>

                                                <div class="collapse menu-dropdown">
                                                    <ul class="nav nav-sm flex-column" aria-expanded="false">
                                                        @for (subChilditem of subSubitem.childItem; track $index) {
                                                        <li class="nav-item">

                                                            <a [routerLink]="subChilditem.link" class="nav-link" (click)="updateActive($event)">
                                                                {{ subChilditem.label | translate }}
                                                            </a>

                                                            <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subChilditem.link" data-bs-toggle="collapse" aria-expanded="false" class="nav-link" (click)="updateActive($event)">
                                                                {{ subChilditem.label | translate }}
                                                            </a>

                                                            <div class="collapse menu-dropdown">
                                                                <ul class="nav nav-sm flex-column" aria-expanded="false">
                                                                    @for (subchildChilditem of subChilditem.childItem; track $index) {
                                                                    <li class="nav-item">

                                                                        <a [attr.data-parent]="subchildChilditem.parentId" [routerLink]="subchildChilditem.link" class="nav-link" (click)="updateActive($event)">
                                                                            {{ subchildChilditem.label | translate }}
                                                                        </a>

                                                                    </li>
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </li>}
                                                    </ul>
                                                </div>
                                                }@else if(subSubitem.subItems){

                                                <a [attr.data-parent]="subSubitem.parentId" data-bs-toggle="collapse" [routerLink]="subSubitem.link" aria-expanded="true" class="nav-link" (click)="updateActive($event)">
                                                    {{ subSubitem.label | translate }}
                                                </a>

                                                <div class="collapse menu-dropdown">
                                                    <ul class="nav nav-sm flex-column" aria-expanded="false">
                                                        @for (subChilditem of subSubitem.subItems; track $index) {
                                                        <li class="nav-item">
                                                            @if(!subChilditem.subItems){
                                                            <a [attr.data-parent]="subChilditem.parentId" [routerLink]="subChilditem.link" class="nav-link" (click)="updateActive($event)">
                                                                {{ subChilditem.label | translate }}
                                                            </a>}
                                                        </li>}
                                                    </ul>
                                                </div>}@else {
                                                <a [routerLink]="subSubitem.link" class="nav-link" (click)="updateActive($event)">
                                                    {{ subSubitem.label | translate }}
                                                </a>}


                                            </li>
                                            }
                                        </ul>
                                    </div>}@else { <a [routerLink]="subitem.link" class="nav-link" [attr.data-parent]="subitem.parentId" (click)="updateActive($event)">
                                        @if(subitem.icon){
                                        <i class="bx {{ subitem.icon }} icon nav-icon" class=""></i>}{{ subitem.label | translate}}
                                    </a>}

                                </li>}
                            </ul>}
                        </div>}@else{

                        <a [routerLink]="item.link" class="side-nav-link-ref nav-link menu-link">
                            @if(item.icon){
                            <i class="bx {{ item.icon }} icon nav-icon" class=""></i>
                            }
                            <span class=""> {{ item.label | translate }}</span>
                            @if(item.badge){
                            <span class="badge {{item.badge.variant}} float-end">{{item.badge.text |
                                translate}}</span>}
                        </a>}

                    </li>
                    }
                </ng-container>
                }

            </ul>
        </div>
        <!-- Sidebar -->
    </div>
</div>